/* Import react component */
import React, {useRef, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";

/* Import style */
import '../../Login.css'
import {Alert, Button, Form, Input} from "antd";
import {User, Lock, Settings} from 'react-feather';

function Login() {

    /* Handle state and variable */
    //State document
    const formRef = useRef(null);
    const [validation, setValidation] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const  inputRef = useRef(true);

    //Cannot redirect to log in if user logged in
    useEffect(() => {
        inputRef.current.focus();

        document.title = 'E-Performance';

        //check token
        if(localStorage.getItem('token')) {

            //redirect page dashboard
            navigate('/system');
        }
        /*eslint-disable*/
    }, []);



    /* Handle login */
    const auth = async (values) => {
        setLoading(true);
        //Send data to backend
        await ApiConfig.post('/auth', {
            email: values.email,
            password: values.password,
        }).then(async (response) => {

            const user = response.data.doc;

            //Set Authorization Header
            ApiConfig.defaults.headers.common['Authorization'] = response.data.accessToken;

            //Token
            localStorage.setItem('token', response.data.accessToken);

            //Save data user to local storage
            localStorage.setItem('userId', user.id);
            localStorage.setItem('companyId', user.companyId);
            localStorage.setItem('companyName', user.company.company_name);
            localStorage.setItem('urlLogo', user.company.url_logo);
            localStorage.setItem('directorateId', user.directorateId);
            localStorage.setItem('divisionId', user.divisionId);
            localStorage.setItem('departmentId', user.departmentId);
            localStorage.setItem('positionId', user.positionId);
            localStorage.setItem('grade', user.position.grade);
            localStorage.setItem('role', user.role);
            localStorage.setItem('nik', user.nik);
            localStorage.setItem('name', user.name);
            localStorage.setItem('email', user.email);

            if (user.superiors.length !== 0) {
                localStorage.setItem('superior', true);
            } else {
                localStorage.setItem('superior', false);
            }

            //Allow Access
            navigate('/system');
        }).catch((error) => {
            setLoading(false);
            console.log(error);
            setValidation(error.response.data);
        });
        setLoading(false);
    }

    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <Form className="login100-form" onFinish={auth} ref={formRef} initialValues={{ remember: true }}>
					<span className="login100-form-title p-b-43">
						Login Required
					</span>

                        <Form.Item name="email" rules={[
                            { required: true, message: "Please input email" },
                            { type: 'email', message: "Is not a valid email"},
                            { min: 10, message: "Email must have min 10 character" }
                        ]}>
                            <Input size="large" prefix={<User className="feather"/>} placeholder="Email" ref={inputRef}/>
                        </Form.Item>

                        <Form.Item name="password" rules={[
                            { required: true, message: 'Please input your Password!',},
                        ]}
                        >
                            <Input.Password size="large" prefix={<Lock className="feather" />} placeholder="Password" />
                        </Form.Item>

                        <Form.Item>
                            <Settings className="feather" />
                            <a className="login-form-forgot" href="">
                                Forgot password
                            </a>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login100-form-btn" loading={loading}>
                                Sign in
                            </Button>
                        </Form.Item>
                        {
                            validation.message && (
                                <Alert message={validation.message} type="error" showIcon />
                            )
                        }
                    </Form>

                    <div className="login100-more" style={{ backgroundImage: "url('/mbs-login-page.png')" }}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;