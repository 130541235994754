/* Import Module */
import React, {useState, useEffect, useCallback} from "react";
import { Card } from 'react-bootstrap';
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";


/* Import Style*/
import {Button, message, Skeleton, DatePicker, Row, Col, Form, Segmented, Switch} from "antd";

/* Import Component Time */
import dayjs from "dayjs";

export default function DashKpiCompany() {
    //Prepare State
    const [formSettingPeriod] = Form.useForm();
    const currentDate = new Date();
    const yearFormat = 'YYYY';
    const [year, setYear] = useState(dayjs(currentDate).format(yearFormat));
    const [automaticPeriod, setAutomaticPeriod] = useState(false);
    const [quarter, setQuarter] = useState("");
    const [lockRealization, setLockRealization] = useState(false);
    const [lockRealization360, setLockRealization360] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    /*
   * Initialization function in first render
   */
    useEffect(() => {
        document.title = 'Setting Period';
        fetchSettingPeriod().finally();
        fetchSettingRealization().finally();
        fetchSettingRealization360().finally();
        /*eslint-disable-next-line*/
    }, []);


    /*
    * Handle Handle Automatic Period
    */
    const handleAutomaticPeriod = useCallback( (value) => {
        setAutomaticPeriod(value);
    }, [automaticPeriod]);


    /*
    * Handle year user trigger form select year.
    */
    const handleYear = useCallback( (value) => {
        const selectedYear = dayjs(value).format(yearFormat);
        setYear(selectedYear);
        /* eslint-disable */
    }, [year]);


    /*
    * Handle Quarter by current month
    */
    const handleQuarter = useCallback( (value) => {
        setQuarter(value);
    }, [quarter]);


    /*
    * Handle Lock Realization
    */
    const handleLockRealization = useCallback( (value) => {
        setLockRealization(value);
    }, [lockRealization]);

    /*
    * Handle Lock Realization 360
    */
    const handleLockRealization360 = useCallback( (value) => {
        setLockRealization360(value);
    }, [lockRealization360]);


    /*
    * Handle fetch data setting period
    */
    async function fetchSettingPeriod() {
        setLoading(true);
        await ApiConfig.get(`/env/get-setting-period`).then(response => {
            const data = response.data;

            setAutomaticPeriod(data.automaticPeriod !== "false");
            setYear(data.currentYear);
            setQuarter(data.currentGroup);

            formSettingPeriod.setFieldsValue({
                automaticPeriod: data.automaticPeriod !== "false",
                year: dayjs(data.currentYear),
                group: data.currentGroup,
            });

        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setAutomaticPeriod("");
            setYear("");
            setQuarter("");
            formSettingPeriod.resetFields();
        });
        setLoading(false);
    }


    /*
    * Handle fetch data setting realization
    */
    async function fetchSettingRealization() {
        setLoading(true);
        await ApiConfig.get(`/env/get-lock-realization`).then(response => {
            const data = response.data;

            setLockRealization(data.lock !== "false");

            formSettingPeriod.setFieldsValue({
                lockRealization: data.lock !== "false",
            });

        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setLockRealization("");
        });
        setLoading(false);
    }


    /*
    * Handle fetch data setting realization 360
    */
    async function fetchSettingRealization360() {
        setLoading(true);
        await ApiConfig.get(`/env/get-lock-realization-360`).then(response => {
            const data = response.data;

            setLockRealization360(data.lock !== "false");

            formSettingPeriod.setFieldsValue({
                lockRealization360: data.lock !== "false",
            });

        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setLockRealization360("");
        });
        setLoading(false);
    }

    /*
    * Handle Update Function
    */
    async function setPeriod(values) {
        setLoadingBtn(true);
        const formData = {
            automaticPeriod: automaticPeriod,
            currentYear: dayjs(values.year).format(yearFormat),
            currentGroup: values.group,
        }

        await ApiConfig.post('/env/setting-period', formData).then(() => {
            setLock();
            setLock360();
            message.success('Setting Saved');
            fetchSettingPeriod().finally();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });

        setLoadingBtn(false);
    }


    /*
    * Handle Update Lock Realization
    */
    async function setLock() {
        setLoadingBtn(true);
        const formData = {
            lockRealization: lockRealization,
        }

        await ApiConfig.post('/env/setting-lock-realization', formData).then(() => {
            fetchSettingRealization().finally();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });

        setLoadingBtn(false);
    }

    /*
    * Handle Update Lock Realization 360
    */
    async function setLock360() {
        setLoadingBtn(true);
        const formData = {
            lockRealization360: lockRealization360,
        }

        await ApiConfig.post('/env/setting-lock-realization-360', formData).then(() => {
            fetchSettingRealization360().finally();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });

        setLoadingBtn(false);
    }

    return (
        <Form layout="vertical" onFinish={setPeriod} form={formSettingPeriod} requiredMark={false}>
            <Card className="shadow-sm" style={{ width: "100%" }}>
                <AuthVerify />

                <Card.Header>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            Setting
                        </Col>

                    </Row>
                </Card.Header>

                <Card.Body>
                    <Skeleton active loading={loading}>
                        <Row className="mt-4">
                            <Col xs sm lg={4}>
                                <Form.Item label="Tahun" name="year">
                                    <DatePicker onChange={handleYear} defaultValue={dayjs(currentDate)} picker="year" disabled={automaticPeriod === true}/>
                                </Form.Item>
                            </Col>
                            <Col xs sm lg={5}>
                                <Form.Item label="Group" name="group" rules={[ {required: true, message: "Please select a group"} ]}>
                                    <Segmented onChange={handleQuarter} options={['Q1', 'Q2', 'Q3', 'Q4']} disabled={automaticPeriod === true}/>
                                </Form.Item>
                            </Col>

                            <Col xs sm lg={3}>
                                <Form.Item label="Automatic Period" name="automaticPeriod">
                                    <Switch  checkedChildren="Yes" unCheckedChildren="No" defaultChecked={automaticPeriod} onChange={handleAutomaticPeriod} />
                                </Form.Item>
                            </Col>

                            <Col xs sm lg={3}>
                                <Form.Item label="Kunci Realisasi" name="lockRealization">
                                    <Switch  checkedChildren="Yes" unCheckedChildren="No" defaultChecked={lockRealization} onChange={handleLockRealization} />
                                </Form.Item>
                            </Col>

                            <Col xs sm lg={3}>
                                <Form.Item label="Kunci Penialain 360" name="lockRealization360">
                                    <Switch  checkedChildren="Yes" unCheckedChildren="No" defaultChecked={lockRealization360} onChange={handleLockRealization360} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Skeleton>
                </Card.Body>

                <Card.Footer className="text-muted text-end">
                    <Button type="primary" htmlType="submit" loading={loadingBtn}>
                        Save
                    </Button>
                </Card.Footer>

            </Card>
        </Form>
    );
}