/* Import Style */
import {Button, Flex, Form, Input} from "antd";

function UserPasswordForm(props) {

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        props.formReference.resetFields();
    }

    return (
        <>
            <Form.Item label="Password" name="password" rules={[
                { required: true, message: "Please input password" },
                { min: 6, message: "Password must have min 6 character" }
            ]} >
                <Input.Password />
            </Form.Item>
            <Form.Item name="confirm" label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                    { required: true, message: 'Please confirm your password!'},
                    { min: 6, message: "Password must have min 6 character" },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}

export default UserPasswordForm;