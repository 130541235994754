/* Import Component React */
import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";
import numberFormat from "../NumberFormat";

/* Import Style */
import {Card} from "react-bootstrap";
import {message, Skeleton, Modal, Descriptions, Progress, Button, Popconfirm,} from "antd";
import {MaterialReactTable} from "material-react-table";
import {Plus} from "react-feather";

/* Import Time Component */
import dayjs from "dayjs";

/*Import Child Component*/
import SkUpdate from "./SkUpdate";
import RealizationCreate from "./RealizationCreate";

/* Return the module */
export default  function SkDetail(props) {
    //Prepare variable
    let {id} = useParams();
    const grade = localStorage.getItem('grade');
    const userId = localStorage.getItem('userId');
    const monthFormat = 'M';
    const currentDate = new Date();
    const [skDetail, setSkDetail] = useState([]);
    const [realization, setRealization] = useState([]);
    const [lockRealization, setLockRealization] = useState(false);
    const [group, setGroup] = useState("");
    const [loadingView, setLoadingView] = useState(false);
    const modal = props.modal;
    const [modalRealization, setModalRealization] = useState(false);
    const columns = useMemo(
        () => [
            {
                accessorKey: "date",
                header: "Tanggal",
            },
            {
                accessorKey: "division",
                header: "Divisi",
            },
            {
                accessorKey: "department",
                header: "Departemen",
            },
            {
                accessorKey: "name",
                header: "Nama",
            },
            {
                accessorKey: "uom",
                header: "UoM",
            },
            {
                accessorKey: "target",
                header: "Target",
            },
            {
                accessorKey: "achievement",
                header: "Realisasi",
            },
            {
                accessorKey: "result",
                header: "Hasil",
            },
            {
                accessorKey: "review",
                header: "Ulasan",
            },
            // eslint-disable-next-line
        ], []
    );



    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchSkDetail().finally();
        fetchLockRealization().finally();
        setGroup(currentQuarter());
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch data Lock Realization
    */
    async function fetchLockRealization() {
        await ApiConfig.get(`/env/get-lock-realization`).then(response => {
            const data = response.data;
            if (data.lock === "false") {
                setLockRealization(false);
            } else {
                setLockRealization(true);
            }
            /*eslint-disable-next-line*/
        }).catch(error => {
            console.log(error);
        });
    };


    /*
    * Handle Current Quarter.
    */
    const currentQuarter = () => {
        const month = dayjs(currentDate).format(monthFormat);
        if (month <= 3) {
            return "Q1";
        } else if (month <= 6) {
            return "Q2";
        } else if (month <= 9) {
            return "Q3";
        } else {
            return "Q4";
        }
    }



    /*
    * Handle fetch detail data SK
    */
    async function fetchSkDetail() {
        setLoadingView(true);
        if (grade === "Manajer") {
            await ApiConfig.get(`/sk-manager/find/${id}`).then(response => {
                const data = response.data.doc;
                setSkDetail(data);
                setRealization(data.realization);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkDetail([]);
                setRealization([]);
            });
        } else if (grade === "Asisten Manajer") {
            await ApiConfig.get(`/sk-assistant-manager/find/${id}`).then(response => {
                const data = response.data.doc;
                setSkDetail(data);
                setRealization(data.realization);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkDetail([]);
                setRealization([]);
            });
        } else {
            await ApiConfig.get(`/sk-staff/find/${id}`).then(response => {
                const data = response.data.doc;
                setSkDetail(data);
                setRealization(data.realization);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkDetail([]);
                setRealization([]);
            });
        }

        setLoadingView(false);
    }



    /*
    * Handle Delete Evidence
    */
    async function confirmDestroyEvidence(id) {
        await ApiConfig.delete(`/realization/destroy/${id}`).then(() => {
            fetchSkDetail();
            message.success("Delete Realisasi Success");
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }

    const borderedItems = [
        {
            key: '1',
            label: 'ID - Tahun',
            children: (
                <>
                    { skDetail.type}.{skDetail.serialNumber} - {skDetail.year}
                </>
            ),
        },
        {
            key: '2',
            label: 'Group',
            children: skDetail.group,
        },
        {
            key: '3',
            label: 'Link Target',
            children: skDetail.linkTarget === true ? "YA" : "TIDAK",
        },
        {
            key: '4',
            label: 'Target - UoM [Tipe Target]',
            children:(
                <>
                    {numberFormat(skDetail.target)} - {skDetail.uom} [{skDetail.roleTarget}]
                </>
            ),
        },
        {
            key: '5',
            label: 'Actual',
            children:(
                <>
                    {numberFormat(skDetail.actual)}
                </>
            ),
        },
        {
            key: '6',
            label: 'GAP',
            children:(
                <>
                    {numberFormat(Math.round(skDetail.gap))}
                </>
            ),
        },
        {
            key: '7',
            label: 'Achievement %',
            children: (
                <>
                    {Math.round(skDetail.ach/skDetail.target*100 > 100 ? 100 : skDetail.ach/skDetail.target*100)}%
                </>
            ),
        },
        {
            key: '8',
            label: 'Score ',
            children: (
                <>
                    {skDetail.score}%
                </>
            )
        },
        {
            key: '9',
            label: 'Achievement Score %',
            children: (
                <>
                    {skDetail.achScore}%
                </>
            )
        },
        {
            key: '10',
            label: 'Reference',
            span: 3,
            children: (
                <>
                    {skDetail.reference}
                </>


            ),
        },
        {
            key: '11',
            label: 'Sasaran Kinerja',
            span: 3,
            children: (
                <>
                    {skDetail.performanceGoal}
                </>


            ),
        },
        {
            key: '12',
            label: 'Uraian',
            span: 3,
            children: (
                <>
                    {skDetail.description}
                    {`${lockRealization}`}
                </>
            ),
        },
    ];


    return (
        <>
            <Skeleton active loading={loadingView}>
                <AuthVerify />
                {/*Handle Update SK*/}
                <Modal
                    title="Update Sasaran Kinerja"
                    open={modal.modalUpdateSk}
                    onCancel={() => modal.setModalUpdateSk(false)}
                    width={700}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button*/
                    ]}
                >
                    <hr/>
                    <SkUpdate
                        fetchData={fetchSkDetail}
                        skDetail={skDetail}
                        modalClose={modal.setModalUpdateSk}
                    />

                </Modal>

                {/*Handle Realisasi SK*/}
                <Modal
                    title="Realisasi SK"
                    open={modalRealization}
                    onCancel={() => setModalRealization(false)}
                    width={500}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button*/
                    ]}
                >
                    <hr/>
                    <RealizationCreate
                        fetchData={fetchSkDetail}
                        reference={id}
                        skDetail={skDetail}
                        modal={setModalRealization}
                    />

                </Modal>

                {/*Description KPI*/}
                <Descriptions
                    bordered
                    size="small"
                    items={borderedItems}
                    className="mt-3"
                />

                {/*Table Contribute*/}
                <Card className="card-body shadow-sm">
                    <MaterialReactTable
                        state={{ isLoading: loadingView }}
                        columns={columns}
                        data={realization}
                        enablePinning
                        enableGlobalFilter={true}
                        enableDensityToggle={false}
                        enableStickyHeader={true}
                        initialState={{ density: 'compact' }}
                        enableColumnResizing={true}
                        columnResizeMode="onChange"
                        enableRowNumbers={true}
                        enableRowActions={true}
                        positionActionsColumn="last"
                        //Double click for edit
                        muiTableBodyCellProps={({ row }) => ({
                            onDoubleClick: () => {
                                window.open(`${row.original.urlFile}`, "_blank")
                            },
                        })}
                        //Row Actions
                        renderRowActions={({ row}) => (
                            <Popconfirm
                                placement="left"
                                title="Delete"
                                description="Are you sure to delete this?"
                                onConfirm={() => confirmDestroyEvidence(row.original.id)}
                                onCancel={() => message.error(`Delete Realisasi Canceled`)}
                                okText="Yes"
                                cancelText="No"
                            >
                                {
                                    userId === row.original.createdBy &&
                                    <Button size="small" > Delete </Button>
                                }
                            </Popconfirm>
                        )}
                        //Display Head Table
                        muiTableHeadCellProps={{
                            sx: {
                                fontWeight: 'bold',
                                fontSize: '14px',
                                color: '#1976d2',
                            },
                        }}
                        //Height Table
                        muiTableContainerProps={{
                            sx: {
                                minHeight: '200px',
                                maxHeight: '200px'
                            }
                        }}
                        //Custom Width Column
                        displayColumnDefOptions={{
                            'mrt-row-numbers': {
                                size: 10,
                            },
                        }}
                        //add custom action buttons to top-left of top toolbar
                        renderTopToolbarCustomActions={({ table }) => (
                            <Button
                                type="primary"
                                htmlType="button"
                                onClick={() => setModalRealization(true)}
                                disabled={ skDetail.status === "Submission" ? true : lockRealization === false ? false : skDetail.group !== group }
                            >
                                <Plus className="feather-button"/>
                                Add Realisasi
                            </Button>
                        )}
                    />
                </Card>
            </Skeleton>
        </>
    );
}