/* Import Component React */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, DatePicker, Form, Segmented, Space} from "antd";

/* Import Time Component */
import dayjs from "dayjs";

/*Import Child Component*/

/* Return the module */
export default function SkTeamView(props) {
    //Prepare variable
    const userId = localStorage.getItem('userId');
    const [formPeriod] = Form.useForm();
    const [skTeam, setSkTeam] = useState([]);
    const [loading, setLoading] = useState(false);
    const yearFormat = 'YYYY';
    const monthFormat = 'M';
    const currentDate = new Date();
    const [year, setYear] = useState(dayjs(currentDate).format(yearFormat));
    const [quarter, setQuarter] = useState("");
    const navigate = useNavigate();
    const columns = useMemo(
        () => [
            {
                accessorKey: "division",
                header: "Divisi",
            },
            {
                accessorKey: "department",
                header: "Departemen",
                minSize: 350,
                maxSize: 350,
            },
            {
                accessorKey: "teamName",
                header: "Nama",
            },
            {
                accessorKey: "countSk",
                header: "Total SK",
            },
            {
                accessorKey: "score",
                header: "Total Score",
            },
            {
                accessorKey: "status",
                header: "Status",
            },
            {
                accessorKey: "ach",
                header: "Ach%",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        document.title = 'Sasaran Kinerja Team';
        fetchSettingPeriod().finally();
        setQuarter(currentQuarter);
        /*eslint-disable-next-line*/
    }, [])



    /*
    * Handle fetch data setting period
    */
    async function fetchSettingPeriod() {
        setLoading(true);
        await ApiConfig.get(`/env/get-setting-period`).then(response => {
            const data = response.data;
            if (data.automaticPeriod === "false") {
                fetchSkTeam(data.currentYear, data.currentGroup).finally();
                setYear(data.currentYear);
                setQuarter(data.currentGroup);
                formPeriod.setFieldsValue({
                    tahun: dayjs(data.currentYear),
                    group: data.currentGroup,
                });
            } else {
                fetchSkTeam(year, currentQuarter()).finally();
                formPeriod.setFieldsValue({
                    tahun: dayjs(year),
                    group: currentQuarter(),
                });
                setQuarter(currentQuarter);
            }
            /*eslint-disable-next-line*/
        }).catch(error => {
            console.log(error);
        });
        setLoading(false);
    }



    /*
    * Handle Current Quarter.
    */
    const currentQuarter = () => {
        const month = dayjs(currentDate).format(monthFormat);
        if (month <= 3) {
            return "Q1";
        } else if (month <= 6) {
            return "Q2";
        } else if (month <= 9) {
            return "Q3";
        } else {
            return "Q4";
        }
    }



    /*
    * Handle year user trigger form select year.
    */
    const handleYear = useCallback( (value) => {
        const selectedYear = dayjs(value).format(yearFormat);
        fetchSkTeam(selectedYear, quarter).finally();
        setYear(selectedYear);
        /* eslint-disable */
    }, [year, quarter]);



    /*
    * Handle Quarter by current month
    */
    const handleQuarter = useCallback( (value) => {
        fetchSkTeam(year, value).finally();
        setQuarter(value);
    }, [year, quarter]);



    /*
    * Handle fetch data SK Team
    */
    async function fetchSkTeam(year, quarter) {
        setLoading(true);
        await ApiConfig.get(`/sk-team/list-team/${userId}/${year}/${quarter}`).then(response => {
            const data = response.data.doc;
            setSkTeam(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setSkTeam([]);
        });
        setLoading(false);
    }



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={skTeam}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact' }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: () => {
                        row.original.countSk !== 0 ?
                            navigate(`/system/sk-team/detail/${row.original.userId}/${row.original.grade}/${year}/${quarter}`)
                            :
                            message.info(`${row.original.teamName} Belum Memiliki Sasaran Kinerja`);
                    },
                })}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-numbers': {
                        size: 10,
                    },
                    'mrt-row-actions': {
                        // header: 'Action', //change header text
                        // size: 50, //make actions column wider
                    },
                }}
                //add custom action buttons to top-left of top toolbar
                renderTopToolbarCustomActions={({ table }) => (
                    <Form layout="horizontal" form={formPeriod} requiredMark={false}>
                        <Space>
                            <Form.Item label="Tahun" name="tahun">
                                <DatePicker onChange={handleYear} defaultValue={dayjs(currentDate)} picker="year" />
                            </Form.Item>
                            <Form.Item label="Group" name="group" className="ms-4">
                                <Segmented onChange={handleQuarter} options={['Q1', 'Q2', 'Q3', 'Q4']} defaultValue={currentQuarter()}/>
                            </Form.Item>
                        </Space>
                    </Form>
                )}
            />
        </Card>
    );
}