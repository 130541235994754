/* Import Component React*/
import {Button} from "antd";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import React, {useState} from "react";

/*Import Style*/
import {ChevronLeft, Plus} from "react-feather";

/* Import component module */
import CompanyView from "./CompanyView";
import CompanyDetail from "./CompanyDetail";


/* Return the module*/
function CompanyNav() {
    //Handle Modal Entry
    const [modalEntryCompany, setModalEntryCompany] = useState(false);
    const role = localStorage.getItem('role');
    //Location path
    const location = useLocation();
    const navigate = useNavigate();



    /*
    * Handle close modal entry company
    */
    const handleCloseModalEntryCompany = () => {
        setModalEntryCompany(false);
    }

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4 ms-2" style={{ fontWeight: "bolder"}}>Perusahaan Aktif</h4>
                {
                    location.pathname === '/system/company' ?
                        <Button type="primary" className="me-2"  onClick={() => setModalEntryCompany(true)} disabled={location.pathname !== '/system/company'}>
                            <Plus className="feather-button"/>
                            Entry
                        </Button>
                        :

                        role === 'Owners' &&
                        <Button type="primary" className="me-2" onClick={() => navigate(`/system/company`)} >
                            <ChevronLeft className="feather-button"/>
                            Back
                        </Button>

                }
            </div>
            <Routes>
                <Route path="/" element={<CompanyView modal={{modalEntryCompany, handleCloseModalEntryCompany}}/>} />
                <Route path="detail/:id" element={<CompanyDetail/>} />
            </Routes>
        </>
    );
}

export default CompanyNav;