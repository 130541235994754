/* Import component react */
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import {jwtDecode} from "jwt-decode";

const AuthVerify = () => {
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('token');
    const currentDate = new Date();

    /*eslint-disable*/
    useEffect(() => {
        //Checking if access token exist
        if (!accessToken) {
            //Back to log in
            navigate('/');
        } else {
            const decodeToken = jwtDecode(accessToken);
            console.log(decodeToken.exp);
            console.log(currentDate.getTime());
            //Checking token if expired
            if (decodeToken.exp * 1000 < currentDate.getTime()) {
                logout().finally();
            }
        }
    }, []);

    /* Log out */
    const logout = async () => {
        await ApiConfig.get(`/auth/logout/${accessToken}`).then(response => {
            console.log(response);
            removeStorage();
            navigate('/');
        }).catch( error => {
            console.log(error);
            removeStorage();
            navigate('/');
        });
    }


    //Remove Storage
    const removeStorage = () => {
        //Token
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('companyId');
        localStorage.removeItem('companyName');
        localStorage.removeItem('urlLogo');
        localStorage.removeItem('directorateId');
        localStorage.removeItem('divisionId');
        localStorage.removeItem('departmentId');
        localStorage.removeItem('positionId');
        localStorage.removeItem('grade');
        localStorage.removeItem('role');
        localStorage.removeItem('nik');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('superior');
    }
};

export default AuthVerify;