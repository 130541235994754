/*Import Component React*/
import {useCallback, useEffect, useRef, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Col, Row} from "react-bootstrap";
import {Button, Flex, Form, Input, message, Select, Upload} from "antd";
import {UploadCloud} from "react-feather";

function UserForm(props) {
    const companyId = localStorage.getItem('companyId');
    const focusRef = useRef(null);
    const [listDirectorate, setListDirectorate] = useState([]);
    const [loadingListDirectorate, setLoadingListDirectorate] = useState(false);
    const [listDivision, setListDivision] = useState([]);
    const [loadingListDivision, setLoadingListDivision] = useState(false);
    const [listDepartment, setListDepartment] = useState([]);
    const [loadingListDepartment, setLoadingListDepartment] = useState(false);
    const [listPosition, setListPosition] = useState([]);
    const [loadingListPosition, setLoadingListPosition] = useState(false);
    const disableBtnDivision = props.disableBtnDivision;
    const disableBtnDepartment = props.disableBtnDepartment;
    const disableBtnPosition= props.disableBtnPosition;
    const listRole = [
        {
            'value': "Super Admin",
            'label': "Super Admin",
        },
        {
            'value': "Admin",
            'label': "Admin",
        },
        {
            'value': "User",
            'label': "User",
        },
    ];

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        props.formReference.resetFields();
        disableBtnDivision.setDisableBtnDivision(true);
        disableBtnDepartment.setDisableBtnDepartment(true);
        disableBtnPosition.setDisableBtnPosition(true);
    }



    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);



    /*
    * Handle list directorate trigger form select.
    */
    const handleListDirectorate = useCallback( () => {
        fetchListDirectorate().finally();
        /* eslint-disable */
    }, [listDirectorate]);

    /*
    * Handle fetch data directorate
    */
    async function fetchListDirectorate() {
        //Await
        setLoadingListDirectorate(true);
        await ApiConfig.get(`/directorate/select/${companyId}`).then(response => {
            setListDirectorate(response.data.doc);
        }).catch((error) => {
            console.log(error);
            message.error(error.response.data.message);
            setListDirectorate([]);
        });
        setLoadingListDirectorate(false);
    }

    /*
    * Handle list after selected directorate
    */
    const handleListAfterSelect = useCallback((values) => {
        fetchListDivision(values).finally();
        fetchListPosition(values).finally();
        /* eslint-disable */
    }, [listDivision])

    /*
    * Handle fetch data division
    */
    async function fetchListDivision(values) {
        //Await
        setLoadingListDivision(true);
        await ApiConfig.get(`/division/reference/${companyId}/${values}`).then(response => {
            setListDivision(response.data.doc);
            disableBtnDivision.setDisableBtnDivision(false);
            // disableBtnPosition.setDisableBtnDepartment(false);
            //Reset reference if list division found
            props.formReference.setFieldsValue({
                departmentId: null,
                divisionId: null,
            });
        }).catch((error) => {
            console.log(error);
            // message.error(error.response.data.message);
            setListDivision([]);
            disableBtnDivision.setDisableBtnDivision(true);
            // disableBtnDepartment.setDisableBtnDepartment(true);
            //Reset reference if list division not found
            props.formReference.setFieldsValue({
                departmentId: null,
                divisionId: null,
            });
        });
        setLoadingListDivision(false);
    }



    /*
    * Handle list department by selected division
    */
    const handleListDepartment = useCallback((values) => {
        fetchListDepartment(values).finally();
        /* eslint-disable */
    }, [listDepartment])

    /*
    * Handle fetch data department
    */
    async function fetchListDepartment(values) {
        //Await
        setLoadingListDepartment(true);
        await ApiConfig.get(`/department/reference/${companyId}/${values}`).then(response => {
            setListDepartment(response.data.doc);
            disableBtnDepartment.setDisableBtnDepartment(false);
            props.formReference.setFieldsValue({
                departmentId: null,
            });
        }).catch((error) => {
            console.log(error);
            // message.error(error.response.data.message);
            setListDepartment([]);
            disableBtnDepartment.setDisableBtnDepartment(true);
            props.formReference.setFieldsValue({
                departmentId: null,
            });
        });
        setLoadingListDepartment(false);
    }



    /*
    * Handle fetch data position
    */
    async function fetchListPosition(values) {
        //Await
        setLoadingListPosition(true);
        await ApiConfig.get(`/position/select/${companyId}/${values}`).then(response => {
            setListPosition(response.data.doc);
            disableBtnPosition.setDisableBtnPosition(false);
            //Reset reference if list division found
            props.formReference.setFieldsValue({
                positionId: null
            });
        }).catch((error) => {
            console.log(error);
            // message.error(error.response.data.message);
            setListPosition([]);
            disableBtnPosition.setDisableBtnPosition(true);
            //Reset reference if list division not found
            props.formReference.setFieldsValue({
                positionId: null
            });
        });
        setLoadingListPosition(false);
    }

    return (
        <>
            <Row className="justify-content-center">
                <Col xs sm lg={6}>
                    <Form.Item label="NIK" name="nik" rules={[
                        { required: true, message: "Please input nik" },
                        { min: 3, message: "Nik must have min 3 character" }
                    ]}>
                        <Input showCount maxLength={16} ref={focusRef}/>
                    </Form.Item>

                    <Form.Item label="Nama" name="name" rules={[
                        { required: true, message: "Please input nama" },
                        { min: 3, message: "Nama must have min 3 character" }
                    ]}>
                        <Input showCount maxLength={100}/>
                    </Form.Item>

                    <Form.Item label="Email" name="email" rules={[
                        { required: true, message: "Please input email" },
                        { type: 'email', message: "Is not a valid email"},
                        { min: 10, message: "Email must have min 10 character" }
                    ]}>
                        <Input showCount maxLength={100} />
                    </Form.Item>

                    <Form.Item label="Password" name="password" rules={[
                        { required: true, message: "Please input password" },
                        { min: 6, message: "Password must have min 6 character" }
                    ]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item label="Upload Profile" name="profilePict" rules={[
                        { required: true, message: "Please upload profile" }
                    ]}>
                        <Upload {...props.files}>
                            <Button icon={<UploadCloud className="feather-button"/>} style={{ width: "100%"}}>Select to Upload</Button>
                        </Upload>
                    </Form.Item>
                </Col>

                <Col xs sm lg={6}>
                    <Form.Item label="Direksi" name="directorateId" rules={[ {required: true, message: "Please select a reference"} ]}>
                        <Select placeholder="Select a reference" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } onClick={handleListDirectorate} onSelect={handleListAfterSelect} onChange={handleListAfterSelect} loading={loadingListDirectorate} options={listDirectorate}
                        />
                    </Form.Item>

                    <Form.Item label="Divisi" name="divisionId">
                        <Select placeholder="Select a reference" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } loading={loadingListDivision} onSelect={handleListDepartment} onChange={handleListDepartment} options={listDivision}  disabled={disableBtnDivision.disableBtnDivision}
                        />
                    </Form.Item>

                    <Form.Item label="Departemen" name="departmentId">
                        <Select placeholder="Select a reference" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } loading={loadingListDepartment} options={listDepartment} disabled={disableBtnDepartment.disableBtnDepartment}/>
                    </Form.Item>

                    <Form.Item label="Jabatan" name="positionId">
                        <Select placeholder="Select a reference" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } loading={loadingListPosition} options={listPosition} disabled={disableBtnPosition.disableBtnPosition}/>
                    </Form.Item>

                    <Form.Item label="Role" name="role" rules={[ {required: true, message: "Please select a role"} ]}>
                        <Select placeholder="Select a role" allowClear showSearch filterOption={
                            (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        } options={listRole}/>
                    </Form.Item>
                </Col>
            </Row>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}

export default UserForm;