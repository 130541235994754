/* Import Component React */
import {useCallback, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message, Select} from "antd";

export default function UoM(props) {
    const companyId = localStorage.getItem('companyId');
    const [listUoms, setListUoms] = useState([]);
    const [loadingList, setLoadingList] = useState(false);


    /*
    * Handle List Uom.
    */
    const handleListUom = useCallback(() => {
        fetchListUom().finally();
    }, [listUoms]);



    /*
    * Handle Fetch List Uom.
    */
    async function fetchListUom() {
        //Await
        setLoadingList(true);
        await ApiConfig.get(`/uom/${companyId}`).then(response => {
            const rawList = response.data.doc;
            let list = rawList.map(uom => ({
                value: uom.value,
                label: uom.label,
            }));
            setListUoms(list);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setListUoms([]);
        });
        setLoadingList(false);
    }

    return (
        <>
            <Form.Item label="UoM" name="uom" rules={[ {required: true, message: "Please select a uom"} ]}>
                <Select placeholder="Select a uom" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } onClick={handleListUom} onFocus={handleListUom} loading={loadingList} options={listUoms} disabled={props.status === "Approved" || props.targetStatus}/>
            </Form.Item>
        </>
    )
}