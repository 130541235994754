/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, Modal, message} from "antd";

/*Import Child Component*/
import DivisiForm from "./DivisiForm";

function DivisiCreate(props) {
    //Prepare variable
    const [formEntryDivisi] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);

    /*
    * Handle Store Data
    */
    const storeDataDivisi = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            directorateId: values.directorateId,
            divisionName: values.divisionName,
            description: values.description,
        };

        //Send data to backend for store
        await ApiConfig.post('/division/store', formData).then(() => {
            message.success(`Divisi ${values.divisionName} Saved`);
            props.fecthData();
            formEntryDivisi.resetFields();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            {/*Handle Create Delivery*/}
            <Modal
                title="Input Data Divisi"
                open={props.modalEnrty}
                onCancel={props.modalClose}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <Form layout="vertical" onFinish={storeDataDivisi} form={formEntryDivisi} requiredMark={false} className="mt-3">
                    <DivisiForm
                        loading={loadingBtn}
                        formReference={formEntryDivisi}
                        logoRequired={true}
                        button={"Submit"}
                    />
                </Form>
            </Modal>
        </>
    )
}

export default DivisiCreate;