/* Import Component React */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";
import fileDownload from "js-file-download";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, DatePicker, Form, Segmented, Space, Button} from "antd";
import {ExternalLink} from "react-feather";

/* Import Time Component */
import dayjs from "dayjs";

/*Import Child Component*/

/* Return the module */
export default function OverviewView() {
    //Prepare variable
    const companyId = localStorage.getItem('companyId');
    const [formPeriod] = Form.useForm();
    const [sk, setSk] = useState([]);
    const [loading, setLoading] = useState(false);
    const yearFormat = 'YYYY';
    const monthFormat = 'M';
    const currentDate = new Date();
    const [year, setYear] = useState(dayjs(currentDate).format(yearFormat));
    const [quarter, setQuarter] = useState("");
    const navigate = useNavigate();
    const columns = useMemo(
        () => [
            {
                accessorKey: "division",
                header: "Divisi",
            },
            {
                accessorKey: "department",
                header: "Departemen",
                minSize: 350,
                maxSize: 350,
            },
            {
                accessorKey: "teamName",
                header: "Nama",
            },
            {
                accessorKey: "countSk",
                header: "Total SK",
            },
            {
                accessorKey: "score",
                header: "Total Score",
            },
            {
                accessorKey: "status",
                header: "Status",
            },
            {
                accessorKey: "ach",
                header: "Ach%",
            },
            {
                accessorKey: "achDivisionToCompany",
                header: "Ach Divisi To KPI Perusahaan",
            },
            {
                accessorKey: "individu50Point",
                header: "50% Ach Individu",
            },
            {
                accessorKey: "individu50Point",
                header: "50% Ach Division",
            },
            {
                accessorKey: "totalPoint",
                header: "Total Ach",
            },

            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        document.title = 'Sasaran Kinerja Team';
        fetchSettingPeriod().finally();
        setQuarter(currentQuarter);
        /*eslint-disable-next-line*/
    }, [])



    /*
    * Handle fetch data setting period
    */
    async function fetchSettingPeriod() {
        setLoading(true);
        await ApiConfig.get(`/env/get-setting-period`).then(response => {
            const data = response.data;
            if (data.automaticPeriod === "false") {
                fetchSk(data.currentYear, data.currentGroup).finally();
                setYear(data.currentYear);
                setQuarter(data.currentGroup);
                formPeriod.setFieldsValue({
                    tahun: dayjs(data.currentYear),
                    group: data.currentGroup,
                });
            } else {
                fetchSk(year, currentQuarter()).finally();
                formPeriod.setFieldsValue({
                    tahun: dayjs(year),
                    group: currentQuarter(),
                });
                setQuarter(currentQuarter);
            }
            /*eslint-disable-next-line*/
        }).catch(error => {
            console.log(error);
        });
        setLoading(false);
    }



    /*
    * Handle Current Quarter.
    */
    const currentQuarter = () => {
        const month = dayjs(currentDate).format(monthFormat);
        if (month <= 3) {
            return "Q1";
        } else if (month <= 6) {
            return "Q2";
        } else if (month <= 9) {
            return "Q3";
        } else {
            return "Q4";
        }
    }



    /*
    * Handle year user trigger form select year.
    */
    const handleYear = useCallback( (value) => {
        const selectedYear = dayjs(value).format(yearFormat);
        fetchSk(selectedYear, quarter).finally();
        setYear(selectedYear);
        /* eslint-disable */
    }, [year, quarter]);



    /*
    * Handle Quarter by current month
    */
    const handleQuarter = useCallback( (value) => {
        fetchSk(year, value).finally();
        setQuarter(value);
    }, [year, quarter]);



    /*
    * Handle fetch data SK
    */
    async function fetchSk(year, quarter) {
        setLoading(true);
        await ApiConfig.get(`/overview`, {
            params: { year: year, group: quarter, companyId: companyId }
        }).then(response => {
            const data = response.data.doc;
            setSk(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setSk([]);
        })
        setLoading(false);
    }

    /*
    * Handle Report Data
    */
    const report = useCallback( async () => {
        await ApiConfig.get('/overview/report', {
            params: { year: year, group: quarter, companyId: companyId },
            responseType: 'blob',
        }).then( response => {
            fileDownload(response.data, `Overview-${year}-${quarter}.xlsx`);
            message.success(`Overview-${year}-${quarter}.xlsx Exported`);
        }).catch( error => {
            console.log(error);
            message.error(error);
        });
        /* eslint-disable */
    }, [year, quarter]);



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={sk}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact' }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: () => {
                        row.original.countSk !== 0 ?
                            navigate(`/system/overview/detail/${row.original.userId}/${row.original.grade}/${year}/${quarter}`)
                            :
                            message.info(`${row.original.teamName} Belum Memiliki Sasaran Kinerja`);
                    },
                })}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-numbers': {
                        size: 10,
                    },
                    'mrt-row-actions': {
                        // header: 'Action', //change header text
                        // size: 50, //make actions column wider
                    },
                }}
                //add custom action buttons to top-left of top toolbar
                renderTopToolbarCustomActions={({ table }) => (
                    <Form layout="horizontal" form={formPeriod} requiredMark={false}>
                        <Space>
                            <Form.Item label="Tahun" name="tahun">
                                <DatePicker onChange={handleYear} defaultValue={dayjs(currentDate)} picker="year" />
                            </Form.Item>
                            <Form.Item label="Group" name="group" className="ms-4">
                                <Segmented onChange={handleQuarter} options={['Q1', 'Q2', 'Q3', 'Q4']} defaultValue={currentQuarter()}/>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={report} >
                                    <ExternalLink className="feather-button"/>
                                    Report
                                </Button>
                            </Form.Item>
                        </Space>
                    </Form>
                )}
            />
        </Card>
    );
}