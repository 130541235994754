/* Import Component React */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button, Modal, Progress, Space} from "antd";

/*Import Child Component*/
import KriteriaCreate from "./KriteriaCreate";
import KriteriaUpdate from "./KriteriaUpdate";

/* Return the module */
export default function Kriteria360View(props) {
    //Variable State
    const companyId = localStorage.getItem('companyId');
    const [kriteria, setKriteria] = useState([]);
    const [detailKriteria, setDetailKriteria] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    //State Modal
    const modal = props.modal;
    const [modalUpdateKriteria, setModalUpdateKriteria] = useState(false);

    //State Columns
    const columns = useMemo(
        () => [
            {
                accessorKey: "kriteria",
                header: "Kriteria",
                minSize: 490,
                maxSize: 490,
            },
            {
                accessorKey: "score",
                header: "Score",
                minSize: 60,
                maxSize: 60,
            },
            // {
            //     accessorKey: "active",
            //     header: "Active",
            //     minSize: 100,
            //     maxSize: 100,
            // },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchKriteria().finally();
        document.title = 'Kriteria 360';
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch data kriteria
    */
    async function fetchKriteria() {
        setLoading(true);
        await ApiConfig.get(`/kriteria-360/${companyId}`).then(response => {
            const data = response.data.doc;
            setKriteria(data);
            setTotalScore(response.data.totalScore);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setKriteria([])
            setTotalScore(0);
        });
        setLoading(false);
    }


    /*
    * Handle fetch data detail kriteria
    */
    const fetchDetailKriteria =  async (id) => {
        await ApiConfig.get(`/kriteria-360/find/${id}`).then(response => {
            const data = response.data.doc;
            setDetailKriteria(data);
            setModalUpdateKriteria(true)
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setDetailKriteria([]);
            setModalUpdateKriteria(false);
        });
    }



    /*
    * Handle update active kriteria.
    */
    const updateDetailKriteria =  async (id) => {
        await ApiConfig.get(`/kriteria-360/find/${id}`).then(async response => {
            const data = response.data.doc;
            const formData = {
                companyId: localStorage.getItem('companyId'),
                kriteria: data.kriteria,
                score: data.score,
                updatedBy: localStorage.getItem('userId'),
                active: data.active !== true,
            };

            //Send data to backend for store
            await ApiConfig.patch(`/kriteria-360/update/${data.id}`, formData).then(() => {
                message.success(`Kriteria Updated`);
                fetchKriteria().finally();
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    /*
    * Handle Delete Data
    */
    const confirmDelete = async (id) => {
        //Sending request delete
        await ApiConfig.delete(`/kriteria-360/destroy/${id}`).then(() => {
            message.success(`Deleted Successful`);
            fetchKriteria();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Row className="justify-content-center">
            <Col xs sm lg={11}>
                <Card className="card-body shadow-sm">
                    <AuthVerify />

                    {/*Handle Kriteria Create*/}
                    <Modal
                        title="Input Data Kriteria"
                        open={modal.modalEntryKriteria}
                        onCancel={() => modal.setModalEntryKriteria(false)}
                        width={550}
                        style={{
                            top: 57,
                        }}
                        footer={[
                            /*Disable Button */
                        ]}
                    >
                        <hr/>
                        <KriteriaCreate
                            fetchData={fetchKriteria}
                        />

                    </Modal>

                    {/*Handle Kriteria Update*/}
                    <Modal
                        title="Update Data Kriteria"
                        open={modalUpdateKriteria}
                        onCancel={() => setModalUpdateKriteria(false)}
                        width={550}
                        style={{
                            top: 57,
                        }}
                        footer={[
                            /*Disable Button */
                        ]}
                    >
                        <hr/>
                        <KriteriaUpdate
                            fetchData={fetchKriteria}
                            detailData={detailKriteria}
                            modalSet={setModalUpdateKriteria}
                        />

                    </Modal>

                    <MaterialReactTable
                        state={{ isLoading: loading }}
                        columns={columns}
                        data={kriteria}
                        enablePinning
                        enableGlobalFilter={true}
                        enableDensityToggle={false}
                        enableStickyHeader={true}
                        initialState={{ density: 'compact'}}
                        enableColumnResizing={true}
                        columnResizeMode="onChange"
                        enableRowNumbers={true}
                        enableRowActions={true}
                        positionActionsColumn="last"
                        //Double click for edit
                        muiTableBodyCellProps={({ row }) => ({
                            onDoubleClick: () => {
                                fetchDetailKriteria(row.original.id).finally();
                            },
                        })}
                        //Row Actions
                        renderRowActions={({ row}) => (
                            <Space>
                                <Popconfirm
                                    placement="left"
                                    title={row.original.active === "YA" ? "Disable" : "Enable"}
                                    description={`Are you sure to ${row.original.active === "YA" ? "disable" : "enable"} this?`}
                                    onConfirm={() => updateDetailKriteria(row.original.id)}
                                    onCancel={() => message.error(`Update Active Canceled`)}
                                    okText="Yes"
                                    cancelText="No"
                                > <Button size="small" type="primary" danger={row.original.active !== "YA"}> {row.original.active === "YA" ? "Disable" : "Enable"} </Button>
                                </Popconfirm>
                                <Popconfirm
                                    placement="left"
                                    title="Delete"
                                    description="Are you sure to delete this?"
                                    onConfirm={() => confirmDelete(row.original.id)}
                                    onCancel={() => message.error(`Delete Canceled`)}
                                    okText="Yes"
                                    cancelText="No"
                                > <Button size="small"> Delete </Button>
                                </Popconfirm>
                            </Space>
                        )}
                        //Display Head Table
                        muiTableHeadCellProps={{
                            sx: {
                                fontWeight: 'bold',
                                fontSize: '14px',
                                color: '#1976d2',
                            },
                        }}
                        //Height Table
                        muiTableContainerProps={{
                            sx: {
                                minHeight: '400px',
                                maxHeight: '500px'
                            }
                        }}
                        //Custom Width Column
                        displayColumnDefOptions={{
                            'mrt-row-numbers': {
                                size: 10,
                            },
                            'mrt-row-actions': {
                                // header: 'Action', //change header text
                                // size: 50, //make actions column wider
                            },
                        }}
                    />
                </Card>
            </Col>

            <Col xs sm lg={1}>
                <Card className="card-body shadow-sm p-0 text-center">
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">Detail</ListGroup.Item>

                        <ListGroup.Item className="item-instruction text-center">
                            Total Score <br/>
                            <Progress type="circle" className="mt-1" percent={totalScore} size={55} format={(percent) => `${percent} %`} />
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
    );
}