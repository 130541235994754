/* Import Component React */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button, Modal, DatePicker, Form, Progress} from "antd";

/* Import Time Component */
import dayjs from "dayjs";

/*Import Child Component*/
import KpiCompanyCreate from "./KpiCompanyCreate";

/* Return the module */
export default function KpiCompanyView(props) {
    //Prepare variable
    const companyId = localStorage.getItem('companyId');
    const [formPeriod] = Form.useForm();
    const [kpiCompany, setKpiCompany] = useState([]);
    const [score, setScore] = useState(0);
    const [totalAch, setTotalAch] = useState(0);
    const [loading, setLoading] = useState(false);
    const yearFormat = 'YYYY';
    const currentDate = new Date();
    const [year, setYear] = useState(dayjs(currentDate).format(yearFormat));
    const navigate = useNavigate();
    const modal = props.modal;
    const columns = useMemo(
        () => [
            {
                accessorKey: "serialNumber",
                header: "ID",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "performanceGoal",
                header: "Sasaran Kinerja",
                minSize: 550,
                maxSize: 550,
            },
            {
                accessorKey: "uom",
                header: "UoM",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "target",
                header: "Target",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "roleTarget",
                header: "Tipe",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "actual",
                header: "Actual",
                minSize: 110,
                maxSize: 110,
            },
            {
                accessorKey: "gap",
                header: "GAP",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "score",
                header: "Score",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "ach",
                header: "Ach%",
                minSize: 100,
                maxSize: 100,
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchSettingPeriod().finally();
        document.title = 'KPI Perusahaan';
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle year user trigger form select year.
    */
    const handleYear = useCallback( (value) => {
        const selectedYear = dayjs(value).format(yearFormat);
        fetchKpiCompany(selectedYear).finally();
        setYear(selectedYear);
        /* eslint-disable */
    }, [year]);



    /*
    * Handle fetch data setting period
    */
    async function fetchSettingPeriod() {
        setLoading(true);
        await ApiConfig.get(`/env/get-setting-period`).then(response => {
            const data = response.data;
            data.automaticPeriod === "false" ? (
                fetchKpiCompany(data.currentYear).finally(),
                setYear(data.currentYear),
                formPeriod.setFieldsValue({
                    tahun: dayjs(data.currentYear),
                })
            ) : (
                fetchKpiCompany(year).finally(),
                formPeriod.setFieldsValue({
                    tahun: dayjs(year),
                })
            );

        }).catch(error => {
            console.log(error);
        });
        setLoading(false);
    }



    /*
    * Handle fetch data kpi company
    */
    async function fetchKpiCompany(year) {
        setLoading(true);
        await ApiConfig.get(`/kpi-company/all`, {
            params: {companyId: companyId, year: year}
        }).then(response => {
            const data = response.data.doc;
            setKpiCompany(data);
            setScore(response.data.score);
            setTotalAch(response.data.totalAch);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setKpiCompany([]);
            setScore(0);
            setTotalAch(0);
        });
        setLoading(false);
    }



    /*
    * Handle Delete Data
    */
    const confirmDeleteKpiCompany = async (id, serialNumber) => {
        //Sending request delete
        await ApiConfig.delete(`/kpi-company/destroy/${id}`).then(response => {
            message.success(`${serialNumber} Is Deleted`);
            console.log(response);
            fetchKpiCompany(year);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Row className="justify-content-center">
            <Col xs sm lg={11}>
                <Card className="card-body shadow-sm">
                    <AuthVerify />

                    {/*Handle Create Team*/}
                    <Modal
                        title="Input Data KPI"
                        open={modal.modalEntryKpi}
                        onCancel={() => modal.setModalEntryKpi(false)}
                        width={700}
                        style={{
                            top: 57,
                        }}
                        footer={[
                            /*Disable Button */
                        ]}
                    >
                        <hr/>
                        <KpiCompanyCreate
                            fecthData={fetchKpiCompany}
                            currentYear={year}
                        />

                    </Modal>

                    <MaterialReactTable
                        state={{ isLoading: loading }}
                        columns={columns}
                        data={kpiCompany}
                        enablePinning
                        enableGlobalFilter={true}
                        enableDensityToggle={false}
                        enableStickyHeader={true}
                        initialState={{ density: 'compact', columnPinning: { right: ['score', 'ach', 'mrt-row-actions'] }}}
                        enableColumnResizing={true}
                        columnResizeMode="onChange"
                        enableRowNumbers={true}
                        enableRowActions={true}
                        positionActionsColumn="last"
                        //Double click for edit
                        muiTableBodyCellProps={({ row }) => ({
                            onDoubleClick: () => {
                                navigate(`/system/kpi-company/detail/${row.original.id}`);
                            },
                        })}
                        //Row Actions
                        renderRowActions={({ row}) => (
                            <Popconfirm
                                placement="left"
                                title="Delete"
                                description="Are you sure to delete this?"
                                onConfirm={() => confirmDeleteKpiCompany(row.original.id, row.original.serialNumber)}
                                onCancel={() => message.error(`Delete ${row.original.serialNumber} Canceled`)}
                                okText="Yes"
                                cancelText="No"
                            > <Button size="small"> Delete </Button>
                            </Popconfirm>
                        )}
                        //Display Head Table
                        muiTableHeadCellProps={{
                            sx: {
                                fontWeight: 'bold',
                                fontSize: '14px',
                                color: '#1976d2',
                            },
                        }}
                        //Height Table
                        muiTableContainerProps={{
                            sx: {
                                minHeight: '400px',
                                maxHeight: '500px'
                            }
                        }}
                        //Custom Width Column
                        displayColumnDefOptions={{
                            'mrt-row-numbers': {
                                size: 10,
                            },
                            'mrt-row-actions': {
                                // header: 'Action', //change header text
                                // size: 50, //make actions column wider
                            },
                        }}
                        //add custom action buttons to top-left of top toolbar
                        renderTopToolbarCustomActions={({ table }) => (
                            <Form layout="horizontal" form={formPeriod} requiredMark={false}>
                                <Form.Item label="Tahun" name="tahun">
                                    <DatePicker onChange={handleYear} defaultValue={dayjs(year)} picker="year" />
                                </Form.Item>
                            </Form>
                        )}
                    />
                </Card>
            </Col>

            <Col xs sm lg={1}>
                <Card className="card-body shadow-sm p-0 text-center">
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">Detail</ListGroup.Item>

                        <ListGroup.Item className="item-instruction text-center">
                            Score <br/>
                            <Progress type="circle" className="mt-1" percent={score} size={55} format={(percent) => `${percent} %`} />
                        </ListGroup.Item>

                        <ListGroup.Item className="item-instruction">
                            Achiev <br/>
                            <Progress type="circle" className="mt-1"  percent={totalAch} size={55} format={(percent) => `${percent} %`} />
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
    );
}