/* Import Component React */
import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card, Col, Row} from "react-bootstrap";
import {Popconfirm, message, Button, Descriptions, Form, Space, Skeleton} from "antd";

/*Import Child Component*/
import CompanyUpdate from "./CompanyUpdate";

/* Return the module */
function CompanyDetail() {
    //Prepare variable
    const [company, setCompany] = useState([]);
    const [loadingView, setLoadingView] = useState(false);
    const role = localStorage.getItem('role');
    let {id} = useParams();
    //Handle Modal Entry
    const [modalUpdateCompany, setModalUpdateCompany] = useState(false);



    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchCompany().finally();
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch detail data company
    */
    async function fetchCompany() {
        setLoadingView(true);
        await ApiConfig.get(`/company/${id}`).then(response => {
            const data = response.data.doc;
            setCompany(data);
            handleCloseModalUpdateCompany();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setCompany([]);
        });
        setLoadingView(false);
    }



    /*
    * Handle close modal update company
    */
    const handleCloseModalUpdateCompany = () => {
        setModalUpdateCompany(false);
    }

    return (
        <>
            <Skeleton active loading={loadingView}>
                <AuthVerify />
                {/*Handle RouteCompany Update*/}
                <CompanyUpdate
                    fetchData={fetchCompany}
                    companyData={company}
                    modalUpdate={modalUpdateCompany}
                    modalClose={handleCloseModalUpdateCompany}
                />

                <Row>
                    <Col md lg={3}>
                        <Card className="mt-3">
                            <Card.Img variant="top" src={company.url_logo} className="p-2"/>
                            <Card.Body>
                                <Card.Title>{company.company_name}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md lg={9}>
                        <Card className="card-body">
                            <Descriptions title="Company Info" className="mt-2">
                                <Descriptions.Item label="Company Name">{company.company_name}</Descriptions.Item>
                                <Descriptions.Item label="PIC Name">{company.pic_name}</Descriptions.Item>
                                <Descriptions.Item label="Email">{company.email}</Descriptions.Item>
                                <Descriptions.Item label="Expired At">{company.expired_at}</Descriptions.Item> <br/>
                                <Descriptions.Item label="Address">
                                    {company.address}
                                </Descriptions.Item>
                            </Descriptions>
                            <hr className="my-3" />
                            <Form.Item>
                                {
                                    role === 'Owners' &&
                                    <Space>
                                        <Popconfirm
                                            title="Update"
                                            description="Are you sure to update this?"
                                            onConfirm={() => setModalUpdateCompany(true)}
                                            onCancel={() => message.error(`${company.company_name} Update Canceled`)}
                                            okText="Yes"
                                            cancelText="No"
                                        > <Button type="primary"> Update </Button>
                                        </Popconfirm>
                                    </Space>
                                }
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Skeleton>
        </>
    );
}

export default CompanyDetail;