/* Import Component React */
import {useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import PositionForm from "./PositionForm";

function PositionUpdate(props) {
    //Prepare variable
    const [formUpdatePosition] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const position = props.positionData;
    const [disableBtnDivision, setDisableBtnDivision] = useState(true);
    const [disableBtnDepartment, setDisableBtnDepartment] = useState(true);


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fillField();
        /*eslint-disable-next-line*/
    }, [position]);



    /*
    * Handle Fill Field Form
    */
    const fillField = () => {
        formUpdatePosition.setFieldsValue({
            directorateId: { value: position.directorateId, label: position.directorateName},
            divisionId: position.divisionId && { value: position.divisionId, label: position.divisionName },
            departmentId: position.departmentId && { value: position.departmentId, label: position.departmentName},
            title: position.title,
            grade: position.grade,
        });
        setDisableBtnDivision(true);
        setDisableBtnDepartment(true);
    }



    /*
    * Handle Update Data
    */
    const updateDataPosition = async (values) => {
        setLoadingBtn(true);
        //Prepare value options
        let directorateId, divisionId, departmentId;
        console.log(values);
        directorateId = values.directorateId.value ? values.directorateId.value : values.directorateId;
        divisionId = values.divisionId === null || values.divisionId === undefined ? null : values.divisionId.value ? values.divisionId.value : values.divisionId;
        departmentId = values.departmentId === null || values.departmentId === undefined ? null : values.departmentId.value ? values.departmentId.value : values.departmentId;


        const formData = {
            companyId: localStorage.getItem('companyId'),
            directorateId: directorateId,
            divisionId: divisionId,
            departmentId: departmentId,
            title: values.title,
            grade: values.grade,
        };

        //Send data to backend for update
        await ApiConfig.patch(`/position/update/${position.id}`, formData).then(() => {
            message.success(`Position ${values.title} Updated`);
            props.fecthData();
            formUpdatePosition.resetFields();
            props.modalClose(false);
            setDisableBtnDivision(true);
            setDisableBtnDepartment(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={updateDataPosition} form={formUpdatePosition} requiredMark={false} className="mt-3">
                <PositionForm
                    loading={loadingBtn}
                    formReference={formUpdatePosition}
                    disableBtnDivision={{disableBtnDivision, setDisableBtnDivision}}
                    disableBtnDepartment={{disableBtnDepartment, setDisableBtnDepartment}}
                    button={"Update"}
                />
            </Form>
        </>
    )
}

export default PositionUpdate;