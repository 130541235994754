/*Import Component React*/
import React, {useEffect, useRef} from "react";

/* Import Style */
import {Button, Flex, Form, InputNumber, Switch} from "antd";
import TextArea from "antd/es/input/TextArea";
import {Col, Row} from "react-bootstrap";

export default function KriteriaForm(props) {
    const focusRef = useRef(null);

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        if (props.button === "Update") {
            props.setField();
        } else {
            props.formReference.resetFields();
        }
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);

    return (
        <>
            <Form.Item label="Kriteria" name="kriteria" rules={[
                {required: true, message: "Please input kriteria"},
                {min: 10, message: "Kriteria must have min 10 character"}
            ]}>
                <TextArea showCount maxLength={500} ref={focusRef}/>
            </Form.Item>

            <Row>
                <Form.Item label="Score" name="score" rules={[
                    {required: true, message: "Please input Score"},
                ]}>
                    <InputNumber
                        style={{ width: "100%" }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')} disabled={props.achKpi > 0}
                    />
                </Form.Item>
            </Row>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}