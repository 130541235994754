/*Import Component React*/
import {useEffect, useRef} from "react";

/* Import Style */
import {Button, Flex, Form, Input} from "antd";
import TextArea from "antd/es/input/TextArea";

function DireksiForm(props) {
    const focusRef = useRef(null);

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        props.formReference.resetFields();
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);

    return (
        <>
            <Form.Item label="Direksi" name="directorate_name" rules={[
                { required: true, message: "Please input direksi" },
                { min: 5, message: "Direksi must have min 5 character" }
            ]}>
                <Input showCount maxLength={100} ref={focusRef}/>
            </Form.Item>

            <Form.Item label="Deskripsi" name="description" rules={[
                {required: true, message: "Please input deskripsi"},
                {min: 1, message: "Deskripsi must have min 1 character"}
            ]}>
                <TextArea showCount maxLength={300} />
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}

export default DireksiForm;