/* Import Component React */
import {useEffect, useMemo, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button} from "antd";

/*Import Child Component*/
import DepartmentCreate from "./DepartmentCreate";
import DepartmentUpdate from "./DepartmentUpdate";

/* Return the module */
function DepartmentView(props) {
    //Prepare variable
    const [department, setDepartment] = useState([]);
    const [detailDepartment, setDetailDepartment] = useState([]);
    const [loading, setLoading] = useState(false);
    const companyId = localStorage.getItem('companyId');
    //Handle Modal Entry & Update
    const [modalUpdateDepartment, setModalUpdateDepartment] = useState(false);
    const modal = props.modal;
    const columns = useMemo(
        () => [
            {
                accessorKey: "division.divisionName",
                header: "Divisi",
            },
            {
                accessorKey: "departmentName",
                header: "Departemen",
            },
            {
                accessorKey: "description",
                header: "Deskripsi",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchDepartment().finally();
        document.title = 'Departemen';
    }, []);



    /*
    * Handle fetch data department
    */
    async function fetchDepartment() {
        setLoading(true);
        await ApiConfig.get(`/department/${companyId}`).then(response => {
            const data = response.data.doc;
            setDepartment(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setDepartment([]);
        });
        setLoading(false);
    }



    /*
    * Handle fetch data detail department
    */
    const fetchDetailDepartment =  async (id) => {
        await ApiConfig.get(`/department/find/${id}`).then(response => {
            const data = response.data.doc;
            console.log(data)
            setDetailDepartment(data);
            setModalUpdateDepartment(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setDetailDepartment([]);
        });
    }



    /*
    * Handle close modal update department
    */
    const handleCloseModalUpdateDepartment = () => {
        setModalUpdateDepartment(false);
    }



    /*
    * Handle Delete Data
    */
    const confirmDeleteDepartment = async (id, departmentName) => {
        //Sending request delete
        await ApiConfig.delete(`/department/destroy/${id}`).then(response => {
            message.success(`Divisi ${departmentName} Is Deleted`);
            console.log(response);
            fetchDepartment();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            {/*Handle Department Create*/}
            <DepartmentCreate
                fecthData={fetchDepartment}
                modalEnrty={modal.modalEntryDepartment}
                modalClose={modal.handleCloseModalEntryDepartment}
            />

            {/*Handle Department Update*/}
            <DepartmentUpdate
                fecthData={fetchDepartment}
                departmentData={detailDepartment}
                modalUpdate={modalUpdateDepartment}
                modalClose={handleCloseModalUpdateDepartment}
            />

            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={department}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact'}}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        fetchDetailDepartment(row.original.id).finally();
                    },
                })}
                //Row Actions
                renderRowActions={({ row}) => (
                    <Popconfirm
                        placement="left"
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeleteDepartment(row.original.id, row.original.departmentName)}
                        onCancel={() => message.error(`Delete ${row.original.departmentName} Canceled`)}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        // header: 'Action', //change header text
                        size: 10, //make actions column wider
                    },
                    'mrt-row-numbers': {
                        size: 10,
                    }
                }}
            />
        </Card>
    );
}

export default DepartmentView;