import {useState} from "react";
import {Routes, Route, Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ApiConfig from "../config/ApiConfig"
import AuthVerify from "../feature/Authentication/AuthVerify";

/*Import style*/
import {Container, Nav, Navbar, Offcanvas, ThemeProvider} from "react-bootstrap";
import {LogOut} from "react-feather";

/*Import component*/
import Menu from "./Menu";
import Dashboard from "../feature/Dashboard/Dashboard";
import DireksiNav from "../feature/direksi/DireksiNav";
import CompanyNav from "../feature/company/CompanyNav";
import DivisiNav from "../feature/division/DivisiNav";
import DepartmentNav from "../feature/department/DepartmentNav";
import PositionNav from "../feature/position/PositionNav";
import UserNav from "../feature/User/UserNav";
import TeamsNav from "../feature/Teams/TeamsNav";
import KpiCompanyNav from "../feature/kpi-company/KpiCompanyNav";
import SkNav from "../feature/sasaran-kinerja/SkNav";
import SkTeamNav from "../feature/sasaran-kinerja-team/SkTeamNav";
import UomNav from "../feature/uom/UomNav";
import Kriteria360Nav from "../feature/kritetia-360/Kriteria360Nav";
import Assessment360Nav from "../feature/assessment360/Assessment360Nav";
import Setting from "../feature/settings/Setting";
import OverviewNav from "../feature/overview/OverviewNav";


function Layout() {
    //Defined variable
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('name');
    const companyName = localStorage.getItem('companyName');
    const urlLogo = localStorage.getItem('urlLogo');

    /* Handle navbar off canvas */
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Logout Handler
    /* Log out */
    const logout = async () => {
        await ApiConfig.get(`/auth/logout/${token}`).then(response => {
            console.log(response);
            removeStorage();
            navigate('/');
        }).catch( error => {
            console.log(error);
            removeStorage();
            navigate('/');
        });
    }

    const removeStorage = () => {
        //Token
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('companyId');
        localStorage.removeItem('companyName');
        localStorage.removeItem('urlLogo');
        localStorage.removeItem('directorateId');
        localStorage.removeItem('divisionId');
        localStorage.removeItem('departmentId');
        localStorage.removeItem('positionId');
        localStorage.removeItem('grade');
        localStorage.removeItem('role');
        localStorage.removeItem('nik');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('superior');
    }

    return (
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
        >
            <div className="App">
                <AuthVerify />
                <div className="container-fluid">
                    <div className="row">
                        <Offcanvas show={show} onHide={handleClose} responsive="md lg" className="col-md-3 col-lg-2 p-0" id="sidebar">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    <h5>{companyName}</h5>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Menu
                                logo={urlLogo}
                                company={companyName}
                            />
                        </Offcanvas>

                        <main className="col-md-9 ms-sm-auto col-lg-10">
                            <header className="sticky-top-header">
                                <Navbar collapseOnSelect expand="lg" className="main-navbar shadow-sm">
                                    <Container fluid>
                                        <Navbar.Brand href="/">
                                            <Nav.Link as={Link} to="#" style={{ textDecoration: "none", fontSize: "13pt"}}> {user} </Nav.Link>
                                        </Navbar.Brand>
                                        <Navbar.Text>
                                            <Nav.Link as={Link} to="#" style={{ textDecoration: "none"}} onClick={logout}> <LogOut className="feather"/>  </Nav.Link>
                                        </Navbar.Text>
                                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="d-lg-none d-md-none" onClick={handleShow}/>
                                    </Container>
                                </Navbar>
                            </header>

                            <Routes>
                                <Route path="/*" element={<Dashboard />} />
                                <Route path="company/*" element={<CompanyNav />} />
                                <Route path="direksi/*" element={<DireksiNav />} />
                                <Route path="divisi/*" element={<DivisiNav />} />
                                <Route path="department/*" element={<DepartmentNav />} />
                                <Route path="position/*" element={<PositionNav />} />
                                <Route path="user/*" element={<UserNav />} />
                                <Route path="team/*" element={<TeamsNav />} />
                                <Route path="kpi-company/*" element={<KpiCompanyNav />} />
                                <Route path="sasaran-kinerja/*" element={<SkNav />} />
                                <Route path="sk-team/*" element={<SkTeamNav />} />
                                <Route path="uom/*" element={<UomNav />} />
                                <Route path="kriteria-360/*" element={<Kriteria360Nav />} />
                                <Route path="penilaian-360/*" element={<Assessment360Nav />} />
                                <Route path="setting/*" element={<Setting />} />
                                <Route path="overview/*" element={<OverviewNav />} />
                            </Routes>
                        </main>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}

export default Layout;
