/* Import Component React*/
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import React, {useState} from "react";

/*Import Style*/
import {ChevronLeft, Edit3, Plus} from "react-feather";
import {Button, Space} from "antd"

/* Import component module */
import KpiCompanyView from "./KpiCompanyView";
import KpiCompanyDetail from "./KpiCompanyDetail";

/* Return the module*/
export default function KpiCompanyNav() {
    //Handle Modal Entry
    const [modalEntryKpi, setModalEntryKpi] = useState(false);
    const [modalUpdateKpi, setModalUpdateKpi] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4 ms-2" style={{ fontWeight: "bolder"}}>KPI Perusahaan</h4>
                {
                    location.pathname === '/system/kpi-company' ?
                        <Button type="primary" className="me-2" onClick={() => setModalEntryKpi(true)}>
                            <Plus className="feather-button"/>
                            Entry
                        </Button>
                        :
                        <Space>
                            <Button className="me-2" onClick={() => navigate(`/system/kpi-company`)} >
                                <ChevronLeft className="feather-button"/>
                                Back
                            </Button>
                            <Button type="primary" className="me-2" onClick={() => setModalUpdateKpi(true)} >
                                <Edit3 className="feather-button"/>
                                Update
                            </Button>
                        </Space>



                }
            </div>
            <Routes>
                <Route path="/" element={<KpiCompanyView modal={{modalEntryKpi, setModalEntryKpi}}/>} />
                <Route path="detail/:id" element={<KpiCompanyDetail modal={{modalUpdateKpi, setModalUpdateKpi}}/>} />
            </Routes>
        </>
    );
}