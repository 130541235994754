/*Import Component React*/
import {useCallback, useEffect, useRef, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Button, Flex, Form, Input, message, Select} from "antd";

function PositionForm(props) {
    const companyId = localStorage.getItem('companyId');
    const focusRef = useRef(null);
    const [listDirectorate, setListDirectorate] = useState([]);
    const [loadingListDirectorate, setLoadingListDirectorate] = useState(false);
    const [listDivision, setListDivision] = useState([]);
    const [loadingListDivision, setLoadingListDivision] = useState(false);
    const [listDepartment, setListDepartment] = useState([]);
    const [loadingListDepartment, setLoadingListDepartment] = useState(false);
    const disableBtnDivision = props.disableBtnDivision;
    const disableBtnDepartment = props.disableBtnDepartment;
    const listGrade = [
        {
            'value': "Direktur",
            'label': "Direktur",
        },
        {
            'value': "Manajer",
            'label': "Manajer",
        },
        {
            'value': "Asisten Manajer",
            'label': "Asisten Manajer",
        },
        {
            'value': "Staff",
            'label': "Staff",
        },
        {
            'value': "Non Staff",
            'label': "Non Staff",
        },
    ];

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        props.formReference.resetFields();
        disableBtnDivision.setDisableBtnDivision(true);
        disableBtnDepartment.setDisableBtnDepartment(true);
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);



    /*
    * Handle list directorate trigger form select.
    */
    const handleListDirectorate = useCallback( () => {
        fetchListDirectorate().finally();
        /* eslint-disable */
    }, [listDirectorate]);

    /*
    * Handle fetch data directorate
    */
    async function fetchListDirectorate() {
        //Await
        setLoadingListDirectorate(true);
        await ApiConfig.get(`/directorate/select/${companyId}`).then(response => {
            setListDirectorate(response.data.doc);
        }).catch((error) => {
            console.log(error);
            message.error(error.response.data.message);
            setListDirectorate([]);
        });
        setLoadingListDirectorate(false);
    }



    /*
    * Handle list division by selected directorate
    */
    const handleListDivision = useCallback((values) => {
        fetchListDivision(values).finally();
        disableBtnDivision.setDisableBtnDivision(false);
        /* eslint-disable */
    }, [listDivision])

    /*
    * Handle fetch data division
    */
    async function fetchListDivision(values) {
        //Await
        setLoadingListDivision(true);
        await ApiConfig.get(`/division/reference/${companyId}/${values}`).then(response => {
            setListDivision(response.data.doc);
            //Reset reference if list division found
            props.formReference.setFieldsValue({
                divisionId: null,
                departmentId: null,
            });
        }).catch((error) => {
            console.log(error);
            // message.error(error.response.data.message);
            setListDivision([]);
            disableBtnDivision.setDisableBtnDivision(true);
            disableBtnDepartment.setDisableBtnDepartment(true);
            //Reset reference if list division not found
            props.formReference.setFieldsValue({
                divisionId: null,
                departmentId: null,
            });
        });
        setLoadingListDivision(false);
    }



    /*
    * Handle list department by selected division
    */
    const handleListDepartment = useCallback((values) => {
        fetchListDepartment(values).finally();
        disableBtnDepartment.setDisableBtnDepartment(false);
        /* eslint-disable */
    }, [listDepartment])

    /*
    * Handle fetch data department
    */
    async function fetchListDepartment(values) {
        //Await
        setLoadingListDepartment(true);
        await ApiConfig.get(`/department/reference/${companyId}/${values}`).then(response => {
            setListDepartment(response.data.doc);
        }).catch((error) => {
            console.log(error);
            // message.error(error.response.data.message);
            setListDepartment([]);
            disableBtnDepartment.setDisableBtnDepartment(true);
            props.formReference.setFieldsValue({
                departmentId: null,
            })
        });
        setLoadingListDepartment(false);
    }

    return (
        <>
            <Form.Item label="Direksi" name="directorateId" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } onClick={handleListDirectorate} onSelect={handleListDivision} onChange={handleListDivision} loading={loadingListDirectorate} options={listDirectorate} ref={focusRef}
                />
            </Form.Item>

            <Form.Item label="Divisi" name="divisionId">
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } loading={loadingListDivision} onSelect={handleListDepartment} onChange={handleListDepartment} options={listDivision}  disabled={disableBtnDivision.disableBtnDivision}
                />
            </Form.Item>

            <Form.Item label="Departemen" name="departmentId">
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } loading={loadingListDepartment} options={listDepartment} disabled={disableBtnDepartment.disableBtnDepartment}/>
            </Form.Item>

            <Form.Item label="Position" name="title" rules={[
                { required: true, message: "Please input position" },
                { min: 3, message: "Position must have min 3 character" }
            ]}>
                <Input showCount maxLength={100}/>
            </Form.Item>

            <Form.Item label="Grade" name="grade" rules={[ {required: true, message: "Please select a grade"} ]}>
                <Select placeholder="Select a grade" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } options={listGrade}/>
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}

export default PositionForm;