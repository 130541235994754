/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import RealizationKpiForm from "./RealizationKpiForm";

export default  function RealizationKpiCreate(props) {
    //Prepare variable
    const companyId = localStorage.getItem('companyId');
    const userId = localStorage.getItem('userId');
    const [formEntryRealization] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);


    /*
    * Defined File On Form
    */
    const files = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    /*
    * Handle Store Data
    */
    const storeRealization = async (values) => {
        setLoadingBtn(true);
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('evidence', file);
        });

        //Append data
        formData.append('companyId', companyId);
        formData.append('referenceId', props.reference);
        formData.append('achievement', values.achievement);
        formData.append('createdBy', userId);

        //Send data to backend for store
        await ApiConfig.post('/realization/store-realization-kpi', formData).then(() => {
            setFileList([]);
            message.success(`Realisasi Saved`);
            props.fetchData();
            props.modal(false);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={storeRealization} form={formEntryRealization} requiredMark={false} className="mt-3">
                <RealizationKpiForm
                    files={files}
                    loading={loadingBtn}
                    formReference={formEntryRealization}
                    button={"Submit"}
                    kpiDetail={props.kpiDetail}
                />
            </Form>
        </>
    )
}