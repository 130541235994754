/* Import Module */
import React from "react";

/* Import Style */
import {Row, Col} from "react-bootstrap"

/* Import Child Component */
import DashKpiCompany from "./DashKpiCompany";
import DashSKPersonal from "./DashSKPersonal";
import DashSKTeam from "./DashSKTeam";

export default function Dashboard() {

    return (
        <>
            <DashKpiCompany />
            <Row>
                <Col>
                    <DashSKPersonal />
                </Col>
                {
                    localStorage.getItem('superior') === 'true' &&
                    <Col>
                        <DashSKTeam />
                    </Col>
                }
            </Row>
        </>
    );
}