/* Import Component React */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";
import numberFormat from "../NumberFormat";
import fileDownload from "js-file-download";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button, Modal, DatePicker, Form, Progress, Space, Segmented, Statistic} from "antd";
import {LikeOutlined} from "@ant-design/icons";
import {ExternalLink} from "react-feather";

/* Import Time Component */
import dayjs from "dayjs";

/*Import Child Component*/
import AssessmentCreate from "./AssessmentCreate";


/* Return the module */
export default function Assessment360View(props) {
    //Variable State
    const companyId = localStorage.getItem('companyId');
    const role = localStorage.getItem('role');
    const [formPeriod] = Form.useForm();
    const userId = localStorage.getItem('userId');
    const yearFormat = 'YYYY';
    const currentDate = new Date();
    const [year, setYear] = useState(dayjs(currentDate).format(yearFormat));
    const [personalAssessment, setPersonalAssessment] = useState([]);
    const [subject, setSubject] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSubject, setLoadingSubject] = useState(false);
    const [kriteria, setKriteria] = useState([]);
    const [subjectId, setSubjectId] = useState("");
    const [subjectName, setSubjectName] = useState("");
    const [availableYear, setAvailableYear] = useState(dayjs(currentDate).format(yearFormat));
    const [lock360, setLock360] = useState(false);
    const [automaticPeriod, setAutomaticPeriod] = useState(false);

    //State Modal
    const [modalPenilaian, setModalPenilaian] = useState(false);

    //State Columns
    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Nama",
            },
            {
                accessorKey: "division",
                header: "Division",
            },
            {
                accessorKey: "department",
                header: "Departemen",
            },
            {
                accessorKey: "assessment",
                header: "Nilai",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        document.title = 'Penilaian 360';
        fetchSettingPeriod().finally();
        fetchSettingRealization360().finally();
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch data setting period
    */
    async function fetchSettingPeriod() {
        setLoading(true);
        await ApiConfig.get(`/env/get-setting-period`).then(response => {
            const data = response.data;
            if (data.automaticPeriod === "false") {
                fetchPersonalAssessment(data.currentYear).finally();
                fetchSubject(data.currentYear).finally();
                setYear(data.currentYear);
                formPeriod.setFieldsValue({
                    tahun: dayjs(data.currentYear),
                });
                setAvailableYear(data.currentYear);
                setAutomaticPeriod(false);
            } else {
                fetchPersonalAssessment(year).finally();
                fetchSubject(year).finally();
                formPeriod.setFieldsValue({
                    tahun: dayjs(year),
                });
                setYear(year);
                setAvailableYear(year);
                setAutomaticPeriod(true);
            }
            /*eslint-disable-next-line*/
        }).catch(error => {
            console.log(error);
        });
        setLoading(false);
    }


    /*
    * Handle fetch data setting realization 360
    */
    async function fetchSettingRealization360() {
        setLoading(true);
        await ApiConfig.get(`/env/get-lock-realization-360`).then(response => {
            const data = response.data;
            setLock360(data.lock !== "false");
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setLock360(false);
        });
        setLoading(false);
    }



    /*
    * Handle year user trigger form select year.
    */
    const handleYear = useCallback( (value) => {
        const selectedYear = dayjs(value).format(yearFormat);
        fetchPersonalAssessment(selectedYear).finally();
        fetchSubject(selectedYear).finally();
        setYear(selectedYear);
        /* eslint-disable */
    }, [year]);



    /*
    * Handle fetch data personal assessment
    */
    async function fetchPersonalAssessment(year) {
        setLoading(true);
        await ApiConfig.get(`/assessment-360/personal`, {
            params: { companyId: companyId, year: year, userId: userId }
        }).then(response => {
            const data = response.data.doc;
            setPersonalAssessment(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setPersonalAssessment([]);
        });
        setLoading(false);
    }

    /*
    * Handle fetch data subject assessment
    */
    async function fetchSubject(year) {
        setLoadingSubject(true);
        await ApiConfig.get(`/assessment-360/subject`, {
            params: { companyId: companyId, year: year, userId: userId }
        }).then(response => {
            const data = response.data.doc;
            setSubject(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setSubject([]);
        });
        setLoadingSubject(false);
    }


    /*
    * Handle fetch data kriteria
    */
    async function fetchKriteria(id, name) {
        setLoading(true);
        await ApiConfig.get(`/assessment-360/kriteria`, {
            params: { companyId: companyId }
        }).then(response => {
            const data = response.data.doc;
            setSubjectId(id);
            setSubjectName(name);
            setKriteria(data);
            setModalPenilaian(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setSubjectId("");
            setSubjectName("");
            setKriteria([]);
            setModalPenilaian(false);
        });
        setLoading(false);
    }



    /*
    * Handle Reset Assessment
    */
    const confirmReset = async (year, subjectId) => {
        //Sending request delete
        await ApiConfig.delete(`/assessment-360/reset`, {
            params: { year: year, subjectId: subjectId, respondentId: userId }
        }).then(() => {
            message.success(`Reset Successful`);
            fetchSubject(year);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }


    /*
    * Handle Double Click For Assessment
    */
    const handleAssessment = useCallback( (id, name, assessment) => {
        if (lock360 === true ) {
            message.info("Penilaian 360 ini Telah di Kunci");
        } else if ( automaticPeriod === true && year !== availableYear ) {
            message.info("Penilaian Pada Periode ini Tidak Dapat Dilakukan");
        } else if (assessment === 0 ) {
            fetchKriteria(id, name).finally();
        } else {
            message.info("Karyawan ini Sudah di Nilai");
        }
        console.log(year);
        console.log(availableYear);
    }, [availableYear, lock360, automaticPeriod, year]);

    /*
    * Handle Report Data
    */
    const report = useCallback( async () => {
        await ApiConfig.get('/assessment-360/report', {
            params: { year: year, companyId: companyId },
            responseType: 'blob',
        }).then( response => {
            fileDownload(response.data, `Penilaian-360-${year}.xlsx`);
            message.success(`Penilaian-360-${year}.xlsx Exported`);
        }).catch( error => {
            console.log(error);
            message.error(error);
        });
        /* eslint-disable */
    }, [year]);



    return (
        <>
            <Card className="card-body shadow-sm">
                <Row gutter={16}>
                    <Col span={12}>
                        <Statistic title="Nama" value={personalAssessment.name} valueStyle={{ fontSize: "12pt", fontWeight: "bold"}}/>
                    </Col>
                    <Col span={12}>
                        <Statistic title="Divisi" value={personalAssessment.division === null ? "-" : personalAssessment.division} valueStyle={{ fontSize: "11pt", fontWeight: "bold"}}/>
                    </Col>
                    <Col span={12}>
                        <Statistic title="Department" value={personalAssessment.department === null ? "-" : personalAssessment.department} valueStyle={{ fontSize: "11pt", fontWeight: "bold"}}/>
                    </Col>
                    <Col span={12}>
                        <Statistic title="Responden" value={personalAssessment.respondent} prefix={<LikeOutlined />} valueStyle={{ fontSize: "12pt", fontWeight: "bold"}}/>
                    </Col>
                    <Col span={12}>
                        <Statistic title="Nilai" value={Math.round(numberFormat(personalAssessment.avgPoint))} suffix="/ 100" valueStyle={{ fontSize: "12pt", fontWeight: "bold"}}/>
                    </Col>
                    <Col span={12}>
                        <Statistic title="Total Point" value={personalAssessment.sumPoint} valueStyle={{ fontSize: "12pt", fontWeight: "bold"}}/>
                    </Col>
                </Row>
            </Card>
            <Card className="card-body shadow-sm mt-3">
                <AuthVerify />

                {/*Handle Kriteria Create*/}
                <Modal
                    title={<>Penilaian : {subjectName}</>}
                    open={modalPenilaian}
                    onCancel={() => setModalPenilaian(false)}
                    width={1100}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button */
                    ]}
                >
                    <hr/>
                    <AssessmentCreate
                        kriteria={kriteria}
                        subjectId={subjectId}
                        year={year}
                        availYear={availableYear}
                        fetchData={fetchSubject}
                        modalClose={setModalPenilaian}
                    />

                </Modal>

                <MaterialReactTable
                    state={{ isLoading: loadingSubject }}
                    columns={columns}
                    data={subject}
                    enablePinning
                    enableGlobalFilter={true}
                    enableDensityToggle={false}
                    enableStickyHeader={true}
                    initialState={{ density: 'compact'}}
                    enableColumnResizing={true}
                    columnResizeMode="onChange"
                    enableRowNumbers={true}
                    enableRowActions={true}
                    positionActionsColumn="last"
                    //Double click for edit
                    muiTableBodyCellProps={({ row }) => ({
                        onDoubleClick: () => { handleAssessment(row.original.id, row.original.name, row.original.assessment)},
                    })}
                    //Row Actions
                    renderRowActions={({ row}) => (
                        <Popconfirm
                            placement="left"
                            title="Reset"
                            description="Are you sure to reset this?"
                            onConfirm={() => confirmReset(row.original.year, row.original.id)}
                            onCancel={() => message.error(`Reset Canceled`)}
                            okText="Yes"
                            cancelText="No"
                        > <Button size="small" disabled={row.original.assessment === 0}> Reset </Button>
                        </Popconfirm>
                    )}
                    //Display Head Table
                    muiTableHeadCellProps={{
                        sx: {
                            fontWeight: 'bold',
                            fontSize: '14px',
                            color: '#1976d2',
                        },
                    }}
                    //Height Table
                    muiTableContainerProps={{
                        sx: {
                            minHeight: '400px',
                            maxHeight: '500px'
                        }
                    }}
                    //Custom Width Column
                    displayColumnDefOptions={{
                        'mrt-row-numbers': {
                            size: 10,
                        },
                        'mrt-row-actions': {
                            // header: 'Action', //change header text
                            // size: 50, //make actions column wider
                        },
                    }}
                    //add custom action buttons to top-left of top toolbar
                    renderTopToolbarCustomActions={({ table }) => (
                        <Form layout="horizontal" form={formPeriod} requiredMark={false}>
                            <Space>
                                <Form.Item label="Tahun" name="tahun">
                                    <DatePicker onChange={handleYear} defaultValue={dayjs(currentDate)} picker="year" />
                                </Form.Item>
                                <Form.Item>
                                    {
                                        role !== "User" &&
                                        <Button onClick={report} >
                                            <ExternalLink className="feather-button"/>
                                            Report
                                        </Button>
                                    }
                                </Form.Item>
                            </Space>
                        </Form>
                    )}
                />
            </Card>
        </>
    );
}