/* Import Component React */
import {useCallback, useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import dayjs from "dayjs";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import SkForm from "./SkForm";

export default function SkUpdate(props) {
    //Prepare
    const grade = localStorage.getItem('grade');
    const [formUpdateSk] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const skDetail = props.skDetail;
    const [linkTarget, setLinktarget] = useState(skDetail.linkTarget);



    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fillField();
        /*eslint-disable-next-line*/
    }, [skDetail]);



    /*
    * Handle Link Target.
    */
    const handleLinkTarget = useCallback( (value) => {
        setLinktarget(value);
        if (skDetail.linkTarget === false) {
            formUpdateSk.setFieldsValue({
                referenceId: null,
            });
        }
    }, [linkTarget]);



    /*
    * Handle Fill Field Form
    */
    const fillField = () => {
        const referenceId = skDetail.referenceId ? { value: skDetail.referenceId, label: skDetail.referenceName } : null;
        setLinktarget(skDetail.linkTarget);
        formUpdateSk.setFieldsValue({
            year: dayjs(skDetail.year),
            group: skDetail.group,
            type: skDetail.type,
            referenceId : referenceId,
            performanceGoal: skDetail.performanceGoal,
            description: skDetail.description,
            uom: skDetail.uom,
            target: skDetail.target,
            score: skDetail.score,
        });
    }



    /*
    * Handle Update Data
    */
    const updateDataSk = async (values) => {
        setLoadingBtn(true);
        //Prepare Value Options
        const referenceId = values.referenceId == null || false ? null : values.referenceId.value ? values.referenceId.value : values.referenceId;
        const formData = {
            companyId: localStorage.getItem('companyId'),
            year: dayjs(values.year).format('YYYY'),
            group: values.group,
            type: values.type,
            referenceId: referenceId,
            performanceGoal: values.performanceGoal,
            description: values.description,
            uom: values.uom,
            target: values.target,
            roleTarget: values.roleTarget,
            score: values.score,
            linkTarget: linkTarget,
            createdBy: skDetail.createdBy,
        };

        //Send data to backend for store
        if (grade === "Manajer") {
            await ApiConfig.patch(`/sk-manager/update/${skDetail.id}`, formData).then(() => {
                message.success(`Sasaran Kinerja Updated`);
                props.fetchData();
                formUpdateSk.resetFields();
                props.modalClose(false);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        } else if (grade === "Asisten Manajer") {
            await ApiConfig.patch(`/sk-assistant-manager/update/${skDetail.id}`, formData).then(() => {
                message.success(`Sasaran Kinerja Updated`);
                props.fetchData();
                formUpdateSk.resetFields();
                props.modalClose(false);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        } else {
            await ApiConfig.patch(`/sk-staff/update/${skDetail.id}`, formData).then(() => {
                message.success(`Sasaran Kinerja Updated`);
                props.fetchData();
                formUpdateSk.resetFields();
                props.modalClose(false);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        }
        setLoadingBtn(false)
    }

    return (
        <>
            <Form layout="vertical" onFinish={updateDataSk} form={formUpdateSk} requiredMark={false} className="mt-3">
                <SkForm
                    loading={loadingBtn}
                    formReference={formUpdateSk}
                    quarter={skDetail.group}
                    linkTarget={{linkTarget, handleLinkTarget}}
                    type={skDetail.type}
                    button={"Update"}
                    setField={fillField}
                    status={skDetail.status}
                    targetStatus={linkTarget}
                    roleTarget={skDetail.roleTarget}
                />
            </Form>
        </>
    )
}