/* Import Component React */
import {useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, Modal, message} from "antd";

/*Import Child Component*/
import DivisiForm from "./DivisiForm";

function DivisiUpdate(props) {
    //Prepare variable
    const [formUpdateDivisi] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const divisi = props.divisiData;


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fillField();
        /*eslint-disable-next-line*/
    }, [divisi]);



    /*
    * Handle Fill Field Form
    */
    const fillField = () => {
        formUpdateDivisi.setFieldsValue({
            directorateId: { value: divisi.directorateId, label: divisi.directorateName },
            divisionName: divisi.divisionName,
            description: divisi.description,
        });
    }



    /*
    * Handle Update Data
    */
    const updateDataDivisi = async (values) => {
        setLoadingBtn(true);
        //Prepare value options
        let directorateId;
        directorateId = values.directorateId.value ? values.directorateId.value : values.directorateId;

        const formData = {
            companyId: localStorage.getItem('companyId'),
            directorateId: directorateId,
            divisionName: values.divisionName,
            description: values.description,
        };

        //Send data to backend for update
        await ApiConfig.patch(`/division/update/${divisi.id}`, formData).then(() => {
            message.success(`Divisi ${values.divisionName} Updated`);
            props.fecthData();
            formUpdateDivisi.resetFields();
            props.modalClose();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            {/*Handle Create Delivery*/}
            <Modal
                title="Update Data Divisi"
                open={props.modalUpdate}
                onCancel={props.modalClose}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <Form layout="vertical" onFinish={updateDataDivisi} form={formUpdateDivisi} requiredMark={false} className="mt-3">
                    <DivisiForm
                        loading={loadingBtn}
                        formReference={formUpdateDivisi}
                        logoRequired={true}
                        button={"Update"}
                    />
                </Form>
            </Modal>
        </>
    )
}

export default DivisiUpdate;