/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import TeamsForm from "./TeamsForm";

export default function TeamsCreate(props) {
    //Prepare variable
    const [formEntryTeams] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);

    /*
    * Handle Store Data
    */
    const storeDataTeams = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            userId: values.superiorId,
            subordinateId: values.subordinateId,
        };

        //Send data to backend for store
        await ApiConfig.post('/team/store', formData).then(() => {
            message.success(`Teams Saved`);
            props.fecthData();
            formEntryTeams.resetFields();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={storeDataTeams} form={formEntryTeams} requiredMark={false} className="mt-3">
                <TeamsForm
                    loading={loadingBtn}
                    formReference={formEntryTeams}
                    disable={false}
                    button={"Submit"}
                />
            </Form>
        </>
    )
}