/* Import Component React */
import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button, Modal} from "antd";

/*Import Child Component*/
import TeamsCreate from "./TeamsCreate";

/* Return the module */
export default function TeamsView(props) {
    //Prepare variable
    const companyId = localStorage.getItem('companyId');
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const modal = props.modal;
    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Nama",
            },
            {
                accessorKey: "position",
                header: "Jabatan",
            },
            {
                accessorKey: "teams",
                header: "Teams",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchTeams().finally();
        document.title = 'Teams';
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch data teams
    */
    async function fetchTeams() {
        setLoading(true);
        await ApiConfig.get(`/team/${companyId}`).then(response => {
            const data = response.data.doc;
            setTeams(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setTeams([]);
        });
        setLoading(false);
    }



    /*
    * Handle Delete Data
    */
    const confirmDeleteSuperior = async (id, name) => {
        //Sending request delete
        await ApiConfig.delete(`/team/destroy-superior/${id}`).then(response => {
            message.success(`${name} Is Deleted`);
            console.log(response);
            fetchTeams();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            {/*Handle Create Team*/}
            <Modal
                title="Input Data Teams"
                open={modal.modalEntryTeams}
                onCancel={() => modal.setModalEntryTeams(false)}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <TeamsCreate
                    fecthData={fetchTeams}
                />

            </Modal>

            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={teams}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact'}}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: () => {
                        navigate(`/system/team/detail/${row.original.id}`);
                    },
                })}
                //Row Actions
                renderRowActions={({ row}) => (
                    <Popconfirm
                        placement="left"
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeleteSuperior(row.original.id, row.original.name)}
                        onCancel={() => message.error(`Delete ${row.original.name} Canceled`)}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-numbers': {
                        size: 10,
                    },
                    'mrt-row-actions': {
                        // header: 'Action', //change header text
                        size: 50, //make actions column wider
                    },
                }}
            />
        </Card>
    );
}