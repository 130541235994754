/*Import Component React*/
import React from "react";
import numberFormat from "../NumberFormat";

/* Import Style */
import {Button, Flex, Form, Input, InputNumber, Upload} from "antd";
import {UploadCloud} from "react-feather";

export default function RealizationKpiForm(props) {

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        props.formReference.resetFields();
    }

    return (
        <>
            <Form.Item label="Upload Bukti" name="evidence" rules={[
                { required: true, message: "Please upload Bukti" }
            ]}>
                <Upload {...props.files}>
                    <Button icon={<UploadCloud className="feather-button"/>} style={{ width: "100%"}}>Select to Upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item label="GAP" name="target">
                <Input readonly={true} defaultValue={`${numberFormat(props.kpiDetail.target - props.kpiDetail.ach)} ${props.kpiDetail.uom}`} readOnly={true}/>
            </Form.Item>

            <Form.Item label="Realisasi Target" name="achievement" rules={[
                {required: true, message: "Please input Realisasi Target"},
            ]}>
                <InputNumber style={{ width: "100%" }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')} min={0}
                />
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}