/*Import Component React*/
import React, {useEffect} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Button, Flex, Form, Rate, List, message} from "antd";

export default function AssessmentCreate(props) {
    const [formEntryAssessment] = Form.useForm();
    const companyId = localStorage.getItem('companyId');
    const respondentId = localStorage.getItem('userId');
    const year = props.year;
    const availYear = props.availYear;
    const kriteria = props.kriteria;
    const subjectId = props.subjectId;


    /*
    * Store Assessment.
    */
    const storeAssessment = async (values) => {
        //Defined Form Data
        const formData = {
            assessment: values,
            companyId: companyId,
            year: availYear,
            subjectId: subjectId,
            respondentId: respondentId
        };
        console.log(formData);

        //Send data to backend for store
        await ApiConfig.post('/assessment-360/store', formData).then(() => {
            message.success(`Assessment Saved`);
            props.fetchData(year);
            props.modalClose(false);
            resetForm();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        formEntryAssessment.resetFields();
    }

    return (
        <Form layout="vertical" onFinish={storeAssessment} form={formEntryAssessment} requiredMark={false} className="mt-3">
            <List
                itemLayout="horizontal"
                dataSource={kriteria}
                renderItem={(item, index) => (
                    <List.Item className="p-0">
                        <List.Item.Meta
                            title={<p> {index+1}. {item.kriteria}</p>}
                        />
                        <div>
                            <Form.Item name={item.id}>
                                <Rate />
                            </Form.Item>
                        </div>
                    </List.Item>
                )}
            />

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </Form>
    )
}