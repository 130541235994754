/* Import Style */
import {Button, Flex, Form, Upload} from "antd";
import {UploadCloud} from "react-feather";

function UserPictFrom(props) {

    return (
        <>
            <Form.Item label="Upload Profile" name="profilePict" rules={[
                { required: true, message: "Please upload profile" }
            ]}>
                <Upload {...props.files}>
                    <Button icon={<UploadCloud className="feather-button"/>} style={{ width: "100%"}}>Select to Upload</Button>
                </Upload>
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
            </Flex>
        </>
    )
}

export default UserPictFrom;