/* Import Component React */
import React, {useMemo, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Button, Flex, Form, Input, message, Segmented} from "antd";
import TextArea from "antd/es/input/TextArea";
import {MaterialReactTable} from "material-react-table";
import {Col, Row} from "react-bootstrap";

export default  function ReviewTeam(props) {
    //Prepare Variable
    const userId = localStorage.getItem('userId');
    const realizationData = props.realizationData;
    const oneDetail = props.oneDetail;
    const [loadingBtn, setLoadingBtn] = useState(false);
    const columns = useMemo(
        () => [
            {
                accessorKey: "date",
                header: "Tanggal",
            },
            {
                accessorKey: "name",
                header: "Nama",
            },
            {
                accessorKey: "uom",
                header: "UoM",
            },
            {
                accessorKey: "achievement",
                header: "Realisasi",
            },
            {
                accessorKey: "result",
                header: "Hasil",
            },
            {
                accessorKey: "review",
                header: "Review",
            },
            // eslint-disable-next-line
        ], []
    );


    const resetForm = () => {
        props.formReference.resetFields();
    }


    /*
    * Handle Review SK
    */
    const reviewSk = async (values) => {
        setLoadingBtn(true);
        const formData = {
            result: values.result ? values.result : "Pencapaian Tidak Sesuai",
            review: values.review,
            reviewerId: userId,

        };
        await ApiConfig.patch(`/realization/review/${oneDetail.id}`, formData).then(() => {
            message.success(`Review Saved`);
            props.formReference.resetFields()
            props.fetchData();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false);
    }

    return (
        <Form layout="vertical" onFinish={reviewSk} form={props.formReference} requiredMark={false} className="mt-3">
            <MaterialReactTable
                state={{ isLoading: props.loading }}
                columns={columns}
                data={realizationData}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact' }}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: () => {
                        window.open(`${row.original.urlFile}`, "_blank")
                    },
                })}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '200px',
                        maxHeight: '200px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-numbers': {
                        size: 10,
                    },
                }}
            />

            <hr/>

            <Row>
                <Col>
                    <Form.Item label="Total Target" name="totalTarget">
                        <Input disabled={true} className="bg-light text-dark"/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item label="Total Realisasi" name="totalRealisasi">
                        <Input disabled={true} className="bg-light text-dark"/>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item label="Penilaian" name="result">
                <Segmented options={['Pencapaian Tidak Sesuai', 'Pencapaian Sesuai']}/>
            </Form.Item>

            <Form.Item label="Review" name="review" rules={[
                {required: true, message: "Please input Review"},
                {min: 10, message: "Review must have min 10 character"}
            ]}>
                <TextArea showCount maxLength={1000} />
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={loadingBtn}>
                    Submit
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </Form>
    )
}