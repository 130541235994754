/* Import Component React*/
import {Route, Routes} from "react-router-dom";
import {useState} from "react";

/*Import Style*/
import {Plus} from "react-feather";
import {Button} from "antd"

/* Import component module */
import UomView from "./UomView";

/* Return the module*/
export default function UomNav() {
    //Handle Modal Entry
    const [modalEntryUom, setModalEntryUom] = useState(false);


    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4 ms-2" style={{ fontWeight: "bolder"}}>UoM</h4>
                <Button type="primary" className="me-2" onClick={() => setModalEntryUom(true)}><Plus className="feather-button"/>Entry</Button>
            </div>
            <Routes>
                <Route path="/" element={<UomView modalState={modalEntryUom} modalSet={setModalEntryUom}/>} />
            </Routes>
        </>
    );
}