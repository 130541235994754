/* Import Component React */
import {useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, Modal, message} from "antd";

/*Import Child Component*/
import UomForm from "./UomForm";

export default function UomUpdate(props) {
    //Prepare variable
    const [formUpdateUom] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const detailUom = props.detailUom;


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        setFieldsUom();
        /*eslint-disable-next-line*/
    }, [detailUom]);



    /*
    * Set Field Uom.
    */
    const setFieldsUom = () => {
        formUpdateUom.setFieldsValue({
            value: detailUom.value,
        });
    }


    /*
    * Handle Update Data
    */
    const updateDataUom = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            value: values.value,
        };

        //Send data to backend for store
        await ApiConfig.patch(`/uom/update/${detailUom.id}`, formData).then(() => {
            message.success(`UoM ${values.value} Updated`);
            props.fetchData();
            formUpdateUom.resetFields();
            props.modalSet(false);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={updateDataUom} form={formUpdateUom} requiredMark={false} className="mt-3">
                <UomForm
                    loading={loadingBtn}
                    formReference={formUpdateUom}
                    setField={setFieldsUom}
                    button={"Update"}
                />
            </Form>
        </>
    )
}