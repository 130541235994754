/* Import Component React*/
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import React, {useState} from "react";

/*Import Style*/
import {ChevronLeft, Edit3, Plus} from "react-feather";
import {Button, Space} from "antd"

/* Import component module */
import Assessment360View from "./Assessment360View";

/* Return the module*/
export default function Assessment360Nav() {
    //Handle Modal Entry
    const location = useLocation();
    const navigate = useNavigate();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4 ms-2" style={{ fontWeight: "bolder"}}>Penilaian 360</h4>
                {
                    location.pathname !== '/system/penilaian-360' &&
                    <Button className="me-2" onClick={() => navigate(`/system/penilaian-360`)} >
                        <ChevronLeft className="feather-button"/>
                        Back
                    </Button>
                }
            </div>
            <Routes>
                <Route path="/" element={<Assessment360View />} />
                {/*<Route path="detail/:id" element={<KpiCompanyDetail modal={{modalUpdateKpi, setModalUpdateKpi}}/>} />*/}
            </Routes>
        </>
    );
}