/* Import Component React */
import {useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import dayjs from "dayjs";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import KriteriaForm from "./KriteriaForm";

export default function KriteriaUpdate(props) {
    //Prepare variable
    const [formUpdateKriteria] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const kriteriaDetail = props.detailData;



    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fillField();
        /*eslint-disable-next-line*/
    }, [kriteriaDetail]);



    /*
    * Handle Fill Field Form
    */
    const fillField = () => {
        formUpdateKriteria.setFieldsValue({
            kriteria: kriteriaDetail.kriteria,
            score: kriteriaDetail.score,
        });
    }



    /*
    * Handle Update Data
    */
    const updateKriteria = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            kriteria: values.kriteria,
            score: values.score,
            updatedBy: localStorage.getItem('userId'),
            active: values.active,
        };

        //Send data to backend for store
        await ApiConfig.patch(`/kriteria-360/update/${kriteriaDetail.id}`, formData).then(() => {
            message.success(`Kriteria Updated`);
            props.fetchData();
            formUpdateKriteria.resetFields();
            props.modalSet(false);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }

    return (
        <>
            <Form layout="vertical" onFinish={updateKriteria} form={formUpdateKriteria} requiredMark={false} className="mt-3">
                <KriteriaForm
                    loading={loadingBtn}
                    formReference={formUpdateKriteria}
                    button={"Update"}
                    setField={fillField}
                />
            </Form>
        </>
    )
}