/* Import Component React */
import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";
import numberFormat from "../NumberFormat";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import {message, Skeleton, Modal, Descriptions, Button, Progress, Popconfirm, Form,} from "antd";
import {MaterialReactTable} from "material-react-table";
import {Check, X} from "react-feather";

/*Import Child Component*/
import ReviewTeam from "./ReviewTeam";

/* Return the module */
export default  function SkTeamDetail() {
    //Prepare variable
    let {id} = useParams();
    let {year} = useParams();
    let {group} = useParams();
    let {grade} = useParams();
    const companyId = localStorage.getItem('companyId');
    const [skDetail, setSkDetail] = useState([]);
    const [oneDetail, setOneDetail] = useState([]);
    const [userDetail, setUserDetail] = useState([]);
    const [score, setScore] = useState(0);
    const [scoreKpi, setScoreKpi] = useState(0);
    const [scorePi, setScorePi] = useState(0);
    const [totalAch, setTotalAch] = useState(0);
    const [loadingView, setLoadingView] = useState(false);
    const [loadingRealization, setLoadingRealization] = useState(false);
    const [modalReview, setModalReview] = useState(false);
    const [realization, setRealization] = useState([]);
    const [status, setStatus] = useState("");
    const [formEntryReview] = Form.useForm();
    const columns = useMemo(
        () => [
            // {
            //     accessorKey: "serialNumber",
            //     header: "ID",
            //     minSize: 100,
            //     maxSize: 100,
            // },
            {
                accessorKey: "group",
                header: "Group",
                minSize: 110,
                maxSize: 110,
            },
            {
                accessorKey: "type",
                header: "Tipe",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "performanceGoal",
                header: "Sasaran Kinerja",
                minSize: 500,
                maxSize: 500,
            },
            {
                accessorKey: "uom",
                header: "UoM",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "target",
                header: "Target",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "actual",
                header: "Actual",
                minSize: 110,
                maxSize: 110,
            },
            {
                accessorKey: "gap",
                header: "GAP",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "score",
                header: "Score",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "status",
                header: "Status",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "ach",
                header: "Ach%",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "sesuai",
                header: "Sesuai",
                minSize: 110,
                maxSize: 110,
            },
            {
                accessorKey: "notSesuai",
                header: "Tidak Sesuai",
                minSize: 150,
                maxSize: 150,
            },
            {
                accessorKey: "submission",
                header: "Belum Di Nilai",
                minSize: 170,
                maxSize: 170,
            },
            // eslint-disable-next-line
        ], []
    );



    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchSkDetail().finally();
        fetchUser().finally();
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle Fetch Data Team.
    */
    async function fetchUser() {
        await ApiConfig.get(`/user/find/${id}`).then(response => {
            const data = response.data.doc;
            setUserDetail(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setUserDetail([]);
        });
    }



    /*
    * Handle fetch detail data SK
    */
    async function fetchSkDetail() {
        setLoadingView(true);
        if (grade === "Manajer") {
            await ApiConfig.get(`/sk-manager/all/${companyId}/${year}/${group}/${id}`).then(response => {
                const data = response.data.doc;
                setSkDetail(data);
                setStatus(data[0].status);
                setScore(response.data.totalScore);
                setScoreKpi(response.data.totalScoreKpi);
                setScorePi(response.data.totalScorePi);
                setTotalAch(response.data.totalAch);
                setModalReview(false);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkDetail([]);
                setStatus("");
                setScore(0);
                setScoreKpi(0);
                setScorePi(0);
                setTotalAch(0);
            });
        } else if (grade === "Asisten Manajer") {
            await ApiConfig.get(`/sk-assistant-manager/all/${companyId}/${year}/${group}/${id}`).then(response => {
                const data = response.data.doc;
                setSkDetail(data);
                setStatus(data[0].status);
                setScore(response.data.totalScore);
                setScoreKpi(response.data.totalScoreKpi);
                setScorePi(response.data.totalScorePi);
                setTotalAch(response.data.totalAch);
                setModalReview(false);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkDetail([]);
                setStatus("");
                setScore(0);
                setScoreKpi(0);
                setScorePi(0);
                setTotalAch(0);
            });
        } else {
            await ApiConfig.get(`/sk-staff/all/${companyId}/${year}/${group}/${id}`).then(response => {
                const data = response.data.doc;
                setSkDetail(data);
                setStatus(data[0].status);
                setScore(response.data.totalScore);
                setScoreKpi(response.data.totalScoreKpi);
                setScorePi(response.data.totalScorePi);
                setTotalAch(response.data.totalAch);
                setModalReview(false);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkDetail([]);
                setStatus("");
                setScore(0);
                setScoreKpi(0);
                setScorePi(0);
                setTotalAch(0);
            });
        }
        setLoadingView(false);
    }


    /*
    * Handle fetch one detail SK
    */
    async function fetchOneDetailSk(id) {
        setLoadingRealization(true);
        if (grade === "Manajer") {
            await ApiConfig.get(`/sk-manager/find/${id}`).then(response => {
                const data = response.data.doc;
                setOneDetail(data);
                setRealization(data.realization);
                formEntryReview.setFieldsValue({
                    totalTarget: `${numberFormat(data.target)} - ${data.uom}`,
                    totalRealisasi: `${numberFormat(data.ach)}`,
                });
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setOneDetail([]);
                setRealization([]);
                formEntryReview.resetFields();
            });
        } else if (grade === "Asisten Manajer") {
            await ApiConfig.get(`/sk-assistant-manager/find/${id}`).then(response => {
                const data = response.data.doc;
                setOneDetail(data);
                setRealization(data.realization);
                formEntryReview.setFieldsValue({
                    totalTarget: `${numberFormat(data.target)} - ${data.uom}`,
                    totalRealisasi: `${numberFormat(data.ach)}`,
                });
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkDetail([]);
                setRealization([]);
                formEntryReview.resetFields();
            });
        } else {
            await ApiConfig.get(`/sk-staff/find/${id}`).then(response => {
                const data = response.data.doc;
                setOneDetail(data);
                setRealization(data.realization);
                formEntryReview.setFieldsValue({
                    totalTarget: `${numberFormat(data.target)} - ${data.uom}`,
                    totalRealisasi: `${numberFormat(data.ach)}`,
                });
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setOneDetail([]);
                setRealization([]);
                formEntryReview.resetFields();
            });
        }
        setLoadingRealization(false);
    }


    /*
    * Handle Open Review.
    */
    const openReview = (id) => {
        fetchOneDetailSk(id).finally();
        setModalReview(true);
    }

    /*
    * Handle Approval SK
    */
    const approvalSk = async () => {
        if (grade === "Manajer") {
            await ApiConfig.patch(`/sk-manager/approval/${companyId}/${year}/${group}/${id}`).then(() => {
                message.success(`Approval Success`);
                fetchSkDetail();
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        } else if (grade === "Asisten Manajer") {
            await ApiConfig.patch(`/sk-assistant-manager/approval/${companyId}/${year}/${group}/${id}`).then(() => {
                message.success(`Approval Success`);
                fetchSkDetail();
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        } else {
            await ApiConfig.patch(`/sk-staff/approval/${companyId}/${year}/${group}/${id}`).then(() => {
                message.success(`Approval Success`);
                fetchSkDetail();
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        }
    }

    const borderedItems = [
        {
            key: '1',
            label: 'Nik',
            children: userDetail.nik,
        },
        {
            key: '2',
            label: 'Nama',
            span: 2,
            children: userDetail.name,
        },
        {
            key: '3',
            label: 'Direksi',
            children: userDetail.directorateName,
        },
        {
            key: '4',
            label: 'Divisi',
            children: userDetail.divisionName,
        },
        {
            key: '5',
            label: 'Departemen',
            children: userDetail.departmentName,
        },
    ];


    return (
        <>
            <Skeleton active loading={loadingView}>
                <AuthVerify />
                {/*Description User*/}
                <Descriptions
                    bordered
                    size="small"
                    items={borderedItems}
                    className="mt-3"
                />

                {/*Handle Review*/}
                <Modal
                    title="Review Sasaran Kinerja"
                    open={modalReview}
                    onCancel={() => setModalReview(false)}
                    width={700}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button*/
                    ]}
                >
                    <hr/>
                    <ReviewTeam
                        formReference={formEntryReview}
                        oneDetail={oneDetail}
                        realizationData={realization}
                        grade={grade}
                        loading={loadingRealization}
                        fetchData={fetchSkDetail}
                    />

                </Modal>

                {/*Table SK*/}
                <Card className="card-body shadow-sm">
                    <Row className="justify-content-center">
                        <Col xs sm lg={11}>
                            <MaterialReactTable
                                state={{ isLoading: loadingView }}
                                columns={columns}
                                data={skDetail}
                                enablePinning
                                enableGlobalFilter={true}
                                enableDensityToggle={false}
                                enableStickyHeader={true}
                                initialState={{ density: 'compact', columnPinning: { right: ['score', 'ach', 'mrt-row-actions'] }}}
                                enableColumnResizing={true}
                                columnResizeMode="onChange"
                                enableRowNumbers={true}
                                //Double click for edit
                                muiTableBodyCellProps={({ row }) => ({
                                    onDoubleClick: () => {
                                        openReview(row.original.id)
                                    },
                                })}
                                //Display Head Table
                                muiTableHeadCellProps={{
                                    sx: {
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        color: '#1976d2',
                                    },
                                }}
                                //Height Table
                                muiTableContainerProps={{
                                    sx: {
                                        minHeight: '400px',
                                        maxHeight: '500px'
                                    }
                                }}
                                //Custom Width Column
                                displayColumnDefOptions={{
                                    'mrt-row-numbers': {
                                        size: 10,
                                    },
                                }}
                                //add custom action buttons to top-left of top toolbar
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Popconfirm
                                        placement="left"
                                        title="Konfirmasi"
                                        description="Konfirmasi Persetujuan"
                                        onConfirm={approvalSk}
                                        onCancel={() => message.error(`Konfirmasi Dibatalkan`)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="primary" danger={status !== "Submission"} htmlType="button" disabled={totalAch > 0}>
                                            { status === "Submission" ?
                                                <>
                                                    <Check className="feather-button"/>
                                                    Setujui SK
                                                </>
                                                :
                                                <>
                                                    <X className="feather-button"/>
                                                    Batalkan Persetujuan SK
                                                </>

                                            }
                                        </Button>
                                    </Popconfirm>
                                )}
                            />
                        </Col>

                        <Col xs sm lg={1}>
                            <Card className="card-body shadow-sm p-0 text-center">
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="header-form">Detail</ListGroup.Item>

                                    <ListGroup.Item className="item-instruction text-center">
                                        Score KPI <br/>
                                        <Progress type="circle" className="mt-1" percent={scoreKpi} size={55} format={(percent) => `${percent} %`} />
                                    </ListGroup.Item>

                                    <ListGroup.Item className="item-instruction text-center">
                                        Score PI <br/>
                                        <Progress type="circle" className="mt-1" percent={scorePi} size={55} format={(percent) => `${percent} %`} />
                                    </ListGroup.Item>

                                    <ListGroup.Item className="item-instruction text-center">
                                        Score <br/>
                                        <Progress type="circle" className="mt-1" percent={score} size={55} format={(percent) => `${percent} %`} />
                                    </ListGroup.Item>

                                    <ListGroup.Item className="item-instruction">
                                        Achiev <br/>
                                        <Progress type="circle" className="mt-1"  percent={totalAch} size={55} format={(percent) => `${percent} %`} />
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>

                </Card>
            </Skeleton>
        </>
    );
}