/*Import Component React*/
import {useEffect, useRef} from "react";

/* Import Style */
import {Button, DatePicker, Flex, Form, Input, Upload} from "antd";
import TextArea from "antd/es/input/TextArea";
import {UploadCloud} from "react-feather";

/* Import Time Component */
import 'dayjs/locale/en-in';
import locale from 'antd/es/date-picker/locale/id_ID';

function CompanyForm(props) {
    const focusRef = useRef(null);

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        props.formReference.resetFields();
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);

    return (
        <>
            <Form.Item label="Perusahaan" name="company_name" rules={[
                { required: true, message: "Please input perusahaan" },
                { min: 2, message: "Perusahaan must have min 2 character" }
            ]}>
                <Input showCount maxLength={100} ref={focusRef}/>
            </Form.Item>

            <Form.Item label="Email" name="email" rules={[
                { required: true, message: "Please input email" },
                { type: 'email', message: "Is not a valid email"},
                { min: 10, message: "Email must have min 10 character" }
            ]}>
                <Input showCount maxLength={100} />
            </Form.Item>

            <Form.Item label="Nama PIC" name="pic_name" rules={[
                { required: true, message: "Please input PIC Name" },
                { min: 3, message: "Pic Name must have min 3 character" }
            ]}>
                <Input showCount maxLength={100} />
            </Form.Item>

            <Form.Item label="Alamat" name="address" rules={[
                {required: true, message: "Please input alamat"},
                {min: 10, message: "Alamat must have min 10 character"}
            ]}>
                <TextArea showCount maxLength={300} />
            </Form.Item>

            <Form.Item label="Expired At" name="expired_at" rules={[
                { required: true, message: "Please input expired date" }
            ]}>
                <DatePicker locale={locale} style={{ width: "100%"}}/>
            </Form.Item>

            <Form.Item label="Upload Logo" name="company_logo" rules={[
                { required: props.logoRequired, message: "Please upload logo" }
            ]}>
                <Upload {...props.files}>
                    <Button icon={<UploadCloud className="feather-button"/>} style={{ width: "100%"}}>Select to Upload</Button>
                </Upload>
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}

export default CompanyForm;