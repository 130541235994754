/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import UserPictForm from "./UserPictForm";

function UserPictUpdate(props) {
    //Prepare variable
    const [formUpdatePict] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const user = props.userData;


    /*
    * Defined File On Form
    */
    const files = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    /*
    * Handle Update Data
    */
    const updateDataPictUser = async () => {
        setLoadingBtn(true);
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('profilePict', file);
        });

        //Send data to backend for update
        await ApiConfig.patch(`/user/update-profile-pict/${user.id}`, formData).then(() => {
            message.success(`Profile Pict Updated`);
            props.fetchData()
            formUpdatePict.resetFields();
            props.modalClose(false);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={updateDataPictUser} form={formUpdatePict} requiredMark={false} className="mt-3">
                <UserPictForm
                    files={files}
                    loading={loadingBtn}
                    formReference={formUpdatePict}
                    button={"Update"}
                />
            </Form>
        </>
    )
}

export default UserPictUpdate;