/*Import Component React*/
import {useCallback, useEffect, useRef, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Button, Flex, Form, message, Select} from "antd";

export default function TeamsForm(props) {

    const companyId = localStorage.getItem('companyId');
    const focusRef = useRef(null);
    const [listUser, setListUser] = useState([]);
    const [loadingListUser, setLoadingListUser] = useState(false);

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        props.formReference.resetFields();
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);



    /*
    * Handle list user trigger form select.
    */
    const handleListUser = useCallback( () => {
        fetchListUser().finally();
        /* eslint-disable */
    }, [listUser]);

    /*
    * Handle fetch data user
    */
    async function fetchListUser() {
        //Await
        setLoadingListUser(true);
        props.disable === false ? //If Button Superior not disable get all user
        await ApiConfig.get(`/user/select/${companyId}`).then(response => {
            setListUser(response.data.doc);
        }).catch((error) => {
            console.log(error);
            message.error(error.response.data.message);
            setListUser([]);
        })
        : // else get user ready
        await ApiConfig.get(`/user/select-ready-user/${companyId}`).then(response => {
            setListUser(response.data.doc);
        }).catch((error) => {
            console.log(error);
            message.error(error.response.data.message);
            setListUser([]);
        })
        setLoadingListUser(false);
    }

    return (
        <>
            <Form.Item label="Atasan" name="superiorId" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } onClick={handleListUser} onFocus={handleListUser} loading={loadingListUser} options={listUser} ref={focusRef} disabled={props.disable}/>
            </Form.Item>

            <Form.Item label="Bawahan" name="subordinateId" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select mode="multiple" style={{ width: '100%' }} placeholder="Please select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } onClick={handleListUser} options={listUser} loading={loadingListUser}
                />
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}