/* Import Component React */
import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button} from "antd";

/*Import Child Component*/
import CompanyCreate from "./CompanyCreate";

/* Return the module */
function CompanyView(props) {
    //Prepare variable
    const [company, setCompany] = useState([]);
    const [loading, setLoading] = useState(false);
    const role = localStorage.getItem('role');
    const companyId = localStorage.getItem('companyId');
    const modal = props.modal;
    const navigate = useNavigate();
    const columns = useMemo(
        () => [
            {
                accessorKey: "company_name",
                header: "Perusahaan",
            },
            {
                accessorKey: "address",
                header: "Alamat",
            },
            {
                accessorKey: "email",
                header: "Email",
            },
            {
                accessorKey: "pic_name",
                header: "PIC",
            },
            {
                accessorKey: "expired_at",
                header: "Expired",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchCompany().finally();
        document.title = 'Perusahaan';

        if (role !== 'Owners') {
            navigate(`/system/company/detail/${companyId}`);
        }

        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch data company
    */
    async function fetchCompany() {
        setLoading(true);
        await ApiConfig.get('/company').then(response => {
            const data = response.data.doc;
            setCompany(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setCompany([]);
        });
        setLoading(false);
    }



    /*
    * Handle Delete Data
    */
    const confirmDeleteCompany = async (id, company_name) => {
        //Sending request delete
        await ApiConfig.delete(`/company/destroy/${id}`).then(response => {
            message.success(`Company ${company_name} Is Deleted`);
            console.log(response);
            fetchCompany();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            {/*Handle Company Create*/}
            <CompanyCreate
                fetchData={fetchCompany}
                modalEntry={modal.modalEntryCompany}
                modalClose={modal.handleCloseModalEntryCompany}
            />

            {/*Handle Table*/}
            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={company}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact'}}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        navigate(`/system/company/detail/${row.original.id}`);
                    },
                })}
                renderRowActions={({ row}) => (
                    <Popconfirm
                        placement="left"
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeleteCompany(row.original.id, row.original.company_name)}
                        onCancel={() => message.error(`Delete ${row.original.company_name} Canceled`)}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
            />
        </Card>
    );
}

export default CompanyView;