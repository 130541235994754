/* Import Component React */
import {useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import UserProfileForm from "./UserProfileForm";

function UserProfileUpdate(props) {
    //Prepare variable
    const [formUpdateUserProfile] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const user = props.userData;
    const [disableBtnDivision, setDisableBtnDivision] = useState(true);
    const [disableBtnDepartment, setDisableBtnDepartment] = useState(true);
    const [disableBtnPosition, setDisableBtnPosition] = useState(true);


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fillField();
        /*eslint-disable-next-line*/
    }, [user]);



    /*
    * Handle Fill Field Form
    */
    const fillField = () => {
        formUpdateUserProfile.setFieldsValue({
            directorateId: { value: user.directorateId, label: user.directorateName},
            divisionId: user.divisionId && { value: user.divisionId, label: user.divisionName },
            departmentId: user.departmentId && { value: user.departmentId, label: user.departmentName},
            positionId: { value: user.positionId, label: user.positionName},
            role: user.role,
            nik: user.nik,
            name: user.name,
            email: user.email,
        });
        setDisableBtnDivision(true);
        setDisableBtnDepartment(true);
        setDisableBtnPosition(true);
    }



    /*
    * Handle Update Data
    */
    const updateDataProfileUser = async (values) => {
        setLoadingBtn(true);
        //Prepare value options
        let directorateId, divisionId, departmentId, positionId;
        directorateId = values.directorateId.value ? values.directorateId.value : values.directorateId;
        divisionId = values.divisionId === null || values.divisionId === undefined ? null : values.divisionId.value ? values.divisionId.value : values.divisionId;
        departmentId = values.departmentId === null || values.departmentId === undefined ? null : values.departmentId.value ? values.departmentId.value : values.departmentId;
        positionId = values.positionId.value ? values.positionId.value : values.positionId;

        const formData = {
            companyId: localStorage.getItem('companyId'),
            directorateId: directorateId,
            divisionId: divisionId,
            departmentId: departmentId,
            positionId: positionId,
            role: values.role,
            nik: values.nik,
            name: values.name,
            email: values.email,
        };

        //Send data to backend for update
        await ApiConfig.patch(`/user/update-profile/${user.id}`, formData).then(() => {
            message.success(`User ${values.name} Updated`);
            props.fetchData();
            formUpdateUserProfile.resetFields();
            props.modalClose(false);
            setDisableBtnDivision(true);
            setDisableBtnDepartment(true);
            setDisableBtnPosition(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={updateDataProfileUser} form={formUpdateUserProfile} requiredMark={false} className="mt-3">
                <UserProfileForm
                    loading={loadingBtn}
                    formReference={formUpdateUserProfile}
                    disableBtnDivision={{disableBtnDivision, setDisableBtnDivision}}
                    disableBtnDepartment={{disableBtnDepartment, setDisableBtnDepartment}}
                    disableBtnPosition={{disableBtnPosition, setDisableBtnPosition}}
                    button={"Update"}
                />
            </Form>
        </>
    )
}

export default UserProfileUpdate;