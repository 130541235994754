/*Import React Component*/
import {useLocation, Link} from "react-router-dom";

/* Import style */
import {Nav, Navbar, NavDropdown, Offcanvas} from "react-bootstrap";
import {
    BarChart2,
    Book,
    HardDrive,
    Monitor,
    Users,
    User,
    Activity,
    UserCheck,
    Settings, BookOpen
} from "react-feather";

function Menu(props) {
    const level = localStorage.getItem('role');
    //Location path
    const location = useLocation();

    const handleMenu = () => {
        switch(level) {
            case 'Owners':
                return [
                    // <KpiManagement/>,
                    <PerformanceManagement/>,
                    <DataManagement/>,
                    <AdministrationManagement/>,
                    <Setting />
                ];
            case 'Super Admin':
                return [
                    // <KpiManagement/>,
                    <PerformanceManagement/>,
                    <DataManagement/>,
                    <AdministrationManagement/>,
                    <Setting />
                ];
            case 'Admin':
                return [
                    // <KpiManagement/>,
                    <PerformanceManagement/>,
                    <DataManagement/>,
                    <AdministrationManagement/>,
                    <Setting />

                ];
            case 'User':
                return [
                    <PerformanceManagement/>,
                    <ProfileManagement/>
                ];
            default:
                return [
                    <DataManagement/>,
                    <AdministrationManagement/>,
                ];
        }
    }

    return  (
        <Offcanvas.Body className="d-md-flex flex-column p-0 pt-lg-2 pt-md-2">
            <Navbar.Brand href="/system" className="text-center mt-1 mb-1">
                <img
                    alt=""
                    src= {props.logo}
                    width="35"
                    height="30"
                    className="d-inline-block align-top"
                />
                {' '}
                <span className="h5 text-light ms-2">E-Performance</span>
            </Navbar.Brand>
            <hr className="my-3" />
            <Nav activeKey={location.pathname} className="flex-column">
                <Nav.Link as={Link} to="/system" eventKey="/system"> <Monitor className="feather"/> Dashboard</Nav.Link>
                {handleMenu()}
            </Nav>
        </Offcanvas.Body>
    );
}

export default Menu;

function DataManagement() {
    const navData = (<><HardDrive className="feather"/> Data </>);
    //Location path
    const location = useLocation();
    const pathRoles = [
        "/system/direksi",
        "/system/divisi",
        "/system/department",
        "/system/grade",
        "/system/position",
        "/system/kriteria-360",
        "/system/role",
        "/system/uom"
    ];

    return (
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-secondary">
                <span>Management</span>
            </h6>
            <NavDropdown title={navData} id="collasible-nav-dropdown" drop="down-centered"
                active={ pathRoles.includes(location.pathname) }
            >
                <NavDropdown.Item as={Link} to="/system/direksi" eventKey="/system/direksi">Direksi</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/system/divisi" eventKey="/system/divisi">Divisi</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/system/department" eventKey="/system/department">Departemen</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/system/position" eventKey="/system/position">Position</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/system/uom" eventKey="/system/uom">UoM</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/system/kriteria-360" eventKey="/system/kriteria-360">Kriteria 360</NavDropdown.Item>
            </NavDropdown>
        </>
    )
}

function AdministrationManagement() {
    const navData = (<><Book className="feather"/> Administrasi </>);
    //Location path
    const location = useLocation();
    const pathRoles = [
        "/system/company",
        "/system/team",
        "/system/user"
    ]

    return (
        <>
            <NavDropdown title={navData} id="collasible-nav-dropdown" drop="down-centered"
                active={ pathRoles.includes(location.pathname) }
            >
                <NavDropdown.Item as={Link} to="/system/company" eventKey="/system/company">Company</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/system/team" eventKey="/system/team">Struktur Team</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/system/user" eventKey="/system/user">User</NavDropdown.Item>
            </NavDropdown>
        </>
    )
}

// function KpiManagement() {
//     return (
//         <>
//             <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-secondary">
//                 <span>Performance Index</span>
//             </h6>
//             <Nav.Link as={Link} to="/system/kpi-company" eventKey="/system/kpi-company"> <BarChart2 className="feather"/> Perusahaan</Nav.Link>
//             <Nav.Link as={Link} to="/system/kpi-divisi" eventKey="/system/kpi-divisi"> <BarChart2 className="feather"/> Divisi</Nav.Link>
//         </>
//     )
// }

function PerformanceManagement() {
    return (
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-secondary">
                <span>Performance</span>
            </h6>
            {
                localStorage.getItem('grade') === 'Direktur' &&
                <Nav.Link as={Link} to="/system/kpi-company" eventKey="/system/kpi-company"> <BarChart2 className="feather"/> KPI Perusahaan</Nav.Link>
            }
            {
                localStorage.getItem('role') === 'Super Admin' &&
                <Nav.Link as={Link} to="/system/kpi-company" eventKey="/system/kpi-company"> <BarChart2 className="feather"/> KPI Perusahaan</Nav.Link>
            }
            {
                localStorage.getItem('role') === 'Admin' &&
                <Nav.Link as={Link} to="/system/kpi-company" eventKey="/system/kpi-company"> <BarChart2 className="feather"/> KPI Perusahaan</Nav.Link>
            }
            {
                localStorage.getItem('grade') !== 'Direktur' &&
                <Nav.Link as={Link} to="/system/sasaran-kinerja" eventKey="/system/sasaran-kinerja"> <Activity className="feather"/> Sasaran Kinerja</Nav.Link>
            }
            {
                localStorage.getItem('superior') === 'true' &&
                <Nav.Link as={Link} to="/system/sk-team" eventKey="/system/sk-team"> <UserCheck className="feather"/> Sasaran Kinerja Team</Nav.Link>
            }
            <Nav.Link as={Link} to="/system/penilaian-360" eventKey="/system/penilaian-360"> <Users className="feather"/> Penilaian 360</Nav.Link>
            {
                localStorage.getItem('role') === 'Super Admin' &&
                <Nav.Link as={Link} to="/system/overview" eventKey="/system/overview"> <BookOpen className="feather"/> Overview</Nav.Link>
            }
            {
                localStorage.getItem('role') === 'Admin' &&
                <Nav.Link as={Link} to="/system/overview" eventKey="/system/overview"> <BookOpen className="feather"/> Overview</Nav.Link>
            }
        </>
    )
}

function ProfileManagement() {
    return (
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-secondary">
                <span>Profile</span>
            </h6>
            <Nav.Link as={Link} to="/system/user" eventKey="/system/user"> <User className="feather"/> User</Nav.Link>
        </>
    )
}

function Setting() {
    return (
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-secondary">
                <span>Setting</span>
            </h6>
            <Nav.Link as={Link} to="/system/setting" eventKey="/system/setting"> <Settings className="feather"/> Setting</Nav.Link>
        </>
    )
}