/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import UserForm from "./UserForm";


function UserCreate(props) {
    //Prepare variable
    const [formEntryUser] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [disableBtnDivision, setDisableBtnDivision] = useState(true);
    const [disableBtnDepartment, setDisableBtnDepartment] = useState(true);
    const [disableBtnPosition, setDisableBtnPosition] = useState(true);


    /*
    * Defined File On Form
    */
    const files = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    /*
    * Handle Store Data
    */
    const storeDataUser = async (values) => {
        setLoadingBtn(true);
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('profilePict', file);
        });

        //Append data
        formData.append('companyId', localStorage.getItem('companyId'));
        formData.append('directorateId', values.directorateId);
        values.divisionId && formData.append('divisionId', values.divisionId);
        values.departmentId && formData.append('departmentId', values.departmentId);
        formData.append('positionId', values.positionId);
        formData.append('role', values.role);
        formData.append('nik', values.nik);
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('password', values.password);

        //Send data to backend for store
        await ApiConfig.post('/user/store', formData).then(() => {
            setFileList([]);
            message.success(`User ${values.name} Saved`);
            props.fecthData();
            formEntryUser.resetFields();
            setDisableBtnDivision(true);
            setDisableBtnDepartment(true);
            setDisableBtnPosition(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={storeDataUser} form={formEntryUser} requiredMark={false} className="mt-3">
                <UserForm
                    files={files}
                    loading={loadingBtn}
                    formReference={formEntryUser}
                    disableBtnDivision={{disableBtnDivision, setDisableBtnDivision}}
                    disableBtnDepartment={{disableBtnDepartment, setDisableBtnDepartment}}
                    disableBtnPosition={{disableBtnPosition, setDisableBtnPosition}}
                    button={"Submit"}
                />
            </Form>
        </>
    )
}

export default UserCreate;