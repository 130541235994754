/*Import Component React*/
import {useCallback, useEffect, useRef, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Button, Flex, Form, Input, message, Select} from "antd";
import TextArea from "antd/es/input/TextArea";

function DepartmentForm(props) {
    const focusRef = useRef(null);
    const [listDivision, setListDivision] = useState([]);
    const [loadingListDivision, setLoadingListDivision] = useState(false);
    const companyId = localStorage.getItem('companyId');

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        props.formReference.resetFields();
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);



    /*
    * Handle list division trigger form select.
    */
    const handleListDivision = useCallback( () => {
        fetchListDivision().finally();
        /* eslint-disable */
    }, [listDivision]);

    /*
    * Handle fetch data division*/
    async function fetchListDivision() {
        //Await
        setLoadingListDivision(true);
        await ApiConfig.get(`/division/select/${companyId}`).then(response => {
            setListDivision(response.data.doc);
        }).catch((error) => {
            console.log(error);
            message.error(error.response.data.message);
            setListDivision([]);
        });
        setLoadingListDivision(false);
    }

    return (
        <>
            <Form.Item label="Divisi" name="divisionId" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } onClick={handleListDivision} onFocus={handleListDivision} loading={loadingListDivision} options={listDivision} ref={focusRef}/>
            </Form.Item>

            <Form.Item label="Departemen" name="departmentName" rules={[
                { required: true, message: "Please input departemen" },
                { min: 3, message: "Departemen must have min 3 character" }
            ]}>
                <Input showCount maxLength={100}/>
            </Form.Item>

            <Form.Item label="Deskripsi" name="description" rules={[
                {required: true, message: "Please input deskripsi"},
                {min: 1, message: "Deskripsi must have min 1 character"}
            ]}>
                <TextArea showCount maxLength={300} />
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}

export default DepartmentForm;