/* Import Component React */
import {useEffect, useMemo, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button, Modal} from "antd";

/*Import Child Component*/
import PositionCreate from "./PositionCreate";
import PositionUpdate from "./PositionUpdate";

/* Return the module */
function PositionView(props) {
    //Prepare variable
    const [position, setPosition] = useState([]);
    const [detailPosition, setDetailPosition] = useState([]);
    const [loading, setLoading] = useState(false);
    const companyId = localStorage.getItem('companyId');
    //Handle Modal Update
    const [modalUpdatePosition, setModalUpdatePosition] = useState(false);
    const modal = props.modal;
    const columns = useMemo(
        () => [
            {
                accessorKey: "directorateName",
                header: "Direksi",
            },
            {
                accessorKey: "divisionName",
                header: "Divisi",
            },
            {
                accessorKey: "departmentName",
                header: "Departemen",
            },
            {
                accessorKey: "title",
                header: "Position",
            },
            {
                accessorKey: "grade",
                header: "Grade",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchPosition().finally();
        document.title = 'Position';
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch data position
    */
    async function fetchPosition() {
        setLoading(true);
        await ApiConfig.get(`/position/${companyId}`).then(response => {
            const data = response.data.doc;
            setPosition(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setPosition([]);
        });
        setLoading(false);
    }



    /*
    * Handle fetch data detail position
    */
    const fetchDetailPosition =  async (id) => {
        await ApiConfig.get(`/position/find/${id}`).then(response => {
            const data = response.data.doc;
            console.log(data)
            setDetailPosition(data);
            setModalUpdatePosition(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setDetailPosition([]);
        });
    }



    /*
    * Handle Delete Data
    */
    const confirmDeletePosition = async (id, title) => {
        //Sending request delete
        await ApiConfig.delete(`/position/destroy/${id}`).then(response => {
            message.success(`Position ${title} Is Deleted`);
            console.log(response);
            fetchPosition();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            {/*Handle Create Position*/}
            <Modal
                title="Input Data Position"
                open={modal.modalEntryPosition}
                onCancel={() => modal.setModalEntryPosition(false)}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <PositionCreate
                    fecthData={fetchPosition}
                />

            </Modal>

            {/*Handle Update Position*/}
            <Modal
                title="Update Data Position"
                open={modalUpdatePosition}
                onCancel={() => setModalUpdatePosition(false)}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <PositionUpdate
                    fecthData={fetchPosition}
                    positionData={detailPosition}
                    modalClose={setModalUpdatePosition}
                />

            </Modal>

            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={position}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact'}}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: () => {
                        fetchDetailPosition(row.original.id).finally();
                    },
                })}
                //Row Actions
                renderRowActions={({ row}) => (
                    <Popconfirm
                        placement="left"
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeletePosition(row.original.id, row.original.title)}
                        onCancel={() => message.error(`Delete ${row.original.title} Canceled`)}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-numbers': {
                        size: 10,
                    },
                    'mrt-row-actions': {
                        // header: 'Action', //change header text
                        size: 50, //make actions column wider
                    },
                }}
            />
        </Card>
    );
}

export default PositionView;