/* Import Component React*/
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import React, {useState} from "react";

/*Import Style*/
import {ChevronLeft, Edit3, Plus} from "react-feather";
import {Button, Space} from "antd"

/* Import component module */
import SkView from "./SkView";
import SkDetail from "./SkDetail";

/* Return the module*/
export default function SkNav() {
    //Handle Modal Entry
    const [modalEntrySk, setModalEntrySk] = useState(false);
    const [modalUpdateSk, setModalUpdateSk] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4 ms-2" style={{ fontWeight: "bolder"}}>Sasaran Kinerja</h4>
                {
                    location.pathname === '/system/sasaran-kinerja' ?
                        <Button type="primary" className="me-2" onClick={() => setModalEntrySk(true)}>
                            <Plus className="feather-button"/>
                            Entry
                        </Button>
                        :
                        <Space>
                            <Button className="me-2" onClick={() => navigate(`/system/sasaran-kinerja`)} >
                                <ChevronLeft className="feather-button"/>
                                Back
                            </Button>
                            <Button type="primary" className="me-2" onClick={() => setModalUpdateSk(true)} >
                                <Edit3 className="feather-button"/>
                                Update
                            </Button>
                        </Space>



                }
            </div>
            <Routes>
                <Route path="/" element={<SkView modal={{modalEntrySk, setModalEntrySk}}/>} />
                <Route path="detail/:id" element={<SkDetail modal={{modalUpdateSk, setModalUpdateSk}}/>} />
            </Routes>
        </>
    );
}