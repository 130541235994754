/* Import component React */
import React from "react";
import {Routes, Route} from "react-router-dom";

/*Import component*/
import LoginView from "./feature/Authentication/Login";
import Layout from "./layout/Layout";

function App() {
    return (
        <Routes>
            <Route path="/" element={<LoginView />}/>
            <Route path="/system/*" element={<Layout/>} />
        </Routes>
    )
}

export default App;
