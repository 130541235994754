/* Import Component React */
import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card, Col, Row} from "react-bootstrap";
import {Popconfirm, message, Button, Skeleton, Modal} from "antd";
import {MaterialReactTable} from "material-react-table";
import {Plus} from "react-feather";

/*Import Child Component*/
import TeamsAdd from "./TeamsAdd";

/* Return the module */
export default  function TeamsDetail() {
    //Prepare variable
    let {id} = useParams();
    const [superior, setSuperior] = useState([]);
    const [teamDetail, setTeamDetail] = useState([]);
    const [loadingView, setLoadingView] = useState(false);
    //Handle Modal Add
    const [modalAddTeams, setModalAddTeams] = useState(false);
    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Nama",
            },
            {
                accessorKey: "position",
                header: "Jabatan",
            },
            // eslint-disable-next-line
        ], []
    );



    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchTeams().finally();
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch detail data teams
    */
    async function fetchTeams() {
        setLoadingView(true);
        await ApiConfig.get(`/team/find/${id}`).then(response => {
            const data = response.data.doc;
            setSuperior(data);
            setTeamDetail(data.teams);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setSuperior([]);
            setTeamDetail()
        });
        setLoadingView(false);
    }



    /*
    * Handle Delete Data Teams
    */
    const confirmDeleteSubordinate = async (id, name) => {
        //Sending request delete
        await ApiConfig.delete(`/team/destroy-subordinate/${id}`).then(response => {
            message.success(`${name} Is Deleted`);
            console.log(response);
            fetchTeams();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }

    return (
        <>
            <Skeleton active loading={loadingView}>
                {/*Handle Update Teams*/}
                <Modal
                    title="Add New Teams"
                    open={modalAddTeams}
                    onCancel={() => setModalAddTeams(false)}
                    width={500}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button */
                    ]}
                >
                    <hr/>
                    <TeamsAdd
                        fetchData={fetchTeams}
                        superiorDetail={superior}
                        modalClose={setModalAddTeams}
                    />

                </Modal>

                <Row>
                    <AuthVerify />
                    <Col md lg={3}>
                        <Card className="mt-3">
                            <Card.Img variant="top" src={superior.urlProfile} className="p-2"/>
                            <Card.Body className="text-center">
                                <hr/>
                                <Card.Title>Atasan</Card.Title>
                                <Card.Title>{superior.superiorName}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md lg={9}>
                        <Card className="card-body">
                            <MaterialReactTable
                                state={{ isLoading: loadingView }}
                                columns={columns}
                                data={teamDetail}
                                enablePinning
                                enableGlobalFilter={true}
                                enableDensityToggle={false}
                                enableStickyHeader={true}
                                initialState={{ density: 'compact'}}
                                enableColumnResizing={true}
                                columnResizeMode="onChange"
                                enableRowNumbers={true}
                                enableRowActions={true}
                                positionActionsColumn="last"
                                //Row Actions
                                renderRowActions={({ row}) => (
                                    <Popconfirm
                                        placement="left"
                                        title="Delete"
                                        description="Are you sure to delete this?"
                                        onConfirm={() => confirmDeleteSubordinate(row.original.id, row.original.name)}
                                        onCancel={() => message.error(`Delete Teams ${row.original.name} Canceled`)}
                                        okText="Yes"
                                        cancelText="No"
                                    > <Button size="small"> Delete </Button>
                                    </Popconfirm>
                                )}
                                //Display Head Table
                                muiTableHeadCellProps={{
                                    sx: {
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        color: '#1976d2',
                                    },
                                }}
                                //Custom Width Column
                                displayColumnDefOptions={{
                                    'mrt-row-numbers': {
                                        size: 10,
                                    },
                                    'mrt-row-actions': {
                                        // header: 'Action', //change header text
                                        size: 50, //make actions column wider
                                    },
                                }}
                                //Height Table
                                muiTableContainerProps={{
                                    sx: {
                                        minHeight: '300px',
                                        maxHeight: '400px'
                                    }
                                }}
                                //add custom action buttons to top-left of top toolbar
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Button type="primary" htmlType="button" onClick={() => setModalAddTeams(true)}>
                                        <Plus className="feather-button"/>
                                        Add Team
                                    </Button>
                                )}
                            />
                        </Card>
                    </Col>
                </Row>
            </Skeleton>
        </>
    );
}