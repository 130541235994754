/* Import Component React */
import {useEffect, useMemo, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button} from "antd";

/*Import Child Component*/
import DireksiCreate from "./DireksiCreate";
import DireksiUpdate from "./DireksiUpdate";

/* Return the module */
function DireksiView(props) {
    //Prepare variable
    const [direksi, setDireksi] = useState([]);
    const [detailDireksi, setDetailDireksi] = useState([]);
    const [loading, setLoading] = useState(false);
    const companyId = localStorage.getItem('companyId');
    //Handle Modal Entry & Update
    const [modalUpdateDireksi, setModalUpdateDireksi] = useState(false);
    const modal = props.modal;
    const columns = useMemo(
        () => [
            {
                accessorKey: "directorate_name",
                header: "Direksi",
            },{
                accessorKey: "description",
                header: "Deskripsi",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchDirectorate().finally();
        document.title = 'Direksi';
    }, []);



    /*
    * Handle fetch data directorate
    */
    async function fetchDirectorate() {
        setLoading(true);
        await ApiConfig.get(`/directorate/${companyId}`).then(response => {
            const data = response.data.doc;
            setDireksi(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setDireksi([]);
        });
        setLoading(false);
    }



    /*
    * Handle fetch data detail directorate
    */
    const fetchDetailDirectorate =  async (id) => {
        await ApiConfig.get(`/directorate/find/${id}`).then(response => {
            const data = response.data.doc;
            setDetailDireksi(data);
            setModalUpdateDireksi(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setDetailDireksi([]);
        });
    }



    /*
    * Handle close modal update direksi
    */
    const handleCloseModalUpdateDireksi = () => {
        setModalUpdateDireksi(false);
    }



    /*
    * Handle Delete Data
    */
    const confirmDeleteDirectorate = async (id, directorate_name) => {
        //Sending request delete
        await ApiConfig.delete(`/directorate/destroy/${id}`).then(response => {
            message.success(`Direksi ${directorate_name} Is Deleted`);
            console.log(response);
            fetchDirectorate();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            {/*Handle Directorate Create*/}
            <DireksiCreate
                fecthData={fetchDirectorate}
                modalEnrty={modal.modalEntryDireksi}
                modalClose={modal.handleCloseModalEntryDireksi}
            />

            {/*Handle Directorate Update*/}
            <DireksiUpdate
                fecthData={fetchDirectorate}
                direksiData={detailDireksi}
                modalUpdate={modalUpdateDireksi}
                modalClose={handleCloseModalUpdateDireksi}
            />

            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={direksi}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact'}}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        // navigate(`/system/company/detail/${row.original.id}`);
                        fetchDetailDirectorate(row.original.id).finally();
                    },
                })}
                //Row Actions
                renderRowActions={({ row}) => (
                    <Popconfirm
                        placement="left"
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeleteDirectorate(row.original.id, row.original.directorate_name)}
                        onCancel={() => message.error(`Delete ${row.original.directorate_name} Canceled`)}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        // header: 'Action', //change header text
                        size: 10, //make actions column wider
                    },
                    'mrt-row-numbers': {
                        size: 10,
                    }
                }}
            />
        </Card>
    );
}

export default DireksiView;