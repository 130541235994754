/* Import Component React*/
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import React, {useState} from "react";

/*Import Style*/
import {ChevronLeft, Plus} from "react-feather";
import {Button} from "antd"

/* Import component module */
import TeamsView from "./TeamsView";
import TeamsDetail from "./TeamsDetail";

/* Return the module*/
export default function TeamsNav() {
    //Handle Modal Entry
    const [modalEntryTeams, setModalEntryTeams] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4 ms-2" style={{ fontWeight: "bolder"}}>Teams</h4>
                {
                    location.pathname === '/system/team' ?
                        <Button type="primary" className="me-2" onClick={() => setModalEntryTeams(true)}>
                            <Plus className="feather-button"/>
                            Entry
                        </Button>
                        :
                        <Button className="me-2" onClick={() => navigate(`/system/team`)} >
                            <ChevronLeft className="feather-button"/>
                            Back
                        </Button>

                }
            </div>
            <Routes>
                <Route path="/" element={<TeamsView modal={{modalEntryTeams, setModalEntryTeams}}/>} />
                <Route path="detail/:id" element={<TeamsDetail/>} />
            </Routes>
        </>
    );
}