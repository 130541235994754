/* Import Component React */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card, Col, ListGroup, Row} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button, Modal, DatePicker, Form, Progress, Space, Segmented} from "antd";

/* Import Time Component */
import dayjs from "dayjs";

/*Import Child Component*/
import SkCreate from "./SkCreate";

/* Return the module */
export default function SkView(props) {
    //Prepare variable
    const companyId = localStorage.getItem('companyId');
    const [formPeriod] = Form.useForm();
    const grade = localStorage.getItem('grade');
    const userId = localStorage.getItem('userId');
    const [skData, setSkData] = useState([]);
    const [score, setScore] = useState(0);
    const [scoreKpi, setScoreKpi] = useState(0);
    const [scorePi, setScorePi] = useState(0);
    const [totalAch, setTotalAch] = useState(0);
    const [loading, setLoading] = useState(false);
    const yearFormat = 'YYYY';
    const monthFormat = 'M';
    const currentDate = new Date();
    const [year, setYear] = useState(dayjs(currentDate).format(yearFormat));
    const [quarter, setQuarter] = useState("");
    const navigate = useNavigate();
    const modal = props.modal;
    const columns = useMemo(
        () => [
            // {
            //     accessorKey: "serialNumber",
            //     header: "ID",
            //     minSize: 100,
            //     maxSize: 100,
            // },
            {
                accessorKey: "group",
                header: "Group",
                minSize: 110,
                maxSize: 110,
            },
            {
                accessorKey: "type",
                header: "Tipe",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "performanceGoal",
                header: "Sasaran Kinerja",
                minSize: 500,
                maxSize: 500,
            },
            {
                accessorKey: "uom",
                header: "UoM",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "target",
                header: "Target",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "roleTarget",
                header: "Tipe",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "actual",
                header: "Actual",
                minSize: 110,
                maxSize: 110,
            },
            {
                accessorKey: "gap",
                header: "GAP",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "score",
                header: "Score",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "status",
                header: "Status",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "ach",
                header: "Ach%",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "sesuai",
                header: "Sesuai",
                minSize: 110,
                maxSize: 110,
            },
            {
                accessorKey: "notSesuai",
                header: "Tidak Sesuai",
                minSize: 150,
                maxSize: 150,
            },
            {
                accessorKey: "submission",
                header: "Belum Di Nilai",
                minSize: 170,
                maxSize: 170,
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        document.title = 'Sasaran Kinerja';
        fetchSettingPeriod().finally();
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch data setting period
    */
    async function fetchSettingPeriod() {
        setLoading(true);
        await ApiConfig.get(`/env/get-setting-period`).then(response => {
            const data = response.data;
            if (data.automaticPeriod === "false") {
                fetchSk(data.currentYear, data.currentGroup).finally();
                setYear(data.currentYear);
                setQuarter(data.currentGroup);
                formPeriod.setFieldsValue({
                    tahun: dayjs(data.currentYear),
                    group: data.currentGroup,
                });
            } else {
                fetchSk(year, currentQuarter()).finally();
                formPeriod.setFieldsValue({
                    tahun: dayjs(year),
                    group: currentQuarter(),
                });
                setQuarter(currentQuarter);
            }
            /*eslint-disable-next-line*/
        }).catch(error => {
            console.log(error);
        });
        setLoading(false);
    }



    /*
    * Handle Current Quarter.
    */
    const currentQuarter = () => {
        const month = dayjs(currentDate).format(monthFormat);
        if (month <= 3) {
            return "Q1";
        } else if (month <= 6) {
            return "Q2";
        } else if (month <= 9) {
            return "Q3";
        } else {
            return "Q4";
        }
    }



    /*
    * Handle year user trigger form select year.
    */
    const handleYear = useCallback( (value) => {
        const selectedYear = dayjs(value).format(yearFormat);
        fetchSk(selectedYear, quarter).finally();
        setYear(selectedYear);
        /* eslint-disable */
    }, [year, quarter]);



    /*
    * Handle Quarter by current month
    */
    const handleQuarter = useCallback( (value) => {
      fetchSk(year, value).finally();
      setQuarter(value);
    }, [year, quarter]);



    /*
    * Handle fetch data SK.
    */
    async function fetchSk(year, quarter) {
        setLoading(true);
        if (grade === "Manajer") {
            await ApiConfig.get(`/sk-manager/all/${companyId}/${year}/${quarter}/${userId}`).then(response => {
                const data = response.data.doc;
                setSkData(data);
                setScore(response.data.totalScore);
                setScoreKpi(response.data.totalScoreKpi);
                setScorePi(response.data.totalScorePi);
                setTotalAch(response.data.totalAch);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkData([]);
                setScore(0);
                setScoreKpi(0);
                setScorePi(0);
                setTotalAch(0);
            });
        } else if (grade === "Asisten Manajer") {
            await ApiConfig.get(`/sk-assistant-manager/all/${companyId}/${year}/${quarter}/${userId}`).then(response => {
                const data = response.data.doc;
                setSkData(data);
                setScore(response.data.totalScore);
                setScoreKpi(response.data.totalScoreKpi);
                setScorePi(response.data.totalScorePi);
                setTotalAch(response.data.totalAch);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkData([]);
                setScore(0);
                setScoreKpi(0);
                setScorePi(0);
                setTotalAch(0);
            });
        } else {
            await ApiConfig.get(`/sk-staff/all/${companyId}/${year}/${quarter}/${userId}`).then(response => {
                const data = response.data.doc;
                setSkData(data);
                setScore(response.data.totalScore);
                setScoreKpi(response.data.totalScoreKpi);
                setScorePi(response.data.totalScorePi);
                setTotalAch(response.data.totalAch);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
                setSkData([]);
                setScore(0);
                setScoreKpi(0);
                setScorePi(0);
                setTotalAch(0);
            });
        }
        setLoading(false);
    }



    /*
    * Handle Delete Data
    */
    const confirmDeleteSk = async (id, year, group, serialNumber) => {
        //Sending request delete
        if (grade === "Manajer") {
            await ApiConfig.delete(`/sk-manager/destroy/${id}`).then(response => {
                message.success(`${serialNumber} Is Deleted`);
                console.log(response);
                fetchSk(year, group);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        } else if (grade === "Asisten Manajer") {
            await ApiConfig.delete(`/sk-assistant-manager/destroy/${id}`).then(response => {
                message.success(`${serialNumber} Is Deleted`);
                console.log(response);
                fetchSk(year, group);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        } else {
            await ApiConfig.delete(`/sk-staff/destroy/${id}`).then(response => {
                message.success(`${serialNumber} Is Deleted`);
                console.log(response);
                fetchSk(year, group);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        }

    }



    return (

        <Row className="justify-content-center">
            <Col xs sm lg={11}>
                <Card className="card-body shadow-sm">
                    <AuthVerify />

                    {/*Handle Create SK*/}
                    <Modal
                        title="Input Sasaran Kinerja"
                        open={modal.modalEntrySk}
                        onCancel={() => modal.setModalEntrySk(false)}
                        width={700}
                        style={{
                            top: 57,
                        }}
                        footer={[
                            /*Disable Button */
                        ]}
                    >
                        <hr/>
                        <SkCreate
                            fecthData={fetchSk}
                            year={year}
                            quarter={quarter}
                        />

                    </Modal>

                    <MaterialReactTable
                        state={{ isLoading: loading }}
                        columns={columns}
                        data={skData}
                        enablePinning
                        enableGlobalFilter={true}
                        enableDensityToggle={false}
                        enableStickyHeader={true}
                        initialState={{ density: 'compact', columnPinning: { right: ['score', 'ach', 'mrt-row-actions'] }}}
                        enableColumnResizing={true}
                        columnResizeMode="onChange"
                        enableRowNumbers={true}
                        enableRowActions={true}
                        positionActionsColumn="last"
                        //Double click for edit
                        muiTableBodyCellProps={({ row }) => ({
                            onDoubleClick: () => {
                                navigate(`/system/sasaran-kinerja/detail/${row.original.id}`);
                            },
                        })}
                        //Row Actions
                        renderRowActions={({ row}) => (
                            <Popconfirm
                                placement="left"
                                title="Delete"
                                description="Are you sure to delete this?"
                                onConfirm={() => confirmDeleteSk(row.original.id, row.original.year, row.original.group, row.original.serialNumber)}
                                onCancel={() => message.error(`Delete ${row.original.serialNumber} Canceled`)}
                                okText="Yes"
                                cancelText="No"
                            > <Button size="small"> Delete </Button>
                            </Popconfirm>
                        )}
                        //Display Head Table
                        muiTableHeadCellProps={{
                            sx: {
                                fontWeight: 'bold',
                                fontSize: '14px',
                                color: '#1976d2',
                            },
                        }}
                        //Height Table
                        muiTableContainerProps={{
                            sx: {
                                minHeight: '400px',
                                maxHeight: '500px'
                            }
                        }}
                        //Custom Width Column
                        displayColumnDefOptions={{
                            'mrt-row-numbers': {
                                size: 10,
                            },
                            'mrt-row-actions': {
                                // header: 'Action', //change header text
                                // size: 50, //make actions column wider
                            },
                        }}
                        //add custom action buttons to top-left of top toolbar
                        renderTopToolbarCustomActions={({ table }) => (
                            <Form layout="horizontal" form={formPeriod} requiredMark={false}>
                                <Space>
                                    <Form.Item label="Tahun" name="tahun">
                                        <DatePicker onChange={handleYear} defaultValue={dayjs(currentDate)} picker="year" />
                                    </Form.Item>
                                    <Form.Item label="Group" name="group" className="ms-4">
                                        <Segmented onChange={handleQuarter} options={['Q1', 'Q2', 'Q3', 'Q4']} defaultValue={currentQuarter()}/>
                                    </Form.Item>
                                </Space>
                            </Form>
                        )}
                    />
                </Card>
            </Col>

            <Col xs sm lg={1}>
                <Card className="card-body shadow-sm p-0 text-center">
                    <ListGroup variant="flush">
                        <ListGroup.Item className="header-form">Detail</ListGroup.Item>

                        <ListGroup.Item className="item-instruction text-center">
                            Score KPI <br/>
                            <Progress type="circle" className="mt-1" percent={scoreKpi} size={55} format={(percent) => `${percent} %`} />
                        </ListGroup.Item>

                        <ListGroup.Item className="item-instruction text-center">
                            Score PI <br/>
                            <Progress type="circle" className="mt-1" percent={scorePi} size={55} format={(percent) => `${percent} %`} />
                        </ListGroup.Item>

                        <ListGroup.Item className="item-instruction text-center">
                            Score <br/>
                            <Progress type="circle" className="mt-1" percent={score} size={55} format={(percent) => `${percent} %`} />
                        </ListGroup.Item>

                        <ListGroup.Item className="item-instruction">
                            Achiev <br/>
                            <Progress type="circle" className="mt-1"  percent={totalAch} size={55} format={(percent) => `${percent} %`} />
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
    );
}