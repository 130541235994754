/* Import Component React*/
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import React from "react";

/*Import Style*/
import {ChevronLeft} from "react-feather";
import {Button} from "antd"

/* Import component module */
import SkTeamView from "./SkTeamView";
import SkTeamDetail from "./SkTeamDetail";

/* Return the module*/
export default function SkTeamNav() {
    const location = useLocation();
    const navigate = useNavigate();

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4 ms-2" style={{ fontWeight: "bolder"}}>Sasaran Kinerja Team</h4>
                {
                    location.pathname !== '/system/sk-team' &&
                        <Button className="me-2" onClick={() => navigate(`/system/sk-team`)} >
                            <ChevronLeft className="feather-button"/>
                            Back
                        </Button>
                }
            </div>
            <Routes>
                <Route path="/" element={<SkTeamView />} />
                <Route path="detail/:id/:grade/:year/:group" element={<SkTeamDetail />} />
            </Routes>
        </>
    );
}