/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import KriteriaForm from "./KriteriaForm";

export default function KriteriaCreate(props) {
    //Prepare variable
    const [formEntryKriteria] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);

    /*
    * Handle Store Data
    */
    const storeKriteria = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            kriteria: values.kriteria,
            score: values.score,
            createdBy: localStorage.getItem('userId'),
        };

        //Send data to backend for store
        await ApiConfig.post('/kriteria-360/store', formData).then(() => {
            message.success(`Kriteria Saved`);
            props.fetchData();
            formEntryKriteria.resetFields();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={storeKriteria} form={formEntryKriteria} requiredMark={false} className="mt-3">
                <KriteriaForm
                    loading={loadingBtn}
                    formReference={formEntryKriteria}
                    logoRequired={true}
                    button={"Submit"}
                />
            </Form>
        </>
    )
}