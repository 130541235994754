/* Import Module */
import React, {useState, useEffect, useCallback} from "react";
import { Card } from 'react-bootstrap';
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";
import dayjs from "dayjs";
import Chart from "react-apexcharts";

/* Import Style*/
import {message, Skeleton, DatePicker, Row, Col} from "antd";
const { RangePicker } = DatePicker;

export default function DashKpiCompany() {
    //Prepare State
    const companyId = localStorage.getItem('companyId');
    const yearFormat = 'YYYY';
    const currentDate = new Date();
    const currentYear = dayjs(currentDate).format(yearFormat);
    const previousYear = dayjs(currentYear).subtract(3, 'year');
    const [yearStart, setYearStart] = useState(dayjs(previousYear).format(yearFormat));
    const [yearEnd, setYearEnd] = useState(currentYear)
    const [categories, setCategories] = useState([]);
    const [series, setSeries] = useState([]);
    const [loading, setLoading] = useState(false);

    /*
   * Initialization function in first render
   */
    useEffect(() => {
        fetchKpiCompany(yearStart, yearEnd).finally();
        document.title = 'Dashboard';
        console.log(yearStart);
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle Filter Value
    */
    const handleFilterYear = useCallback( (value) => {
        const selectedYearStart = value ? dayjs(value[0]).format(yearFormat) : dayjs(previousYear).format(yearFormat);
        const selectedYearEnd = value ? dayjs(value[1]).format(yearFormat) : dayjs(currentYear).format(yearFormat);
        fetchKpiCompany(selectedYearStart, selectedYearEnd).finally();
        setYearStart(selectedYearStart);
        setYearEnd(selectedYearEnd);
        /* eslint-disable */
    }, [yearStart, yearEnd]);


    /*
    * Handle fetch data company
    */
    async function fetchKpiCompany(yearStart, yearEnd) {
        setLoading(true);
        await ApiConfig.get(`/kpi-company/find-data/${companyId}/${yearStart}/${yearEnd}`).then(response => {
            const data = response.data;
            setCategories(data.categories);
            setSeries(data.series);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setCategories([]);
            setSeries([]);
        });
        setLoading(false);
    }

    const chartOptions = () => {
        return {
            options: {
                chart: {
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'straight',
                    width: 2
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: { categories }
            },
            series: [ series ]
        }
    };

    return (
        <Card className="shadow-sm" style={{ width: "100%" }}>
            <AuthVerify />

            <Card.Header>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={4}>
                        KPI Perusahaan
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={{span: 4, offset: 16}}>
                        <RangePicker picker="year" onChange={handleFilterYear} defaultValue={[dayjs(yearStart), dayjs(yearEnd)]}/>
                    </Col>

                </Row>
            </Card.Header>
            <Card.Body>
                <Skeleton active loading={loading}>
                    <Chart
                        height={250}
                        width="100%"
                        options={chartOptions().options}
                        series={chartOptions().series}
                        type="line"
                    />
                </Skeleton>
            </Card.Body>

        </Card>
    );
}