/* Import Component React */
import {useEffect, useMemo, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button} from "antd";

/*Import Child Component*/
import DivisiCreate from "./DivisiCreate";
import DivisiUpdate from "./DivisiUpdate";

/* Return the module */
function DivisiView(props) {
    //Prepare variable
    const [divisi, setDivisi] = useState([]);
    const [detailDivisi, setDetailDivisi] = useState([]);
    const [loading, setLoading] = useState(false);
    const companyId = localStorage.getItem('companyId');
    //Handle Modal Entry & Update
    const [modalUpdateDivisi, setModalUpdateDivisi] = useState(false);
    const modal = props.modal;
    const columns = useMemo(
        () => [
            {
                accessorKey: "directorate.directorate_name",
                header: "Direksi",
            },
            {
                accessorKey: "divisionName",
                header: "Divisi",
            },
            {
                accessorKey: "description",
                header: "Deskripsi",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchDivisi().finally();
        document.title = 'Divisi';
    }, []);



    /*
    * Handle fetch data divisi
    */
    async function fetchDivisi() {
        setLoading(true);
        await ApiConfig.get(`/division/${companyId}`).then(response => {
            const data = response.data.doc;
            setDivisi(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setDivisi([]);
        });
        setLoading(false);
    }



    /*
    * Handle fetch data detail divisi
    */
    const fetchDetailDivisi =  async (id) => {
        await ApiConfig.get(`/division/find/${id}`).then(response => {
            const data = response.data.doc;
            console.log(data)
            setDetailDivisi(data);
            setModalUpdateDivisi(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setDetailDivisi([]);
        });
    }



    /*
    * Handle close modal update divisi
    */
    const handleCloseModalUpdateDivisi = () => {
        setModalUpdateDivisi(false);
    }



    /*
    * Handle Delete Data
    */
    const confirmDeleteDivisi = async (id, divisionName) => {
        //Sending request delete
        await ApiConfig.delete(`/division/destroy/${id}`).then(response => {
            message.success(`Divisi ${divisionName} Is Deleted`);
            console.log(response);
            fetchDivisi();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            {/*Handle Divisi Create*/}
            <DivisiCreate
                fecthData={fetchDivisi}
                modalEnrty={modal.modalEntryDivisi}
                modalClose={modal.handleCloseModalEntryDivisi}
            />

            {/*Handle Divisi Update*/}
            <DivisiUpdate
                fecthData={fetchDivisi}
                divisiData={detailDivisi}
                modalUpdate={modalUpdateDivisi}
                modalClose={handleCloseModalUpdateDivisi}
            />

            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={divisi}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact'}}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        fetchDetailDivisi(row.original.id).finally();
                    },
                })}
                //Row Actions
                renderRowActions={({ row}) => (
                    <Popconfirm
                        placement="left"
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeleteDivisi(row.original.id, row.original.divisionName)}
                        onCancel={() => message.error(`Delete ${row.original.divisionName} Canceled`)}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        // header: 'Action', //change header text
                        size: 10, //make actions column wider
                    },
                    'mrt-row-numbers': {
                        size: 10,
                    }
                }}
            />
        </Card>
    );
}

export default DivisiView;