/* Import Component React */
import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button, Modal} from "antd";

/*Import Child Component*/
import UserCreate from "./UserCreate";


/* Return the module */
function UserView(props) {
    //Prepare variable
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const modal = props.modal;
    const navigate = useNavigate();
    const role = localStorage.getItem('role');
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyId');
    const columns = useMemo(
        () => [
            {
                accessorKey: "nik",
                header: "NIK",
            },
            {
                accessorKey: "role",
                header: "Role",
            },
            {
                accessorKey: "name",
                header: "Nama",
            },
            {
                accessorKey: "divisionName",
                header: "Divisi",
            },
            {
                accessorKey: "departmentName",
                header: "Departemen",
            },
            {
                accessorKey: "position",
                header: "Jabatan",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchUser().finally();
        document.title = 'User';

        if (role === 'User') {
            navigate(`/system/user/detail/${userId}`);
        }
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch data user
    */
    async function fetchUser() {
        setLoading(true);
        await ApiConfig.get(`/user/${companyId}`).then(response => {
            const data = response.data.doc;
            setUser(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setUser([]);
        });
        setLoading(false);
    }



    /*
    * Handle Delete Data
    */
    const confirmDeleteUser = async (id, name) => {
        //Sending request delete
        await ApiConfig.delete(`/user/destroy/${id}`).then(response => {
            message.success(`RouteUser ${name} Is Deleted`);
            console.log(response);
            fetchUser();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            {/*Handle Create RouteUser*/}
            <Modal
                title="Input Data User"
                open={modal.modalEntryUser}
                onCancel={() => modal.setModalEntryUser(false)}
                width={700}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <UserCreate
                    fecthData={fetchUser}
                />

            </Modal>

            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={user}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact'}}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: () => {
                        navigate(`/system/user/detail/${row.original.id}`);
                    },
                })}
                //Row Actions
                renderRowActions={({ row}) => (
                    <Popconfirm
                        placement="left"
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeleteUser(row.original.id, row.original.name)}
                        onCancel={() => message.error(`Delete ${row.original.name} Canceled`)}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-numbers': {
                        size: 10,
                    },
                    // 'mrt-row-actions': {
                    //     // header: 'Action', //change header text
                    //     size: 50, //make actions column wider
                    // },
                }}
            />
        </Card>
    );
}

export default UserView;