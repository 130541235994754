/* Import Component React */
import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";
import numberFormat from "../NumberFormat";

/* Import Style */
import {Card} from "react-bootstrap";
import {message, Skeleton, Modal, Descriptions, Button, Popconfirm,} from "antd";
import {MaterialReactTable} from "material-react-table";
import {Plus} from "react-feather";

/*Import Child Component*/
import KpiCompanyUpdate from "./KpiCompanyUpdate";
import RealizationCreate from "./RealizationKpiCreate";

/* Return the module */
export default  function KpiCompanyDetail(props) {
    //Prepare variable
    let {id} = useParams();
    const [kpiDetail, setKpiDetail] = useState([]);
    const [contribution, setContribution] = useState([]);
    const [loadingView, setLoadingView] = useState(false);
    const [modalRealization, setModalRealization] = useState(false);
    const modal = props.modal;
    const columns = useMemo(
        () => [
            {
                accessorKey: "group",
                header: "Group",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "division",
                header: "Divisi",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "name",
                header: "Nama",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "uom",
                header: "UoM",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "target",
                header: "Target",
                minSize: 120,
                maxSize: 120,
            },
            {
                accessorKey: "realisasi",
                header: "Realisasi",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "gap",
                header: "GAP",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "score",
                header: "Score",
                minSize: 100,
                maxSize: 100,
            },
            {
                accessorKey: "ach",
                header: "Ach%",
                minSize: 100,
                maxSize: 100,
            },
            // eslint-disable-next-line
        ], []
    );



    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchKpiDetail().finally();
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch detail data KPI
    */
    async function fetchKpiDetail() {
        setLoadingView(true);
        await ApiConfig.get(`/kpi-company/find/${id}`).then(response => {
            const data = response.data.doc;
            setKpiDetail(data);
            setContribution(data.contribution);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setKpiDetail([]);
            setContribution([]);
        });
        setLoadingView(false);
    }



    /*
    * Handle Delete Evidence
    */
    async function confirmDestroyEvidence(id) {
        await ApiConfig.delete(`/realization/destroy-realization-kpi/${id}`).then(() => {
            fetchKpiDetail();
            message.success("Delete Realisasi Success");
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }

    const borderedItems = [
        {
            key: '1',
            label: 'ID - Tahun',
            children: (
                <>
                    {kpiDetail.serialNumber} - {kpiDetail.year}
                </>
            ),
        },
        {
            key: '2',
            label: 'Dibuat Oleh',
            children: kpiDetail.createdBy,
        },
        {
            key: '3',
            label: 'Diedit Oleh',
            children: kpiDetail.updatedBy,
        },
        {
            key: '4',
            label: 'Target - UoM [Tipe Target]',
            children:(
                <>
                    {numberFormat(kpiDetail.target)} - {kpiDetail.uom} [{kpiDetail.roleTarget}]
                </>
            ),
        },
        {
            key: '5',
            label: 'Actual',
            children:(
                <>
                    {numberFormat(kpiDetail.actual)}
                </>
            ),
        },
        {
            key: '6',
            label: 'GAP',
            children:(
                <>
                    {numberFormat(Math.round(kpiDetail.gap ))}
                </>
            ),
        },
        {
            key: '7',
            label: 'Achievement %',
            children: (
                <>
                    {Math.round( kpiDetail.ach/kpiDetail.target*100 > 100 ? 100 : kpiDetail.ach/kpiDetail.target*100 )}%
                </>
            ),
        },
        {
            key: '8',
            label: 'Score',
            children: (
                <>
                    {kpiDetail.score}%
                </>
            )
        },
        {
            key: '9',
            label: 'Achievement Score %',
            children: (
                <>
                    {kpiDetail.achScore}%
                </>
            ),
        },
        {
            key: '10',
            label: 'Sasaran Kinerja',
            span: 3,
            children: (
                <>
                    {kpiDetail.performanceGoal}
                </>


            ),
        },
        {
            key: '11',
            label: 'Uraian',
            span: 3,
            children: (
                <>
                    {kpiDetail.description}
                </>
            ),
        },
    ];


    return (
        <>
            <Skeleton active loading={loadingView}>
                <AuthVerify />
                {/*Handle Update KPI*/}
                <Modal
                    title="Update Data KPI"
                    open={modal.modalUpdateKpi}
                    onCancel={() => modal.setModalUpdateKpi(false)}
                    width={700}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button*/
                    ]}
                >
                    <hr/>
                    <KpiCompanyUpdate
                        fetchData={fetchKpiDetail}
                        kpiDetail={kpiDetail}
                        modalClose={modal.setModalUpdateKpi}
                    />

                </Modal>

                {/*Description KPI*/}
                <Descriptions
                    bordered
                    size="small"
                    items={borderedItems}
                    className="mt-3"
                />

                {/*Handle Realisasi SK*/}
                <Modal
                    title="Realisasi KPI"
                    open={modalRealization}
                    onCancel={() => setModalRealization(false)}
                    width={500}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button*/
                    ]}
                >
                    <hr/>
                    <RealizationCreate
                        fetchData={fetchKpiDetail}
                        reference={id}
                        kpiDetail={kpiDetail}
                        modal={setModalRealization}
                    />

                </Modal>

                {/*Table Contribute*/}
                <Card className="card-body shadow-sm">
                    <MaterialReactTable
                        state={{ isLoading: loadingView }}
                        columns={columns}
                        data={contribution}
                        enablePinning
                        enableGlobalFilter={true}
                        enableDensityToggle={false}
                        enableStickyHeader={true}
                        initialState={{ density: 'compact' }}
                        enableColumnResizing={true}
                        columnResizeMode="onChange"
                        enableRowNumbers={true}
                        enableRowActions={true}
                        positionActionsColumn="last"
                        //Row Actions
                        renderRowActions={({ row}) => (
                            <Popconfirm
                                placement="left"
                                title="Delete"
                                description="Are you sure to delete this?"
                                onConfirm={() => confirmDestroyEvidence(row.original.id)}
                                onCancel={() => message.error(`Delete Realisasi Canceled`)}
                                okText="Yes"
                                cancelText="No"
                            >
                                {
                                    localStorage.getItem('userId') === row.original.createdBy &&
                                    <Button size="small" > Delete </Button>
                                }
                            </Popconfirm>
                        )}
                        //Display Head Table
                        muiTableHeadCellProps={{
                            sx: {
                                fontWeight: 'bold',
                                fontSize: '14px',
                                color: '#1976d2',
                            },
                        }}
                        //Height Table
                        muiTableContainerProps={{
                            sx: {
                                minHeight: '300px',
                                maxHeight: '400px'
                            }
                        }}
                        //Custom Width Column
                        displayColumnDefOptions={{
                            'mrt-row-numbers': {
                                size: 10,
                            },
                        }}
                        //add custom action buttons to top-left of top toolbar
                        //add custom action buttons to top-left of top toolbar
                        renderTopToolbarCustomActions={({ table }) => (
                            <Button type="primary" htmlType="button" onClick={() => setModalRealization(true)}>
                                <Plus className="feather-button"/>
                                Add Realisasi
                            </Button>
                        )}
                    />
                </Card>
            </Skeleton>
        </>
    );
}