/*Import Component React*/
import React, {useCallback, useEffect, useRef, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Button, DatePicker, Flex, Form, InputNumber, message, Segmented, Select, Switch} from "antd";
import TextArea from "antd/es/input/TextArea";
import {Col, Row} from "react-bootstrap";

/* Import Time Component */
import dayjs from "dayjs";

/* Import Child Component */
import UoM from "../uom/UoM";

export default function SkForm(props) {
    const userId = localStorage.getItem('userId');
    const grade = localStorage.getItem('grade');
    const currentDate = new Date();
    const focusRef = useRef(null);
    const [listReference, setListReference] = useState([]);
    const yearFormat = 'YYYY';
    const [year, setYear] = useState(dayjs(currentDate).format(yearFormat));
    const [quarter, setQuarter] = useState(props.quarter);
    const [type, setType] = useState(props.type);
    const [roleTarget, setRoleTarget] = useState(props.roleTarget);
    const [loadingReference, setLoadingReference] = useState(false);
    const linkTarget = props.linkTarget;

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        if (props.button === "Update") {
            props.setField();
        } else {
            props.formReference.resetFields();
            setYear(dayjs(currentDate).format(yearFormat));
            props.formReference.setFieldsValue({
                group: quarter,
                type: type,
                roleTarget: roleTarget === 'Min' ? 'Min' : 'Max',
            });
        }
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
        props.formReference.setFieldsValue({
            year: dayjs(props.year),
            group: quarter,
            type: type,
            roleTarget: roleTarget === 'Min' ? 'Min' : 'Max',
        });
    }, []);



    /*
    * Handle year user trigger form select year.
    */
    const handleYear = useCallback( (value) => {
        const selectedYear = dayjs(value).format(yearFormat);
        setYear(selectedYear);
        /* eslint-disable */
    }, [year]);



    /*
    * Handle Quarter by current month
    */
    const handleQuarter = useCallback( (value) => {
        setQuarter(value);
    }, [quarter]);



    /*
    * Handle Type SK
    */
    const handleType = useCallback( (value) => {
        setType(value);
        props.formReference.setFieldsValue({
            referenceId: null,
            performanceGoal:null,
            description: null,
            uom: null,
            target: null,
        });
    }, [type]);

    /*
    * Handle Type Role Target
    */
    const handleRoleTarget = useCallback( (value) => {
        setRoleTarget(value);
    }, [roleTarget]);



    /*
    * Handle Reference PerformanceGoal
    */
    const handleReference = useCallback( () => {
        // if (grade === "Manajer" && type === "PI") {
        //     setListReference([]);
        // } else {
        //     fetchListReference().finally();
        // }

        fetchListReference().finally();

    }, [year, quarter, type]);


    /*
    * Handle fetch data list reference
    */
    async function fetchListReference() {
        //Await
        setLoadingReference(true);
        await ApiConfig.get(`/search-reference-sk/reference/${userId}/${year}/${quarter}/${grade}`).then(response => {
            setListReference(response.data.doc);
        }).catch((error) => {
            console.log(error);
            message.error(error.response.data.message);
            setListReference([]);
        });
        setLoadingReference(false);
    }



    /*
    * Handle fetch detail reference.
    */
    async function fetchDetailReference(values) {
        await ApiConfig.get(`/search-reference-sk/find-reference/${values}`).then(response => {
            const reference = response.data.doc;
            props.formReference.setFieldsValue({
                performanceGoal: reference.performanceGoal,
                description: reference.description,
                uom: reference.uom,
                target: linkTarget.linkTarget === true ? reference.currentTarget : null,
            });
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }

    return (
        <>
            <Row>
                <Col xs sm lg={2}>
                    <Form.Item label="Tahun" name="year">
                        <DatePicker onChange={handleYear} /*defaultValue={dayjs(props.year)}*/ picker="year" disabled={props.status === "Approved"}/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={4}>
                    <Form.Item label="Group" name="group" rules={[ {required: true, message: "Please select a group"} ]}>
                        <Segmented onChange={handleQuarter} options={['Q1', 'Q2', 'Q3', 'Q4']} disabled={props.status === "Approved"}/>
                    </Form.Item>
                </Col>

                <Col xs sm lg={2}>
                    <Form.Item label="Tipe" name="type" rules={[ {required: true, message: "Please select a type"} ]}>
                        <Segmented onChange={handleType} options={['KPI', 'PI']} disabled={props.status === "Approved"}/>
                    </Form.Item>
                </Col>

                <Col xs sm lg={2}>
                    <Form.Item label="Tipe Target" name="roleTarget" rules={[ {required: true, message: "Please select a type Target"} ]}>
                        <Segmented onChange={handleRoleTarget} options={['Max', 'Min']}/>
                    </Form.Item>
                </Col>

                <Col xs sm lg={2}>
                    <Form.Item label="Link Target" name="linkTarget">
                        <Switch  checkedChildren="Ya" unCheckedChildren="Tidak" defaultChecked={linkTarget.linkTarget} onChange={linkTarget.handleLinkTarget} disabled={props.status === "Approved"}/>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item label="Reference" name="referenceId">
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } options={listReference} onClick={handleReference} loading={loadingReference} onSelect={fetchDetailReference} disabled={props.status === "Approved"}/>
            </Form.Item>

            <Form.Item label="Sasaran Kinerja" name="performanceGoal" rules={[
                {required: true, message: "Please input Sasaran Kinerja"},
                {min: 10, message: "Sasaran Kinerja must have min 10 character"}
            ]}>
                <TextArea showCount maxLength={500} ref={focusRef}/>
            </Form.Item>

            <Form.Item label="Uraian" name="description" rules={[
                {required: true, message: "Please input Uaraian"},
                {min: 10, message: "Uraian must have min 10 character"}
            ]}>
                <TextArea showCount maxLength={1000} />
            </Form.Item>

            <Row>
                <Col md lg={4}>
                    <UoM status={props.status} targetStatus={props.targetStatus}/>
                </Col>
                <Col md lg={4}>
                    <Form.Item label="Target" name="target" rules={[
                        {required: true, message: "Please input Target"},
                    ]}>
                        <InputNumber style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')} disabled={props.status === "Approved"}
                        />
                    </Form.Item>
                </Col>
                <Col md lg={4}>
                    <Form.Item label="Score" name="score" rules={[
                        {required: true, message: "Please input Score"},
                    ]}>
                        <InputNumber style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')} min={1} disabled={props.status === "Approved"}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}