/* Import Component React */
import {useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, Modal, message} from "antd";

/*Import Child Component*/
import DepartmentForm from "./DepartmentForm";

function DepartmentUpdate(props) {
    //Prepare variable
    const [formUpdateDepartment] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const department = props.departmentData;


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fillField();
        /*eslint-disable-next-line*/
    }, [department]);



    /*
    * Handle Fill Field Form
    */
    const fillField = () => {
        formUpdateDepartment.setFieldsValue({
            divisionId: { value: department.divisionId, label: department.divisionName },
            departmentName: department.departmentName,
            description: department.description,
        });
    }



    /*
    * Handle Update Data
    */
    const updateDataDepartment = async (values) => {
        setLoadingBtn(true);
        //Prepare value options
        let divisionId;
        divisionId = values.divisionId.value ? values.divisionId.value : values.divisionId;

        const formData = {
            companyId: localStorage.getItem('companyId'),
            divisionId: divisionId,
            departmentName: values.departmentName,
            description: values.description,
        };

        //Send data to backend for update
        await ApiConfig.patch(`/department/update/${department.id}`, formData).then(() => {
            message.success(`RouteDepartment ${values.departmentName} Updated`);
            props.fecthData();
            formUpdateDepartment.resetFields();
            props.modalClose();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            {/*Handle Create Delivery*/}
            <Modal
                title="Update Data RouteDepartment"
                open={props.modalUpdate}
                onCancel={props.modalClose}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <Form layout="vertical" onFinish={updateDataDepartment} form={formUpdateDepartment} requiredMark={false} className="mt-3">
                    <DepartmentForm
                        loading={loadingBtn}
                        formReference={formUpdateDepartment}
                        logoRequired={true}
                        button={"Update"}
                    />
                </Form>
            </Modal>
        </>
    )
}

export default DepartmentUpdate;