/* Import Module */
import React from "react";

/* Import Child Component */
import SettingPeriod from './SettingPeriod';

export default function Setting() {

    return (
        <>
            <SettingPeriod />
        </>
    );
}