/* Import Component React */
import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card, Col, Row} from "react-bootstrap";
import {Popconfirm, message, Button, Descriptions, Form, Space, Skeleton, Modal} from "antd";

/*Import Child Component*/
import UserProfileUpdate from "./UserProfileUpdate";
import UserPasswordUpdate from "./UserPasswordUpdate";
import UserPictUpdate from "./UserPictUpdate";

/* Return the module */
function UserDetail() {
    //Prepare variable
    const [userDetail, setUserDetail] = useState([]);
    const [loadingView, setLoadingView] = useState(false);
    let {id} = useParams();
    //Handle Modal Entry
    const [modalUpdateProfile, setModalUpdateProfile] = useState(false);
    const [modalUpdatePassword, setModalUpdatePassword] = useState(false);
    const [modalUpdatePict, setModalUpdatePict] = useState(false);



    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchUser().finally();
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle fetch detail data user
    */
    async function fetchUser() {
        setLoadingView(true);
        await ApiConfig.get(`/user/find/${id}`).then(response => {
            const data = response.data.doc;
            setUserDetail(data);
            setModalUpdateProfile(false);
            setModalUpdatePassword(false);
            setModalUpdatePict(false);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setUserDetail([]);
        });
        setLoadingView(false);
    }

    return (
        <>
            <Skeleton active loading={loadingView}>
                {/*Handle Update RouteUser Profile*/}
                <Modal
                    title="Update Profile User"
                    open={modalUpdateProfile}
                    onCancel={() => setModalUpdateProfile(false)}
                    width={700}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button */
                    ]}
                >
                    <hr/>
                    <UserProfileUpdate
                        fetchData={fetchUser}
                        userData={userDetail}
                        modalClose={setModalUpdateProfile}
                    />

                </Modal>

                {/*Handle Update RouteUser Password*/}
                <Modal
                    title="Update Password User"
                    open={modalUpdatePassword}
                    onCancel={() => setModalUpdatePassword(false)}
                    width={500}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button */
                    ]}
                >
                    <hr/>
                    <UserPasswordUpdate
                        fetchData={fetchUser}
                        userData={userDetail}
                        modalClose={setModalUpdatePassword}
                    />

                </Modal>

                {/*Handle Update RouteUser Profile Pict*/}
                <Modal
                    title="Update Profile Picture"
                    open={modalUpdatePict}
                    onCancel={() => setModalUpdatePict(false)}
                    width={500}
                    style={{
                        top: 57,
                    }}
                    footer={[
                        /*Disable Button */
                    ]}
                >
                    <hr/>
                    <UserPictUpdate
                        fetchData={fetchUser}
                        userData={userDetail}
                        modalClose={setModalUpdatePict}
                    />

                </Modal>

                <Row>
                    <AuthVerify />
                    <Col md lg={3}>
                        <Card className="mt-3">
                            <Card.Img variant="top" src={userDetail.urlProfile} className="p-2"/>
                            <Card.Body className="text-center">
                                <hr/>
                                <Card.Title>{userDetail.nik}</Card.Title>
                                <Card.Title>{userDetail.name}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md lg={9}>
                        <Card className="card-body">
                            <Descriptions title="RouteUser Info" className="mt-2">
                                <Descriptions.Item label="Direksi">{userDetail.directorateName}</Descriptions.Item>
                                <Descriptions.Item label="Divisi">{userDetail.divisionName}</Descriptions.Item>
                                <Descriptions.Item label="Departemen">{userDetail.departmentName}</Descriptions.Item>
                                <Descriptions.Item label="Jabatan">{userDetail.positionName}</Descriptions.Item>
                                <Descriptions.Item label="Email">{userDetail.email}</Descriptions.Item>
                                <Descriptions.Item label="Role">{userDetail.role}</Descriptions.Item> <br/>
                            </Descriptions>
                            <hr className="my-3" />
                            <Form.Item>
                                <Space>
                                    <Popconfirm
                                        title="Update Profile"
                                        description="Are you sure to update this?"
                                        onConfirm={() => setModalUpdateProfile(true)}
                                        onCancel={() => message.error(`${userDetail.name} Update Canceled`)}
                                        okText="Yes"
                                        cancelText="No"
                                    > <Button type="primary"> Update Profile </Button>
                                    </Popconfirm>
                                    <Popconfirm
                                        title="Update Password"
                                        description="Are you sure to update this?"
                                        onConfirm={() => setModalUpdatePassword(true)}
                                        onCancel={() => message.error(`${userDetail.name} Update Canceled`)}
                                        okText="Yes"
                                        cancelText="No"
                                    > <Button type="primary"> Update Password </Button>
                                    </Popconfirm>
                                    <Popconfirm
                                        title="Update Pict"
                                        description="Are you sure to update this?"
                                        onConfirm={() => setModalUpdatePict(true)}
                                        onCancel={() => message.error(`${userDetail.name} Update Canceled`)}
                                        okText="Yes"
                                        cancelText="No"
                                    > <Button type="primary"> Update Profile Pict </Button>
                                    </Popconfirm>
                                </Space>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Skeleton>
        </>
    );
}

export default UserDetail;