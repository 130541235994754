/* Import Component React */
import {useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, Modal, message} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import 'dayjs/locale/en-in';

/*Import Child Component*/
import CompanyForm from "./CompanyForm";

function CompanyUpdate(props) {
    //Prepare variable
    const [formEntryCompany] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const company = props.companyData;


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fillField();
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle Fill Field Form
    */
    const fillField = () => {
        formEntryCompany.setFieldsValue({
            company_name: company.company_name,
            email: company.email,
            pic_name: company.pic_name,
            address: company.address,
            expired_at: dayjs(company.expired_at),
        });
    }



    /*
    * Defined File On Form
    */
    const files = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    /*
    * Handle Update Data
    */
    const updateDataCompany = async (values) => {
        setLoadingBtn(true);
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('company_logo', file);
        });

        //Append data
        formData.append('company_name', values.company_name);
        formData.append('address', values.address);
        formData.append('email', values.email);
        formData.append('pic_name', values.pic_name);
        formData.append('expired_at', values.expired_at);

        //Send data to backend for store
        await ApiConfig.patch(`/company/update/${company.id}`, formData).then(() => {
            setFileList([]);
            message.success(`Company ${values.company_name} Updated`);
            props.fetchData();
            formEntryCompany.resetFields();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            {/*Handle Create Delivery*/}
            <Modal
                title="Update Data Perusahaan"
                open={props.modalUpdate}
                onCancel={props.modalClose}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <Form layout="vertical" onFinish={updateDataCompany} form={formEntryCompany} requiredMark={false} className="mt-3">
                    <CompanyForm
                        files={files}
                        loading={loadingBtn}
                        formReference={formEntryCompany}
                        logoRequired={false}
                        button={"Update"}
                    />
                </Form>
            </Modal>
        </>
    )
}

export default CompanyUpdate;