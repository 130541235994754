/* Import Component React */
import {useEffect, useMemo, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";

/* Import Style */
import {Card} from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import {message, Popconfirm, Button, Modal} from "antd";

/*Import Child Component*/
import UomCreate from "./UomCreate";
import UomUpdate from "./UomUpdate";

/* Return the module */
export default function UomView(props) {
    //Prepare variable
    const companyId = localStorage.getItem('companyId');
    const [uom, setUom] = useState([]);
    const [detailUom, setDetailUom] = useState([]);
    const [loading, setLoading] = useState(false);

    //Handle Modal Entry & Update
    const [modalUpdateUom, setModalUpdateUom] = useState(false);

    //Handle Column Table
    const columns = useMemo(
        () => [
            {
                accessorKey: "value",
                header: "Value",
            },{
                accessorKey: "label",
                header: "Label",
            },
            // eslint-disable-next-line
        ], []
    );


    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fetchUom().finally();
        document.title = 'UoM';
    }, []);



    /*
    * Handle fetch data uom
    */
    async function fetchUom() {
        setLoading(true);
        await ApiConfig.get(`/uom/${companyId}`).then(response => {
            const data = response.data.doc;
            setUom(data);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setUom([]);
        });
        setLoading(false);
    }



    /*
    * Handle fetch data detail uom
    */
    const fetchDetailUom =  async (id) => {
        await ApiConfig.get(`/uom/find/${id}`).then(response => {
            const data = response.data.doc;
            setDetailUom(data);
            setModalUpdateUom(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setDetailUom([]);
        });
    }



    /*
    * Handle Delete Data
    */
    const confirmDeleteUom = async (id, uom) => {
        //Sending request delete
        await ApiConfig.delete(`/uom/destroy/${id}`).then(response => {
            message.success(`UoM ${uom} Is Deleted`);
            console.log(response);
            fetchUom();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
    }



    return (
        <Card className="card-body shadow-sm">
            <AuthVerify />

            {/*Handle Uom Create*/}
            <Modal
                title="Input Data Uom"
                open={props.modalState}
                onCancel={() => props.modalSet(false)}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <UomCreate
                    fetchData={fetchUom}
                />

            </Modal>

            {/*Handle Uom Update*/}
            <Modal
                title="Update Data Uom"
                open={modalUpdateUom}
                onCancel={() => setModalUpdateUom(false)}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <UomUpdate
                    fetchData={fetchUom}
                    detailUom={detailUom}
                    modalSet={setModalUpdateUom}
                />

            </Modal>

            <MaterialReactTable
                state={{ isLoading: loading }}
                columns={columns}
                data={uom}
                enablePinning
                enableGlobalFilter={true}
                enableDensityToggle={false}
                enableStickyHeader={true}
                initialState={{ density: 'compact'}}
                enableColumnResizing={true}
                columnResizeMode="onChange"
                enableRowNumbers={true}
                enableRowActions={true}
                positionActionsColumn="last"
                //Double click for edit
                muiTableBodyCellProps={({ row }) => ({
                    onDoubleClick: (e) => {
                        fetchDetailUom(row.original.id).finally();
                    },
                })}
                //Row Actions
                renderRowActions={({ row}) => (
                    <Popconfirm
                        placement="left"
                        title="Delete"
                        description="Are you sure to delete this?"
                        onConfirm={() => confirmDeleteUom(row.original.id, row.original.label)}
                        onCancel={() => message.error(`Delete ${row.original.label} Canceled`)}
                        okText="Yes"
                        cancelText="No"
                    > <Button size="small"> Delete </Button>
                    </Popconfirm>
                )}
                //Display Head Table
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'bold',
                        fontSize: '14px',
                        color: '#1976d2',
                    },
                }}
                //Height Table
                muiTableContainerProps={{
                    sx: {
                        minHeight: '400px',
                        maxHeight: '500px'
                    }
                }}
                //Custom Width Column
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        // header: 'Action', //change header text
                        size: 10, //make actions column wider
                    },
                    'mrt-row-numbers': {
                        size: 10,
                    }
                }}
            />
        </Card>
    );
}