import axios from 'axios';

// Check if access token is available in localStorage
const accessToken = localStorage.getItem('token');

// Set default authorization header for axios if access token is available
if (accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
} else {
    // If access token is not available, remove the authorization header
    delete axios.defaults.headers.common['Authorization'];
}

// Create an instance of axios with custom configurations
const ApiConfig = axios.create({
    // Set the base URL for API requests
    // baseURL: 'http://localhost:3000/api', // Local
    // baseURL: 'https://api.performance.mbskaltim.co.id/api', // Hosting
    baseURL: 'https://api.e-performance.bpbd-samarinda.com/api' // Hosting bpbd-samarinda.com
});

// Export the configured axios instance
export default ApiConfig;
