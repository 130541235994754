/* Import Component React */
import {useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import dayjs from "dayjs";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import KpiCompanyForm from "./KpiCompanyForm";

export default function KpiCompanyUpdate(props) {
    //Prepare variable
    const [formUpdateKpi] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const kpiDetail = props.kpiDetail;



    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fillField();
        /*eslint-disable-next-line*/
    }, [kpiDetail]);



    /*
    * Handle Fill Field Form
    */
    const fillField = () => {
        formUpdateKpi.setFieldsValue({
            year: dayjs(kpiDetail.year),
            performanceGoal: kpiDetail.performanceGoal,
            description: kpiDetail.description,
            uom: kpiDetail.uom,
            target: kpiDetail.target,
            score: kpiDetail.score,
        });
    }



    /*
    * Handle Update Data
    */
    const updateDataKpi = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            year: dayjs(values.year).format('YYYY'),
            performanceGoal: values.performanceGoal,
            description: values.description,
            uom: values.uom,
            target: values.target,
            roleTarget: values.roleTarget,
            score: values.score,
            oldScore: kpiDetail.score,
            updatedBy: localStorage.getItem('userId'),
        };

        //Send data to backend for store
        await ApiConfig.patch(`/kpi-company/update/${kpiDetail.id}`, formData).then(() => {
            message.success(`Kpi Perusahaan Updated`);
            props.fetchData();
            formUpdateKpi.resetFields();
            props.modalClose(false);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }

    return (
        <>
            <Form layout="vertical" onFinish={updateDataKpi} form={formUpdateKpi} requiredMark={false} className="mt-3">
                <KpiCompanyForm
                    loading={loadingBtn}
                    formReference={formUpdateKpi}
                    disable={false}
                    button={"Update"}
                    setField={fillField}
                    takingTarget={kpiDetail.taking}
                    reference={kpiDetail.reference}
                    roleTarget={kpiDetail.roleTarget}
                />
            </Form>
        </>
    )
}