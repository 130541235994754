/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, Modal, message} from "antd";

/*Import Child Component*/
import CompanyForm from "./CompanyForm";

function CompanyCreate(props) {
    //Prepare variable
    const [formEntryCompany] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false);


    /*
    * Defined File On Form
    */
    const files = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

    /*
    * Handle Store Data
    */
    const storeDataCompany = async (values) => {
        setLoadingBtn(true);
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('company_logo', file);
        });

        //Append data
        formData.append('company_name', values.company_name);
        formData.append('address', values.address);
        formData.append('email', values.email);
        formData.append('pic_name', values.pic_name);
        formData.append('expired_at', values.expired_at);

        //Send data to backend for store
        await ApiConfig.post('/company/store', formData).then(() => {
            setFileList([]);
            message.success(`Company ${values.company_name} Saved`);
            props.fetchData();
            formEntryCompany.resetFields();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            {/*Handle Create Delivery*/}
            <Modal
                title="Input Data Perusahaan"
                open={props.modalEntry}
                onCancel={props.modalClose}
                width={500}
                style={{
                    top: 57,
                }}
                footer={[
                    /*Disable Button */
                ]}
            >
                <hr/>
                <Form layout="vertical" onFinish={storeDataCompany} form={formEntryCompany} requiredMark={false} className="mt-3">
                    <CompanyForm
                        files={files}
                        loading={loadingBtn}
                        formReference={formEntryCompany}
                        logoRequired={true}
                        button={"Submit"}
                    />
                </Form>
            </Modal>
        </>
    )
}

export default CompanyCreate;