/*Import Component React*/
import {useEffect, useRef} from "react";

/* Import Style */
import {Button, Flex, Form, Input} from "antd";

export default function UomForm(props) {
    const focusRef = useRef(null);

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        if (props.button === "Update") {
            props.setField();
        } else {
            props.formReference.resetFields();
        }
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);

    return (
        <>
            <Form.Item label="UoM Label" name="value" rules={[
                { required: true, message: "Please input uom" },
            ]}>
                <Input showCount maxLength={100} ref={focusRef}/>
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}