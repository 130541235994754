/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import PositionForm from "./PositionForm";

function PositionCreate(props) {
    //Prepare variable
    const [formEntryPosition] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [disableBtnDivision, setDisableBtnDivision] = useState(true);
    const [disableBtnDepartment, setDisableBtnDepartment] = useState(true);

    /*
    * Handle Store Data
    */
    const storeDataPosition = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            directorateId: values.directorateId,
            divisionId: values.divisionId,
            departmentId: values.departmentId,
            title: values.title,
            grade: values.grade,
        };

        //Send data to backend for store
        await ApiConfig.post('/position/store', formData).then(() => {
            message.success(`Position ${values.title} Saved`);
            props.fecthData();
            formEntryPosition.resetFields();
            setDisableBtnDivision(true);
            setDisableBtnDepartment(true);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={storeDataPosition} form={formEntryPosition} requiredMark={false} className="mt-3">
                <PositionForm
                    loading={loadingBtn}
                    formReference={formEntryPosition}
                    disableBtnDivision={{disableBtnDivision, setDisableBtnDivision}}
                    disableBtnDepartment={{disableBtnDepartment, setDisableBtnDepartment}}
                    button={"Submit"}
                />
            </Form>
        </>
    )
}

export default PositionCreate;