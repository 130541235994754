/* Import Component React */
import {useCallback, useState} from "react";
import ApiConfig from "../../config/ApiConfig";
import dayjs from "dayjs";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import SkForm from "./SkForm";

export default function SkCreate(props) {
    //Prepare variable
    const grade = localStorage.getItem('grade');
    const [formEntrySk] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [linkTarget, setLinktarget] = useState(grade !== "Manajer");


    /*
    * Handle Link Target.
    */
    const handleLinkTarget = useCallback( (value) => {
        setLinktarget(value);
        formEntrySk.setFieldsValue({
            referenceId: null,
            performanceGoal: null,
            description: null,
            uom: null,
            target: null,
        });
    }, [linkTarget]);



    /*
    * Handle Store Data
    */
    const storeDataSk = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            referenceId: values.referenceId,
            year: dayjs(values.year).format('YYYY'),
            group: values.group,
            type: values.type,
            performanceGoal: values.performanceGoal,
            description: values.description,
            uom: values.uom,
            target: values.target,
            roleTarget: values.roleTarget,
            score: values.score,
            linkTarget: linkTarget,
            createdBy: localStorage.getItem('userId'),
        };

        //Send data to backend for store
        if (grade === "Manajer") {
            await ApiConfig.post('/sk-manager/store', formData).then(() => {
                message.success(`Sasaran Kinerja Saved`);
                props.fecthData(dayjs(values.year).format('YYYY'), values.group);
                formEntrySk.resetFields();
                formEntrySk.setFieldsValue({
                    group: values.group,
                    type: values.type,
                });
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        } else if (grade === "Asisten Manajer") {
            await ApiConfig.post('/sk-assistant-manager/store', formData).then(() => {
                message.success(`Sasaran Kinerja Saved`);
                props.fecthData(dayjs(values.year).format('YYYY'), values.group);
                formEntrySk.resetFields();
                formEntrySk.setFieldsValue({
                    group: values.group,
                    type: values.type,
                });
                // setLinktarget(true);
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        } else {
            await ApiConfig.post('/sk-staff/store', formData).then(() => {
                message.success(`Sasaran Kinerja Saved`);
                props.fecthData(dayjs(values.year).format('YYYY'), values.group);
                formEntrySk.resetFields();
                formEntrySk.setFieldsValue({
                    group: values.group,
                    type: values.type,
                });
            }).catch(error => {
                console.log(error);
                message.error(error.response.data.message);
            });
        }

        setLoadingBtn(false)
    }

    return (
        <>
            <Form layout="vertical" onFinish={storeDataSk} form={formEntrySk} requiredMark={false} className="mt-3">
                <SkForm
                    loading={loadingBtn}
                    formReference={formEntrySk}
                    year={props.year}
                    quarter={props.quarter}
                    linkTarget={{linkTarget, handleLinkTarget}}
                    type={"KPI"}
                    button={"Submit"}
                    targetStatus={linkTarget}
                    roleTarget={"Max"}
                />
            </Form>
        </>
    )
}