/* Import Module */
import React, {useState, useEffect, useCallback} from "react";
import { Card } from 'react-bootstrap';
import ApiConfig from "../../config/ApiConfig";
import AuthVerify from "../Authentication/AuthVerify";
import dayjs from "dayjs";
import Chart from "react-apexcharts";

/* Import Style*/
import {message, Skeleton, DatePicker, Row, Col} from "antd";

export default function DashSKTeam() {
    //Prepare State
    const companyId = localStorage.getItem('companyId');
    const userId = localStorage.getItem('userId');
    const yearFormat = 'YYYY';
    const currentDate = new Date();
    const currentYear = dayjs(currentDate).format(yearFormat);
    const [year, setYear] = useState(currentYear)
    const [categories, setCategories] = useState([]);
    const [series, setSeries] = useState([]);
    const [loading, setLoading] = useState(false);

    /*
   * Initialization function in first render
   */
    useEffect(() => {
        fetchSk(year).finally();
        document.title = 'Dashboard';
        /*eslint-disable-next-line*/
    }, []);



    /*
    * Handle Filter Value
    */
    const handleFilterYear = useCallback( (value) => {
        const valueYear = dayjs(value).format('YYYY');
        fetchSk(valueYear).finally();
        setYear(valueYear);
        /* eslint-disable */
    }, [year]);


    /*
    * Handle fetch data company
    */
    async function fetchSk(year) {
        setLoading(true);
        await ApiConfig.get(`/sk-team/find-data/${companyId}/${year}/${userId}`).then(response => {
            const data = response.data;
            setCategories(data.categories);
            setSeries(data.series);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
            setCategories([]);
            setSeries([]);
        });
        setLoading(false);
    }

    const chartOptions = () => {
        return {
            options: {
                chart: {
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'straight',
                    width: 2
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: { categories }
            },
            series: series
        }
    };

    return (
        <Card className="shadow-sm mt-4" style={{ width: "100%" }}>
            <AuthVerify />

            <Card.Header>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={8}>
                        Sasaran Kinerja Team
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={{span: 4, offset: 12}}>
                        <DatePicker onChange={handleFilterYear} picker="year" defaultValue={dayjs(year)}/>
                    </Col>

                </Row>
            </Card.Header>
            <Card.Body>
                <Skeleton active loading={loading}>
                    <Chart
                        height={250}
                        width="100%"
                        options={chartOptions().options}
                        series={chartOptions().series}
                        type="line"
                    />
                </Skeleton>
            </Card.Body>

        </Card>
    );
}