/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import UserPasswordForm from "./UserPassowrdForm";

function UserPasswordUpdate(props) {
    //Prepare variable
    const [formUpdateUserPassword] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const user = props.userData;



    /*
    * Handle Update Data
    */
    const updateDataPasswordUser = async (values) => {
        setLoadingBtn(true);
        const formData = {
            password: values.password,
            confirmPassword: values.confirm,
        };

        //Send data to backend for update
        await ApiConfig.patch(`/user/update-password/${user.id}`, formData).then(() => {
            message.success(`Password Updated`);
            props.fetchData()
            formUpdateUserPassword.resetFields();
            props.modalClose(false);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={updateDataPasswordUser} form={formUpdateUserPassword} requiredMark={false} className="mt-3">
                <UserPasswordForm
                    loading={loadingBtn}
                    formReference={formUpdateUserPassword}
                    button={"Update"}
                />
            </Form>
        </>
    )
}

export default UserPasswordUpdate;