/* Import Component React */
import {useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import KpiCompanyForm from "./KpiCompanyForm";
import dayjs from "dayjs";

export default function KpiCompanyCreate(props) {
    //Prepare variable
    const [formEntryKpi] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);

    /*
    * Handle Store Data
    */
    const storeDataKpi = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            year: dayjs(values.year).format('YYYY'),
            performanceGoal: values.performanceGoal,
            description: values.description,
            uom: values.uom,
            target: values.target,
            roleTarget: values.roleTarget,
            score: values.score,
            createdBy: localStorage.getItem('userId'),
        };

        //Send data to backend for store
        await ApiConfig.post('/kpi-company/store', formData).then(() => {
            message.success(`Kpi Perusahaan Saved`);
            props.fecthData(dayjs(values.year).format('YYYY'));
            formEntryKpi.resetFields();
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }

    return (
        <>
            <Form layout="vertical" onFinish={storeDataKpi} form={formEntryKpi} requiredMark={false} className="mt-3">
                <KpiCompanyForm
                    loading={loadingBtn}
                    formReference={formEntryKpi}
                    disable={false}
                    button={"Submit"}
                    roleTarget={"Max"}
                    currentYear={props.currentYear}
                />
            </Form>
        </>
    )
}