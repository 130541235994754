/* Import Component React */
import {useEffect, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Form, message} from "antd";

/*Import Child Component*/
import TeamsForm from "./TeamsForm";

export default function TeamsAdd(props) {
    //Prepare variable
    const [formEntryTeams] = Form.useForm();
    const [loadingBtn, setLoadingBtn] = useState(false);
    const superior = props.superiorDetail;

    /*
    * Initialization function in first render
    */
    useEffect(() => {
        fillField();
        /*eslint-disable-next-line*/
    }, [superior]);



    /*
    * Handle Fill Field Form
    */
    const fillField = () => {
        formEntryTeams.setFieldsValue({
            superiorId: { value: superior.superiorId, label: superior.superiorName},
        });
    }

    /*
    * Handle Store Data
    */
    const storeDataTeams = async (values) => {
        setLoadingBtn(true);
        const formData = {
            companyId: localStorage.getItem('companyId'),
            userId: superior.superiorId,
            subordinateId: values.subordinateId,
        };

        //Send data to backend for store
        await ApiConfig.post(`/team/update/${superior.id}`, formData).then(() => {
            message.success(`Teams Saved`);
            props.fetchData();
            formEntryTeams.resetFields();
            props.modalClose(false);
        }).catch(error => {
            console.log(error);
            message.error(error.response.data.message);
        });
        setLoadingBtn(false)
    }



    return (
        <>
            <Form layout="vertical" onFinish={storeDataTeams} form={formEntryTeams} requiredMark={false} className="mt-3">
                <TeamsForm
                    loading={loadingBtn}
                    formReference={formEntryTeams}
                    disable={true}
                    button={"Submit"}
                />
            </Form>
        </>
    )
}