/* Import Component React*/
import {Route, Routes} from "react-router-dom";
import {useState} from "react";

/*Import Style*/
import {Plus} from "react-feather";
import {Button} from "antd"

/* Import component module */
import PositionView from "./PositionView";

/* Return the module*/
function PositionNav() {
    //Handle Modal Entry
    const [modalEntryPosition, setModalEntryPosition] = useState(false);

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4 ms-2" style={{ fontWeight: "bolder"}}>Position</h4>
                <Button type="primary" className="me-2" onClick={() => setModalEntryPosition(true)}><Plus className="feather-button"/>Entry</Button>
            </div>
            <Routes>
                <Route path="/" element={<PositionView modal={{modalEntryPosition, setModalEntryPosition}}/>} />
            </Routes>
        </>
    );
}

export default PositionNav;