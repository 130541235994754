/*Import Component React*/
import React, {useCallback, useEffect, useRef, useState} from "react";

/* Import Style */
import {Button, DatePicker, Flex, Form, InputNumber, Segmented} from "antd";

/* Import Time Component */
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import {Col, Row} from "react-bootstrap";

/* Import Child Component */
import UoM from "../uom/UoM";

export default function KpiCompanyForm(props) {
    // const currentDate = new Date();
    const focusRef = useRef(null);
    const [roleTarget, setRoleTarget] = useState(props.roleTarget);

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        if (props.button === "Update") {
            props.setField();
        } else {
            props.formReference.resetFields();
        }
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
        props.formReference.setFieldsValue({
            year: dayjs(props.currentYear),
            roleTarget: roleTarget === 'Min' ? 'Min' : 'Max',
        });
    }, []);

    /*
    * Handle Type Role Target
    */
    const handleRoleTarget = useCallback( (value) => {
        setRoleTarget(value);
    }, [roleTarget]);

    return (
        <>
            <Row>
                <Col xs sm lg={3}>
                    <Form.Item label="Tahun" name="year">
                        <DatePicker /*defaultValue={dayjs(props.currentYear)}*/ picker="year" disabled={props.reference > 0}/>
                    </Form.Item>
                </Col>
                <Col xs sm lg={9}>
                    <Form.Item label="Tipe Target" name="roleTarget" rules={[ {required: true, message: "Please select a type Target"} ]}>
                        <Segmented onChange={handleRoleTarget} options={['Max', 'Min']}/>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item label="Sasaran Kinerja" name="performanceGoal" rules={[
                {required: true, message: "Please input Sasaran Kinerja"},
                {min: 10, message: "Sasaran Kinerja must have min 10 character"}
            ]}>
                <TextArea showCount maxLength={500} ref={focusRef} />
            </Form.Item>

            <Form.Item label="Uraian" name="description" rules={[
                {required: true, message: "Please input Uaraian"},
                {min: 10, message: "Uraian must have min 10 character"}
            ]}>
                <TextArea showCount maxLength={1000} />
            </Form.Item>

            <Row>
                <Col md lg={4}>
                    <UoM status={props.takingTarget > 0 ? "Approved" : "Submission"}/>
                </Col>
                <Col md lg={4}>
                    <Form.Item label="Target" name="target" rules={[
                        {required: true, message: "Please input Target"},
                    ]}>
                        <InputNumber style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')} disabled={props.takingTarget > 0}
                        />
                    </Form.Item>
                </Col>
                <Col md lg={4}>
                    <Form.Item label="Score" name="score" rules={[
                        {required: true, message: "Please input Score"},
                    ]}>
                        <InputNumber style={{ width: "100%" }}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')} disabled={props.takingTarget > 0}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}