/*Import Component React*/
import {useCallback, useEffect, useRef, useState} from "react";
import ApiConfig from "../../config/ApiConfig";

/* Import Style */
import {Button, Flex, Form, Input, message, Select} from "antd";
import TextArea from "antd/es/input/TextArea";

function DivisiForm(props) {
    const focusRef = useRef(null);
    const [listDireksi, setListDireksi] = useState([]);
    const [loadingListDireksi, setLoadingListDireksi] = useState(false);
    const companyId = localStorage.getItem('companyId');

    /*
    * Reset Fields Form
    */
    const resetForm = () => {
        props.formReference.resetFields();
    }

    /*
    * Set focus in form
    */
    useEffect(() => {
        focusRef.current.focus();
    }, []);



    /*
    * Handle list direksi trigger form select.
    */
    const handleListDireksi = useCallback( () => {
        fetchListDireksi().finally();
        /* eslint-disable */
    }, [listDireksi]);

    /*
    * Handle fetch data direksi*/
    async function fetchListDireksi() {
        //Await
        setLoadingListDireksi(true);
        await ApiConfig.get(`/directorate/select/${companyId}`).then(response => {
            setListDireksi(response.data.doc);
        }).catch((error) => {
            console.log(error);
            message.error(error.response.data.message);
            setListDireksi([]);
        });
        setLoadingListDireksi(false);
    }

    return (
        <>
            <Form.Item label="Direksi" name="directorateId" rules={[ {required: true, message: "Please select a reference"} ]}>
                <Select placeholder="Select a reference" allowClear showSearch filterOption={
                    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } onClick={handleListDireksi} onFocus={handleListDireksi} loading={loadingListDireksi} options={listDireksi} ref={focusRef}/>
            </Form.Item>

            <Form.Item label="Divisi" name="divisionName" rules={[
                { required: true, message: "Please input divisi" },
                { min: 3, message: "Divisi must have min 3 character" }
            ]}>
                <Input showCount maxLength={100}/>
            </Form.Item>

            <Form.Item label="Deskripsi" name="description" rules={[
                {required: true, message: "Please input deskripsi"},
                {min: 1, message: "Deskripsi must have min 1 character"}
            ]}>
                <TextArea showCount maxLength={300} />
            </Form.Item>

            <hr/>
            <Flex justify="flex-end" gap="small">
                <Button type="primary" htmlType="submit" loading={props.loading}>
                    {props.button}
                </Button>
                <Button danger htmlType="button" onClick={resetForm}>
                    Reset
                </Button>
            </Flex>
        </>
    )
}

export default DivisiForm;