/* Import Component React*/
import {Route, Routes} from "react-router-dom";
import {useState} from "react";

/*Import Style*/
import {Plus} from "react-feather";
import {Button} from "antd"

/* Import component module */
import DireksiView from "./DireksiView";

/* Return the module*/
function DireksiNav() {
    //Handle Modal Entry
    const [modalEntryDireksi, setModalEntryDireksi] = useState(false);



    /*
    * Handle close modal entry direksi
    */
    const handleCloseModalEntryDireksi = () => {
        setModalEntryDireksi(false);
    }

    return  (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center sticky-top-nav-menu border-bottom">
                <h4 className="h4 ms-2" style={{ fontWeight: "bolder"}}>Direksi</h4>
                <Button type="primary" className="me-2" onClick={() => setModalEntryDireksi(true)}><Plus className="feather-button"/>Entry</Button>
            </div>
            <Routes>
                <Route path="/" element={<DireksiView modal={{modalEntryDireksi, handleCloseModalEntryDireksi}}/>} />
                {/*<Route path="create" element={<StockCreate/>} />*/}
                {/*<Route path="detail/:id" element={<StockDetail/>} />*/}
                {/*<Route path="update/:id" element={<StockUpdate/>} />*/}
            </Routes>
        </>
    );
}

export default DireksiNav;